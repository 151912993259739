import styled from '@emotion/styled'
import { memo } from 'react'

import { DayJS } from '@pure/libs/DayJsHelper'

import PortalTexts from '@my-drifter/assets/texts_extended.json'
import Box from '@my-drifter/components/Box'
import FigmaImage from '@my-drifter/components/FigmaImage'
import FigmaText from '@my-drifter/components/FigmaText'
import Layout from '@my-drifter/components/Layout'
import MonthPicker from '@my-drifter/components/MonthPicker'
import { PortalColors } from '@my-drifter/components/PortalColors'
import { PortalSpacings } from '@my-drifter/components/PortalSpacings'
import SessionsTable from '@my-drifter/components/SessionsTable'
import useSessionScreenData from '@my-drifter/hooks/useSessionScreenData'
import Images from '@my-drifter/libs/Images'
import { getFigmaText } from '@my-drifter/libs/TextRepository'

import NotAuthorizedScreen from './NotAuthorizedScreen'
import NotAvailableOnMobileScreen from './NotAvailableOnMobileScreen'

function SessionsScreen() {
  const {
    isAllowed,
    isLoadingUser,
    isDesktop,
    selectedSiteId,
    setSelectedSiteId,
    isLoadingData,
    sessionsData,
    fromDate,
    setFromDate,
    toDate,
    setToDate
  } = useSessionScreenData()

  const now = DayJS()
  const minDate = DayJS('2024-01-01')

  if (!isAllowed && !isLoadingUser) return <NotAuthorizedScreen />

  if (!isDesktop) return <NotAvailableOnMobileScreen />

  const fromLabel = getFigmaText(PortalTexts.MonthPickerFromLabel)
  const toLabel = getFigmaText(PortalTexts.MonthPickerToLabel)
  const sessionsWithDate = sessionsData.map((s) => {
    return {
      date: DayJS(s.month, 'MMMM YYYY'),
      ...s
    }
  })

  return (
    <Layout selectedSiteId={selectedSiteId} setSelectedSiteId={setSelectedSiteId} isLoading={isLoadingUser}>
      <Box gap={PortalSpacings.x6} spacing={PortalSpacings.x6} fullPadding style={{ paddingTop: PortalSpacings.x3 }}>
        <TitleBox direction="row" align="center" gap={PortalSpacings.x2}>
          <FigmaImage imageKey={Images.carIconDark} />
          <FigmaText textKey={PortalTexts.sessionStatisticsheaderheadlineMain} />
        </TitleBox>
      </Box>
      <Box fullWidth align="center" backgroundColor="#FFF" flexGrow={1}>
        <Box alignSelf="flex-start" direction="row" spacing={PortalSpacings.x6} top left gap={PortalSpacings.x2}>
          <MonthPicker label={fromLabel} date={fromDate} minDate={minDate} maxDate={now} setDate={setFromDate} />
          <MonthPicker label={toLabel} date={toDate} minDate={minDate} maxDate={now} setDate={setToDate} />
        </Box>

        <SessionsTable data={sessionsWithDate} loading={isLoadingData} />
      </Box>
    </Layout>
  )
}

const TitleBox = styled(Box)`
  color: ${PortalColors.textPrimary};
`

export default memo(SessionsScreen)
