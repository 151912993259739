import {
  Box,
  Table,
  TableBody,
  TableCell as MUITableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { SitePricingConfigurationRelativeItem } from '@contracts/types/Site'

import NumericInput from './NumericInput'
import { PortalColors } from './PortalColors'
import { PortalSpacings } from './PortalSpacings'

interface RelativePricingTableProps {
  items: SitePricingConfigurationRelativeItem[]
  isEditing?: boolean
  onChange: (item: SitePricingConfigurationRelativeItem, index: number) => void
}

interface CellProps {
  item: SitePricingConfigurationRelativeItem
  isEditing?: boolean
  onChange?: (item: SitePricingConfigurationRelativeItem) => void
}

const TableCell = ({ isEditing, sx, ...props }: TableCellProps & { isEditing?: boolean }) => (
  <MUITableCell sx={[isEditing ? { py: 3 } : {}, ...(Array.isArray(sx) ? sx : [sx])]} {...props} />
)

const IntervalCell = ({ item, label }: CellProps & { label: string }) => {
  const { t } = useTranslation()
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      {[t('configurationPricing_allday'), t('configurationPricing_thereafter')].includes(label) ? (
        <Box sx={{ fontWeight: 600 }}>{label}</Box>
      ) : (
        <>
          <Box>{label}</Box>
          <Box sx={{ fontWeight: 600 }}>{item.intervalMinutes}</Box>
          <Box>min</Box>
        </>
      )}
    </Box>
  )
}

const PriceCell = ({ item, isEditing, onChange }: CellProps) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'flex-end' }}>
      {isEditing ? (
        <NumericInput
          value={item.perHourPrice}
          onChange={(e) => {
            onChange?.({ ...item, perHourPrice: parseFloat(e.target.value) })
          }}
          min={0}
          variant="outlined"
          size="small"
          sx={{
            width: PortalSpacings.x20
          }}
          inputProps={{
            sx: { textAlign: 'right', fontSize: 14, py: 1, px: 2, color: PortalColors.textPrimary }
          }}
        />
      ) : (
        <Box sx={{ color: PortalColors.textPrimary, fontWeight: 600 }}>{item.perHourPrice}</Box>
      )}

      <Box sx={{ color: PortalColors.textPrimary, fontWeight: 600 }}>kr/hr</Box>
    </Box>
  )
}

export default function RelativePricingTable({ items, isEditing, onChange }: RelativePricingTableProps) {
  const { t } = useTranslation()
  const itemLabels = items.map((_, index, arr) => {
    if (arr.length === 1) {
      return t('configurationPricing_allday')
    }

    if (index === arr.length - 1) {
      return t('configurationPricing_thereafter')
    }

    if (index === 0) {
      return t('configurationPricing_first')
    }

    return t('configurationPricing_next')
  })

  return (
    <TableContainer component={Box} sx={{ overflow: 'hidden' }}>
      <Table sx={{ overflow: 'hidden' }}>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                color: PortalColors.textPrimary,
                fontWeight: 'bold',
                width: '40%',
                borderBottom: '1px solid',
                borderColor: PortalColors.borderStrong
              }}
            >
              {t('configurationPricing_interval').toUpperCase()}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                color: PortalColors.textPrimary,
                fontWeight: 'bold',
                width: '30%',
                borderBottom: '1px solid',
                borderColor: PortalColors.borderStrong
              }}
            >
              {t('configurationPricing_price').toUpperCase()}{' '}
              <Box component="span" sx={{ fontWeight: 400 }}>
                {t('configurationPricing_VATincl')}
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => (
            <TableRow
              key={`${item.intervalMinutes}-${index}`}
              sx={{ '&:last-child td, &:last-child th': !isEditing ? { border: 0 } : {} }}
            >
              <TableCell isEditing={isEditing} sx={{ color: PortalColors.textPrimary }}>
                <IntervalCell item={item} label={itemLabels[index]} />
              </TableCell>
              <TableCell isEditing={isEditing} align="right">
                <PriceCell item={item} isEditing={isEditing} onChange={(item) => onChange(item, index)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
