import styled from '@emotion/styled'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button } from '@mui/material'
import { captureException } from '@sentry/react'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { FieldErrors, useForm, UseFormRegister } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'

import { DebtorType } from '@contracts/types/Debtor'
import { isOrgNr, PermitDebtor } from '@contracts/types/PermitDebtor'

import { isValidNumber } from '@pure/libs/PhoneNumberHelper'
import parseSSN from '@pure/libs/SSNHelper'

import TextsExtended from '@my-drifter/assets/texts_extended.json'
import LoadingModal from '@my-drifter/components/LoadingModal'
import { createPermitDebtor } from '@my-drifter/libs/DBApiHandler'
import NavigationPaths from '@my-drifter/libs/NavigationPaths'
import { getFigmaText } from '@my-drifter/libs/TextRepository'
import { LabelLargeRegular } from '@my-drifter/libs/Typography'

import GroupDebtorForm from './GroupDebtorForm'
import IndividualDebtorForm from './IndividualDebtorForm'
import OrgDebtorForm from './OrgDebtorForm'
import { PortalColors } from './PortalColors'
import { PortalRadiuses } from './PortalRadiuses'
import { PortalSpacings } from './PortalSpacings'

export interface DebtorFormImplProps {
  register: UseFormRegister<FormData>
  debtorType: DebtorType
  onSetDebtorType: (debtorType: DebtorType) => void
  errors: FieldErrors<FormData>
}

export interface DebtorFormContainerProps {
  siteId: string
}

export type OrgFormData = z.infer<typeof schemaOrg>
export type IndFormData = z.infer<typeof schemaInd>
export type GrpFormData = z.infer<typeof schemaGrp>

type FormData = OrgFormData | IndFormData | GrpFormData

const DEFAULT_ORG_FORM_DATA = {
  type: DebtorType.Company,
  name: '',
  email: '',
  phone: '',
  orgnr: '',
  contactName: ''
} as OrgFormData

const DEFAULT_PERSON_FORM_DATA = {
  type: DebtorType.Person,
  name: '',
  email: '',
  phone: '',
  ssn: ''
} as IndFormData

const DEFAULT_GROUP_FORM_DATA = {
  type: DebtorType.Group,
  name: ''
} as GrpFormData

const DEFAULT_FORM_DATA = {
  [DebtorType.Company]: DEFAULT_ORG_FORM_DATA,
  [DebtorType.Person]: DEFAULT_PERSON_FORM_DATA,
  [DebtorType.Group]: DEFAULT_GROUP_FORM_DATA
}

const schemaOrg = z.object({
  type: z.string(),
  name: z.string().min(1, { message: getFigmaText(TextsExtended.permitsInputfieldserrorrequired) }),
  orgnr: z
    .string()
    .min(1, { message: getFigmaText(TextsExtended.permitsInputfieldserrorrequired) })
    .regex(/^[0-9\s-]*$/, { message: getFigmaText(TextsExtended.permitsInputfieldserrororgIDletters) })
    .refine((value) => isOrgNr(value), {
      message: getFigmaText(TextsExtended.permitsInputfieldserrororgID)
    }),
  contactName: z.string().min(1, { message: getFigmaText(TextsExtended.permitsInputfieldserrorrequired) }),
  email: z
    .string()
    .email({ message: getFigmaText(TextsExtended.permitsInputfieldserroremail) })
    .min(1, { message: getFigmaText(TextsExtended.permitsInputfieldserrorrequired) }),
  phone: z
    .string()
    .min(1, { message: getFigmaText(TextsExtended.permitsInputfieldserrorrequired) })
    .refine((value) => isValidNumber(value), {
      message: getFigmaText(TextsExtended.permitsInputfieldserrorphone)
    })
})
const schemaInd = z.object({
  type: z.string(),
  name: z.string().min(1, { message: getFigmaText(TextsExtended.permitsInputfieldserrorrequired) }),
  email: z
    .string()
    .email({ message: getFigmaText(TextsExtended.permitsInputfieldserrorrequired) })
    .min(1, { message: getFigmaText(TextsExtended.permitsInputfieldserrorrequired) }),
  ssn: z
    .string()
    .min(1, { message: getFigmaText(TextsExtended.permitsInputfieldserrorrequired) })
    .refine((value) => parseSSN(value), {
      message: getFigmaText(TextsExtended.permitsInputfieldserrorSSNno)
    }),
  phone: z
    .string()
    .min(1, { message: getFigmaText(TextsExtended.permitsInputfieldserrorrequired) })
    .refine((value) => isValidNumber(value), {
      message: getFigmaText(TextsExtended.permitsInputfieldserrorphone)
    })
})
const schemaGrp = z.object({
  type: z.string(),
  name: z.string().min(1, { message: getFigmaText(TextsExtended.permitsInputfieldserrorrequired) })
})

const SCHEMA = {
  [DebtorType.Company]: schemaOrg,
  [DebtorType.Person]: schemaInd,
  [DebtorType.Group]: schemaGrp
}

function DebtorFormContainer({ siteId }: DebtorFormContainerProps) {
  const [debtorType, setDebtorType] = useState<DebtorType>(DebtorType.Company)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting }
  } = useForm<FormData>({
    resolver: zodResolver(SCHEMA[debtorType]),
    defaultValues: { ...DEFAULT_FORM_DATA[debtorType] }
  })

  useEffect(() => {
    reset({ ...DEFAULT_FORM_DATA[debtorType] })
  }, [debtorType, reset, siteId])

  const onSubmit = async (data: FormData) => {
    console.log(`Form Data Submitted for site ${siteId}:`, data)

    const id = self.crypto.randomUUID()
    const updatedAt = dayjs().format()
    const finalData = {
      ...data,
      siteId,
      id,
      updatedAt
    } as PermitDebtor

    try {
      await createPermitDebtor(siteId, finalData, id)
      navigate(NavigationPaths.NewPermits)
    } catch (error) {
      captureException(error)
      console.error(error)
    }
  }

  const navigate = useNavigate()
  const cancel = () => {
    navigate(NavigationPaths.NewPermits)
  }

  const FormComponent = {
    [DebtorType.Company]: OrgDebtorForm,
    [DebtorType.Person]: IndividualDebtorForm,
    [DebtorType.Group]: GroupDebtorForm
  }[debtorType]

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ position: 'relative', flex: '1', paddingBottom: '80px' }}
    >
      <FormComponent register={register} debtorType={debtorType} onSetDebtorType={setDebtorType} errors={errors} />
      <StyledFooter>
        <Button
          onClick={(e) => cancel()}
          sx={{
            color: PortalColors.textOnActionTertiary,
            backgroundColor: PortalColors.surfaceLevelFirst,
            textTransform: 'none',
            borderRadius: PortalRadiuses.sm,
            ...LabelLargeRegular,
            '&:hover': {
              backgroundColor: PortalColors.surfaceActionTertiaryHover
            },
            '&.Mui-selected': {
              backgroundColor: PortalColors.surfaceActionTertiaryPressed
            }
          }}
        >
          {getFigmaText(TextsExtended.permitsButtonscancel)}
        </Button>
        <Button
          type="submit"
          sx={{
            backgroundColor: PortalColors.surfaceActionSecondary,
            color: PortalColors.textOnActionSecondary,
            textTransform: 'none',
            borderRadius: PortalRadiuses.sm,
            padding: `${PortalSpacings.x3} ${PortalSpacings.x4} `,
            ...LabelLargeRegular,
            '&:hover': {
              backgroundColor: PortalColors.surfaceActionSecondaryHover
            },
            '&.Mui-selected': {
              backgroundColor: PortalColors.surfaceActionTertiaryPressed,
              color: PortalColors.textOnActionTertiaryPressed
            }
          }}
        >
          {getFigmaText(TextsExtended.permitsButtonssavedebtor)}
        </Button>
      </StyledFooter>
      <LoadingModal open={isSubmitting} title={getFigmaText(TextsExtended.permitsSetupNewdebtorloader)} />
    </Box>
  )
}

export default DebtorFormContainer

const StyledFooter = styled.div({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: PortalColors.surfaceLevelFirst,
  borderTop: `1px solid ${PortalColors.borderDecorativeLow}`,
  padding: PortalSpacings.x4,
  display: 'flex',
  justifyContent: 'flex-end',
  gap: PortalSpacings.x4
})
