import { memo } from 'react'

import Texts from '@my-drifter/libs/Texts'

import FigmaText from './FigmaText'

type DropdownSelectedItemProps = {
  selected?: string
  options?: {
    label?: string
    value?: string
  }[]
  dropdownType: 'site' | 'siteDebtor' | 'permit' | 'month'
}

const DropdownSelectedItem = ({ selected, options, dropdownType }: DropdownSelectedItemProps) => {
  const getDropdownEmptyTextKey = () => {
    switch (dropdownType) {
      case 'site':
        return Texts.textMyPortalEmptyStateSite
      case 'siteDebtor':
        return Texts.textMyPortalEmptyStateDebtorSelector
      case 'permit':
        return Texts.textMyPortalEmptyStatePermit
      case 'month':
        return Texts.textMyPortalStatsStatisticsColumnDate
    }
  }

  const selectedOption = options?.find((option) => option.value === selected)

  return (
    <>
      {!selected && <FigmaText textKey={getDropdownEmptyTextKey()} />}
      {selected && <FigmaText textKey={Texts.textPermitManagementPermitCssUserName} text={selectedOption?.label} />}
    </>
  )
}

export default memo(DropdownSelectedItem)
