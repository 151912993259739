import 'react-toastify/dist/ReactToastify.css'
import './libs/FirebaseOptions'
import './libs/Fonts'

import { ThemeProvider } from '@mui/material'
import { ComponentType } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { PermitsProvider } from '@my-drifter/store/PermitsProvider'

import ErrorBoundaryProvider from './components/ErrorBoundaryProvider'
import { Context, useAppStateContext } from './hooks/useAppState'
import { FirebaseAuthUserProvider } from './hooks/useFirebaseAuthUser'
import { SelectedSiteProvider } from './hooks/useSelectedSiteContext'
import { MaterialTheme } from './libs/MaterialTheme'
import NavigationPaths from './libs/NavigationPaths'
import CameraScreen from './screens/CameraScreen'
import CreateDebtorScreen from './screens/CreateDebtorScreen'
import CreatePermitScreen from './screens/CreatePermitScreen'
import CustomPricingEditorScreen from './screens/CustomPricingEditorScreen'
import EditPermitScreen from './screens/EditPermitScreen'
import NewPermitsScreen from './screens/NewPermitsScreen'
import PermitsScreen from './screens/PermitsScreen'
import RevenueScreen from './screens/RevenueScreen'
import SessionOverview from './screens/SessionOverview'
import SessionsScreen from './screens/SessionsScreen'
import SignInScreen from './screens/SignInScreen'
import SiteConfigurationScreen from './screens/SiteConfigurationScreen'
import SiteMapScreen from './screens/SiteMapScreen'
import StaticQRMakerScreen from './screens/StaticQRMakerScreen'
import { NewPermitsProvider } from './store/NewPermitsProvider'
import { UpsertPermitProvider } from './store/UpsertPermitProvider'

function App() {
  const context = useAppStateContext()
  const queryClient = new QueryClient({ defaultOptions: { queries: { staleTime: Infinity } } })

  return (
    <ErrorBoundaryProvider>
      <ThemeProvider theme={MaterialTheme}>
        <Context.Provider value={context}>
          <SelectedSiteProvider>
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                <FirebaseAuthUserProvider>
                  <Routes>
                    <Route path="/" Component={SignInScreen as ComponentType<object> | null | undefined} />
                    <Route path={NavigationPaths.Sites} Component={SiteMapScreen} />
                    <Route path="/dashboard" element={<Navigate to={NavigationPaths.Sites} replace />} />
                    <Route path={NavigationPaths.Sessions} Component={SessionsScreen} />
                    <Route path={NavigationPaths.Revenue} Component={RevenueScreen} />
                    <Route
                      path="/permits"
                      Component={() => (
                        <PermitsProvider>
                          <PermitsScreen />
                        </PermitsProvider>
                      )}
                    />
                    <Route
                      path={NavigationPaths.NewPermits}
                      Component={() => (
                        <NewPermitsProvider>
                          <NewPermitsScreen />
                        </NewPermitsProvider>
                      )}
                    />
                    <Route
                      path={NavigationPaths.CreateDebtor}
                      Component={() => (
                        <PermitsProvider>
                          <CreateDebtorScreen />
                        </PermitsProvider>
                      )}
                    />
                    <Route
                      path={NavigationPaths.CreatePermit}
                      Component={() => (
                        <PermitsProvider>
                          <CreatePermitScreen />
                        </PermitsProvider>
                      )}
                    />
                    <Route
                      path={NavigationPaths.EditPermit}
                      Component={() => (
                        <UpsertPermitProvider>
                          <EditPermitScreen />
                        </UpsertPermitProvider>
                      )}
                    />
                    <Route path="/static-qr-maker" Component={StaticQRMakerScreen} />
                    <Route path="/free-text-pricing-editor" Component={CustomPricingEditorScreen} />
                    <Route path="/hourly-overview" Component={SessionOverview} />
                    <Route path="/cameras" Component={CameraScreen} />
                    <Route path="/configure" Component={SiteConfigurationScreen} />
                  </Routes>
                </FirebaseAuthUserProvider>
                <ToastContainer />
              </BrowserRouter>
            </QueryClientProvider>
          </SelectedSiteProvider>
        </Context.Provider>
      </ThemeProvider>
    </ErrorBoundaryProvider>
  )
}

export default App
