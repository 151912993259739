import styled from '@emotion/styled'

import { PortalColors } from './PortalColors'

interface HeadlineProps {
  size?: 'small' | 'medium' | 'large'
  weight?: 'light' | 'regular' | 'strong'
}

const DEFAULT_SIZE = 'medium'
const DEFAULT_WEIGHT = 'strong'

const fontSize = {
  small: '16px',
  medium: '28px',
  large: '36px'
}

const fontWeight = {
  light: '300',
  regular: '400',
  strong: '600'
}

const lineHeight = {
  small: '20px',
  medium: '36px',
  large: '42px'
}

const Headline = styled.h1`
  font-family: Poppins;
  margin: 0;

  font-size: ${({ size = DEFAULT_SIZE }: HeadlineProps) => fontSize[size]};
  line-height: ${({ size = DEFAULT_SIZE }: HeadlineProps) => lineHeight[size]};
  font-weight: ${({ weight = DEFAULT_WEIGHT }: HeadlineProps) => fontWeight[weight]};
  color: ${PortalColors.textPrimary};
`

export default Headline
