import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { grey } from '@mui/material/colors'
import dayjs, { Dayjs } from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'
import { and, collection, or, query, Timestamp, where } from 'firebase/firestore'
import { memo, useMemo, useState } from 'react'

import { HikerUserRole } from '@contracts/types/HikerUser'
import { OffenseType } from '@contracts/types/OffenseType'
import { isOffenseEvent, ParkingSession } from '@contracts/types/ParkingSession'

import { useFirestoreQuery } from '@web-js/hooks/useFirestoreQuery'

import DailyParkingMetrics from '@my-drifter/components/DailyParkingMetrics'
import DatePicker from '@my-drifter/components/DatePicker'
import ScreenHeadline from '@my-drifter/components/Headline'
import Layout from '@my-drifter/components/Layout'
import { PortalSpacings } from '@my-drifter/components/PortalSpacings'
import Title from '@my-drifter/components/Title'
import WeeklyOccupancyTable from '@my-drifter/components/WeeklyOccupancyTable'
import useAppState from '@my-drifter/hooks/useAppState'
import { useSelectedSiteContext } from '@my-drifter/hooks/useSelectedSiteContext'
import { useSite } from '@my-drifter/hooks/useSite'
import { useUser } from '@my-drifter/hooks/useUser'
import { db } from '@my-drifter/libs/FirebaseOptions'

import NotAuthorizedScreen from './NotAuthorizedScreen'

// Make sure datepicker starts on monday (should probably be configured globally)
dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
  weekStart: 1
})

export interface ParkingSessionView extends Pick<ParkingSession, 'id' | 'siteId' | 'events'> {
  startedAt: Timestamp
  endedAt?: Timestamp
}

export interface OccupancyEvent {
  session: string
  startedAt: string
  endedAt: string
  type: string
  offenseType?: OffenseType
}

export interface SlotOccupancy {
  slot: string
  events: OccupancyEvent[]
}

const Container = styled(Box)`
  font-family: Inter;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${PortalSpacings.x4};
`

const SessionOverview = () => {
  const { state } = useAppState()

  const { data: user, isLoading: isLoadingUser } = useUser(localStorage.getItem('userId') || state?.userId)

  const now = dayjs()
  const minDate = dayjs('2024-01-01')
  const [date, setDate] = useState<Dayjs>(now)

  const isAllowed =
    !isLoadingUser &&
    user?.role &&
    [HikerUserRole.ADMIN, HikerUserRole.ROOT].includes(user.role) &&
    user?.siteIds &&
    user?.siteIds?.length > 0

  const { selectedSiteId, setSelectedSiteId } = useSelectedSiteContext()
  const isDateAfterToday = date.isAfter(dayjs(), 'day')

  const { data: site } = useSite(selectedSiteId)

  const slotCount = site?.segments?.reduce((acc, segment) => acc + segment.slots.length, 0) ?? 0

  // TODO - Do we need to use a UTC date for the query?
  const startOfWeek = Timestamp.fromMillis(date.startOf('week').valueOf())

  const endOfWeek = Timestamp.fromMillis(date.endOf('week').add(1, 'day').startOf('day').valueOf())

  const { data: sessions = [], isLoading } = useFirestoreQuery<ParkingSessionView[]>(
    query(
      collection(db, 'experimental_parking_sessions_view'),
      and(
        where('siteId', '==', selectedSiteId ?? ''),
        or(
          // Started and ended today
          and(where('startedAt', '>=', startOfWeek), where('endedAt', '<', endOfWeek)),
          // Started before date and ended on date
          and(where('startedAt', '<', startOfWeek), where('endedAt', '>=', startOfWeek)),
          // Started before date and ended after date
          and(where('startedAt', '<', startOfWeek), where('endedAt', '>=', endOfWeek)),
          // Started before day after and not ended yet
          and(where('startedAt', '<', endOfWeek), where('endedAt', '==', null))
        )
      )
    ),
    {
      fnName: 'useSessions',
      enabled: Boolean(selectedSiteId)
    }
  )

  const occupancy = useMemo(() => {
    const occupancyBySlotAndType = sessions.reduce((occupancy, session) => {
      const events = session.events ?? []

      if (events.some((event) => event.name === 'PARKING_FALSE_SESSION')) {
        return occupancy
      }

      let occupancyForSlot: OccupancyEvent[] = []

      events.forEach((event) => {
        if (!event.place_code || !event.startedAt || !event.endedAt || !event.type) {
          return
        }

        occupancyForSlot = occupancy.get(event.place_code) ?? []

        const occupancyEvent: OccupancyEvent = {
          session: session.id,
          startedAt: dayjs(event.startedAt).format(),
          endedAt: dayjs(event.endedAt).format(),
          type: event.type
        }

        if (isOffenseEvent(event)) {
          occupancyEvent.offenseType = event.offenseType
        }

        occupancyForSlot.push(occupancyEvent)

        occupancy.set(event.place_code, occupancyForSlot)
      })

      return occupancy
    }, new Map<string, OccupancyEvent[]>())

    return Array.from(occupancyBySlotAndType.entries()).map(([key, events]) => {
      const sortedEvents = events

      return {
        slot: key,
        events: sortedEvents
      }
    })
  }, [sessions.length, date])

  const occupancyToday = useMemo(
    () =>
      occupancy.map((o) => {
        const todayEvents = o.events.filter((event) => {
          const start = dayjs(event.startedAt)
          const end = dayjs(event.endedAt)

          return start.isSame(date, 'day') || end.isSame(date, 'day')
        })

        return {
          ...o,
          events: todayEvents
        }
      }, []),
    [occupancy]
  )

  if (!isAllowed && !isLoadingUser) return <NotAuthorizedScreen />

  if (!site) return null

  return (
    <Layout
      selectedSiteId={selectedSiteId}
      setSelectedSiteId={(id) => {
        setSelectedSiteId(id)
      }}
      isLoading={isLoadingUser}
    >
      <Container sx={(theme) => ({ maxWidth: 1000, display: 'flex', flexDirection: 'column', gap: 4 })}>
        <ScreenHeadline>Statistical Overview</ScreenHeadline>
        <Box sx={{ border: `1px solid ${grey[300]}`, borderRadius: 2, backgroundColor: 'white' }}>
          <Header>
            <Title>Parking Breakdown</Title>
            <DatePicker
              value={date}
              format="YYYY-MM-DD"
              minDate={minDate}
              maxDate={now}
              onChange={(newDate) => newDate && setDate(newDate)}
              slotProps={{
                textField: {
                  size: 'small',
                  inputProps: {
                    'aria-label': 'Select date'
                  }
                }
              }}
              sx={{ width: 168 }}
            />
          </Header>

          <DailyParkingMetrics
            occupancy={isDateAfterToday ? [] : occupancyToday}
            slotCount={slotCount}
            date={date}
            isLoading={isLoading}
            site={site}
          />
        </Box>
        <WeeklyOccupancyTable occupancy={isDateAfterToday ? [] : occupancy} slotCount={slotCount} date={date} />
      </Container>
    </Layout>
  )
}

export default memo(SessionOverview)
