import { useLocalStorage } from '@uidotdev/usehooks'
import React from 'react'

interface SelectedSiteContextInterface {
  selectedSiteId?: string
  setSelectedSiteId: (siteId: string) => void
}

const DEFAULT_SITE_CONTEXT = {
  setSelectedSiteId: (siteId: string): void => {}
}

const SelectedSiteContext = React.createContext<SelectedSiteContextInterface>(DEFAULT_SITE_CONTEXT)

export function SelectedSiteProvider({ children }) {
  const [selectedSiteId, setSelectedSiteId] = useLocalStorage<string | undefined>('selectedSiteId')

  return (
    <SelectedSiteContext.Provider value={{ selectedSiteId, setSelectedSiteId }}>
      {children}
    </SelectedSiteContext.Provider>
  )
}

export function useSelectedSiteContext() {
  const context = React.useContext(SelectedSiteContext)
  if (!context) {
    throw new Error('useSelectedSiteContext must be used within a SelectedSiteContextProvider')
  }
  return context
}
