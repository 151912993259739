import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'

dayjs.extend(weekday)
dayjs.extend(weekOfYear)
dayjs.extend(updateLocale)
dayjs.extend(isSameOrAfter)
dayjs.updateLocale('en', {
  weekStart: 1
})

import { OccupancyEvent, SlotOccupancy } from '@my-drifter/screens/SessionOverview'

import { getIntervalIndexForHour, splitEventByHourInterval } from './IntervalHelper'
dayjs.extend(utc)

export function groupOccupancyByHourInterval(
  occupancy: SlotOccupancy[],
  baseDate: string,
  interval = [0, 6, 12, 18, 24]
) {
  const splitOccupancies: SlotOccupancy[][] = Array.from({ length: interval.length - 1 }, () => [])
  const date = dayjs(baseDate)

  for (const { slot, events } of occupancy) {
    const allSplits: OccupancyEvent[][] = splitOccupancies.map(() => [])

    for (const event of events) {
      const splitEvent: OccupancyEvent[] = splitEventByHourInterval(event, interval)

      const filtered = splitEvent.filter((split) => {
        const startedAt = dayjs(split.startedAt)
        const endedAt = dayjs(split.endedAt)

        const isSplitBeforeDate = endedAt.isSameOrBefore(date.startOf('day'))
        const isSplitAfterDate = startedAt.isAfter(date.endOf('day'))

        return !isSplitBeforeDate && !isSplitAfterDate
      })

      filtered.forEach((f) => {
        const intervalIndex = getIntervalIndexForHour(dayjs(f.startedAt).hour(), interval)

        if (intervalIndex >= 0) {
          allSplits[intervalIndex].push({
            ...f,
            startedAt: dayjs(f.startedAt).utc().format(),
            endedAt: dayjs(f.endedAt).utc().format()
          })
        }
      })
    }

    allSplits.forEach((split, index) => {
      if (split.length === 0) {
        return
      }

      splitOccupancies[index].push({
        slot,
        events: split
      })
    })
  }

  return splitOccupancies
}

export function groupOccupancyByDominantType(occupancies: SlotOccupancy[]) {
  return occupancies.reduce<{
    free: number
    parking: number
    permit: number
    offense: number
  }>(
    (acc, { events }) => {
      const durationByType = events.reduce(
        (acc, event) => {
          if (!event.type || !event.startedAt || !event.endedAt) return acc

          const duration = dayjs(event.endedAt).diff(dayjs(event.startedAt), 'seconds')

          if (typeof acc[event.type] !== 'number') {
            acc[event.type] = 0
          }

          acc[event.type] += duration

          return acc
        },
        {
          free: 0,
          parking: 0,
          permit: 0,
          offense: 0
        }
      )

      const mostUsedType = Object.keys(durationByType).reduce((acc, type) => {
        const previousDuration = durationByType[acc]
        const currentDuration = durationByType[type]

        if (currentDuration > previousDuration) {
          return type
        }

        return acc
      })

      acc[mostUsedType]++

      return acc
    },
    {
      free: 0,
      parking: 0,
      permit: 0,
      offense: 0
    }
  )
}

if (import.meta.vitest) {
  const { test, expect } = import.meta.vitest

  test('groupOccupancyByHourInterval', () => {
    const filteredOccupancy = groupOccupancyByHourInterval(
      [
        {
          slot: 'A-01',
          events: [
            {
              startedAt: '2025-03-02T22:00:00Z',
              endedAt: '2025-03-03T00:00:00Z',
              session: '1000',
              type: 'free'
            },
            {
              startedAt: '2025-03-02T22:00:00Z',
              endedAt: '2025-03-03T12:00:00Z',
              session: '1001',
              type: 'free'
            },
            {
              startedAt: '2025-03-03T09:00:00Z',
              endedAt: '2025-03-03T13:00:00Z',
              session: '1002',
              type: 'free'
            }
          ]
        },

        {
          slot: 'A-02',
          events: [
            {
              startedAt: '2025-03-03T03:00:00Z',
              endedAt: '2025-03-04T04:00:00Z',
              session: '1003',
              type: 'free'
            }
          ]
        },

        {
          slot: 'A-03',
          events: [
            {
              startedAt: '2025-03-02T23:00:00Z',
              endedAt: '2025-03-09T23:00:00Z',
              session: '1004',
              type: 'free'
            }
          ]
        }
      ],
      '2025-03-03'
    )

    expect(filteredOccupancy).toEqual([
      [
        {
          slot: 'A-01',
          events: [
            {
              startedAt: '2025-03-02T23:00:00Z',
              endedAt: '2025-03-03T00:00:00Z',
              session: '1000',
              type: 'free'
            },
            {
              startedAt: '2025-03-02T23:00:00Z',
              endedAt: '2025-03-03T05:00:00Z',
              session: '1001',
              type: 'free'
            }
          ]
        },
        {
          slot: 'A-02',
          events: [
            {
              startedAt: '2025-03-03T03:00:00Z',
              endedAt: '2025-03-03T05:00:00Z',
              session: '1003',
              type: 'free'
            }
          ]
        },
        {
          slot: 'A-03',
          events: [
            {
              startedAt: '2025-03-02T23:00:00Z',
              endedAt: '2025-03-03T05:00:00Z',
              session: '1004',
              type: 'free'
            }
          ]
        }
      ],
      [
        {
          slot: 'A-01',
          events: [
            {
              startedAt: '2025-03-03T05:00:00Z',
              endedAt: '2025-03-03T11:00:00Z',
              session: '1001',
              type: 'free'
            },
            {
              startedAt: '2025-03-03T09:00:00Z',
              endedAt: '2025-03-03T11:00:00Z',
              session: '1002',
              type: 'free'
            }
          ]
        },
        {
          slot: 'A-02',
          events: [
            {
              startedAt: '2025-03-03T05:00:00Z',
              endedAt: '2025-03-03T11:00:00Z',
              session: '1003',
              type: 'free'
            }
          ]
        },
        {
          slot: 'A-03',
          events: [
            {
              startedAt: '2025-03-03T05:00:00Z',
              endedAt: '2025-03-03T11:00:00Z',
              session: '1004',
              type: 'free'
            }
          ]
        }
      ],
      [
        {
          slot: 'A-01',
          events: [
            {
              startedAt: '2025-03-03T11:00:00Z',
              endedAt: '2025-03-03T12:00:00Z',
              session: '1001',
              type: 'free'
            },
            {
              startedAt: '2025-03-03T11:00:00Z',
              endedAt: '2025-03-03T13:00:00Z',
              session: '1002',
              type: 'free'
            }
          ]
        },
        {
          slot: 'A-02',
          events: [
            {
              startedAt: '2025-03-03T11:00:00Z',
              endedAt: '2025-03-03T17:00:00Z',
              session: '1003',
              type: 'free'
            }
          ]
        },
        {
          slot: 'A-03',
          events: [
            {
              startedAt: '2025-03-03T11:00:00Z',
              endedAt: '2025-03-03T17:00:00Z',
              session: '1004',
              type: 'free'
            }
          ]
        }
      ],
      [
        {
          slot: 'A-02',
          events: [
            {
              startedAt: '2025-03-03T17:00:00Z',
              endedAt: '2025-03-03T23:00:00Z',
              session: '1003',
              type: 'free'
            }
          ]
        },

        {
          slot: 'A-03',
          events: [
            {
              startedAt: '2025-03-03T17:00:00Z',
              endedAt: '2025-03-03T23:00:00Z',
              session: '1004',
              type: 'free'
            }
          ]
        }
      ]
    ])
  })

  test('groupOccupancyByHourInterval can handle midnight, to midnight events', () => {
    const filteredOccupancy = groupOccupancyByHourInterval(
      [
        {
          slot: 'A-01',
          events: [
            {
              startedAt: '2025-03-02T23:00:00Z',
              endedAt: '2025-03-03T23:00:00Z',
              session: '1000',
              type: 'free'
            }
          ]
        }
      ],
      '2025-03-03'
    )

    expect(filteredOccupancy).toEqual([
      [
        {
          slot: 'A-01',
          events: [
            {
              startedAt: '2025-03-02T23:00:00Z',
              endedAt: '2025-03-03T05:00:00Z',
              session: '1000',
              type: 'free'
            }
          ]
        }
      ],
      [
        {
          slot: 'A-01',
          events: [
            {
              startedAt: '2025-03-03T05:00:00Z',
              endedAt: '2025-03-03T11:00:00Z',
              session: '1000',
              type: 'free'
            }
          ]
        }
      ],
      [
        {
          slot: 'A-01',
          events: [
            {
              startedAt: '2025-03-03T11:00:00Z',
              endedAt: '2025-03-03T17:00:00Z',
              session: '1000',
              type: 'free'
            }
          ]
        }
      ],
      [
        {
          slot: 'A-01',
          events: [
            {
              startedAt: '2025-03-03T17:00:00Z',
              endedAt: '2025-03-03T23:00:00Z',
              session: '1000',
              type: 'free'
            }
          ]
        }
      ]
    ])

    expect(
      groupOccupancyByHourInterval(
        [
          {
            slot: 'A-01',
            events: [
              {
                startedAt: '2025-03-03T23:00:00Z',
                endedAt: '2025-03-04T23:00:00Z',
                session: '1000',
                type: 'free'
              }
            ]
          }
        ],
        '2025-03-04'
      )
    ).toEqual([
      [
        {
          slot: 'A-01',
          events: [
            {
              startedAt: '2025-03-03T23:00:00Z',
              endedAt: '2025-03-04T05:00:00Z',
              session: '1000',
              type: 'free'
            }
          ]
        }
      ],
      [
        {
          slot: 'A-01',
          events: [
            {
              startedAt: '2025-03-04T05:00:00Z',
              endedAt: '2025-03-04T11:00:00Z',
              session: '1000',
              type: 'free'
            }
          ]
        }
      ],
      [
        {
          slot: 'A-01',
          events: [
            {
              startedAt: '2025-03-04T11:00:00Z',
              endedAt: '2025-03-04T17:00:00Z',
              session: '1000',
              type: 'free'
            }
          ]
        }
      ],
      [
        {
          slot: 'A-01',
          events: [
            {
              startedAt: '2025-03-04T17:00:00Z',
              endedAt: '2025-03-04T23:00:00Z',
              session: '1000',
              type: 'free'
            }
          ]
        }
      ]
    ])
  })

  test('groupOccupancyByHourInterval - never includes dates outside of day', () => {
    // Events on the 3rd, should not be included on the 4th
    expect(
      groupOccupancyByHourInterval(
        [
          {
            slot: 'A-01',
            events: [
              {
                startedAt: '2025-03-02T23:00:00Z', // 2025-03-03 00:00:00
                endedAt: '2025-03-03T23:00:00Z', // 2025-03-04 00:00:00
                session: '1000',
                type: 'free'
              }
            ]
          }
        ],
        '2025-03-04'
      )
    ).toEqual([[], [], [], []])

    // Events on the 4th should be included in the 3rd
    expect(
      groupOccupancyByHourInterval(
        [
          {
            slot: 'A-01',
            events: [
              {
                startedAt: '2025-03-03T23:00:00Z', // 2025-03-04 00:00:00
                endedAt: '2025-03-04T23:00:00Z', // 2025-03-05 00:00:00
                session: '1000',
                type: 'free'
              }
            ]
          }
        ],
        '2025-03-03'
      )
    ).toEqual([[], [], [], []])
  })
}
