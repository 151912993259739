import dayjs from 'dayjs'

import {
  Site,
  SitePricingConfiguration,
  SitePricingConfigurationAbsolute,
  SitePricingConfigurationRelative
} from '@contracts/types/Site'

import { getSitePricingTimeConfigurationItems } from '@pure/libs/getParkingSessionHelperPricing'
import { isAbsolutePricingConfiguration } from '@pure/libs/SiteHelper'

export function getFeeForStartAndEndTime(
  startedAt: string,
  endedAt: string,
  pricing: SitePricingConfiguration,
  site: Site
) {
  if (isAbsolutePricingConfiguration(pricing)) {
    return getAbsoluteFee(startedAt, endedAt, pricing, site)
  }

  return getRelativeFee(startedAt, endedAt, pricing)
}

function getRelativeFee(start: string, end: string, pricing: SitePricingConfigurationRelative) {
  const durationInSeconds = dayjs(end).diff(dayjs(start), 'seconds')

  const price = pricing.items.reduce(
    (acc, item) => {
      if (typeof item.intervalMinutes !== 'number') {
        return acc
      }

      if (acc.secondsRemaining === 0) {
        return acc
      }

      const itemDurationInSeconds = item.intervalMinutes * 60
      const perSecondPrice = item.perHourPrice / 60 / 60

      if (itemDurationInSeconds === 0) {
        acc.price += perSecondPrice * acc.secondsRemaining
        acc.secondsRemaining = 0

        return acc
      }

      acc.price += perSecondPrice * Math.min(acc.secondsRemaining, itemDurationInSeconds)
      acc.secondsRemaining = Math.max(0, acc.secondsRemaining - itemDurationInSeconds)

      return acc
    },
    {
      secondsRemaining: durationInSeconds,
      price: 0
    }
  ).price

  return Math.round(price * 100) / 100
}

function getAbsoluteFee(startedAt: string, endedAt: string, pricing: SitePricingConfigurationAbsolute, site: Site) {
  const items = getSitePricingTimeConfigurationItems({ startedAt, endedAt, pricingConfiguration: pricing, site })

  return items.reduce((acc, item) => {
    return acc + item.cost
  }, 0)
}
