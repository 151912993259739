import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'

interface MonthPickerProps {
  label: string
  date: dayjs.Dayjs | null
  minDate?: dayjs.Dayjs
  maxDate?: dayjs.Dayjs
  setDate: (newDate: dayjs.Dayjs | null) => void
}

const sx = {
  maxWidth: '27%',
  '& .MuiInputBase-root': {
    minHeight: '36px'
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1rem'
  }
}

export default function MonthPicker({ label, date, setDate, minDate, maxDate }: MonthPickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        format="MMM-YYYY"
        label={label}
        value={date}
        onChange={(newValue) => setDate(newValue)}
        slotProps={{ textField: { size: 'small' } }}
        sx={sx}
        views={['year', 'month']}
        openTo="month"
        minDate={minDate}
        maxDate={maxDate}
      />
    </LocalizationProvider>
  )
}
