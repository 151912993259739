const NavigationPaths = {
  Root: '/',
  Sites: '/sites',
  Sessions: '/sessions',
  Revenue: '/revenue',
  NewPermits: '/new-permits',
  CreateDebtor: '/create-debtor',
  CreatePermit: '/create-permit',
  EditPermit: '/edit-permit'
} as const

export default NavigationPaths
