import styled from '@emotion/styled'
import EastIcon from '@mui/icons-material/East'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Button } from '@mui/material'

import { Permit } from '@contracts/types/Permit'
import { PermitValidityPeriod } from '@contracts/types/PermitTemplate'

import { arrayEquals } from '@pure/libs/Common'
import { formatHour } from '@pure/libs/formatHour'
import { getStartAndEndHour, VALIDITY_WEEKDAYS } from '@pure/libs/permit/validityPeriodHelper'

import TextsExtended from '@my-drifter/assets/texts_extended.json'
import { Elevation1 } from '@my-drifter/libs/Elevation'
import { getFigmaText } from '@my-drifter/libs/TextRepository'
import { BodyMedium, BodySmall, BodySmallStrong, LabelMedium, TitleMediumRegular } from '@my-drifter/libs/Typography'

import { PortalColors } from './PortalColors'
import { PortalRadiuses } from './PortalRadiuses'
import { PortalSpacings } from './PortalSpacings'
import StatusChip from './StatusChip'
import { getWeekDayText } from './WeekdaySelector'

interface PermitInfoProps {
  permit: Permit
  onPermitSelected: () => void
  onShowPermitUsers: () => void
  onEditPermit: (id: string) => void
}

const InfoText = ({ headline, info }) => {
  return (
    <>
      <StyledInfoTextHeadline>{headline}</StyledInfoTextHeadline>
      <StyledTextInfo>{info}</StyledTextInfo>
    </>
  )
}

function getValidityDays(validityPeriods: PermitValidityPeriod[] = []) {
  if (validityPeriods.length === 0) {
    return getFigmaText(TextsExtended.sitesallWeek)
  }
  const days = validityPeriods.map((period) => period.dayOfWeek)
  const uniqueDays = [...new Set(days)]

  const isWeekend = uniqueDays.includes(6) && uniqueDays.includes(7) && uniqueDays.length === 2
  if (isWeekend) {
    return getFigmaText(TextsExtended.permitsInputFieldsweekends)
  }

  const isAllDaysInWeek = uniqueDays.length === 7
  if (isAllDaysInWeek) {
    return getFigmaText(TextsExtended.sitesallWeek)
  }

  const isOnlyWeekDays = arrayEquals(VALIDITY_WEEKDAYS, uniqueDays)
  if (isOnlyWeekDays) {
    return getFigmaText(TextsExtended.permitsInputFieldsweekdays)
  }

  return uniqueDays.map((day) => getWeekDayText(day)).join()
}

function getPeriods(validityPeriods: PermitValidityPeriod[] = []) {
  console.log(validityPeriods)
  const { startHour, endHour } = getStartAndEndHour(validityPeriods)
  if (startHour === 0 && endHour === 24) {
    return getFigmaText(TextsExtended.sitesallDay)
  }
  return `${formatHour(startHour)}-${formatHour(endHour)}`
}

function PermitInfo({ permit, onPermitSelected, onShowPermitUsers, onEditPermit }: PermitInfoProps) {
  const {
    id,
    status,
    name,
    maxNumberOfConcurrentParkedVehicles,
    userPermitConnections,
    fleetVehicles,
    price,
    includeSlotPlaceCodes,
    validityPeriods
  } = permit

  const quantity = maxNumberOfConcurrentParkedVehicles
  const userPermitConnectionsCount = userPermitConnections?.length ?? 0
  const fleetVehiclesCount = Object.values(fleetVehicles ?? {}).length
  const activeUsers = userPermitConnectionsCount + fleetVehiclesCount
  const isReserved = (includeSlotPlaceCodes && includeSlotPlaceCodes.length > 0) ?? false

  const handleEditButtonClick = () => onEditPermit(id)

  function onPermitTableOpenClick() {
    onPermitSelected()
    onShowPermitUsers()
  }
  return (
    <StyledWrapper>
      <StyledHeader>
        <StyledHeaderHeadline>
          {name}{' '}
          <StyledSpanHeader>
            (
            {isReserved
              ? getFigmaText(TextsExtended.permitsButtonsreservedPermit)
              : getFigmaText(TextsExtended.permitsButtonsfloatingPermit)}
            )
          </StyledSpanHeader>
        </StyledHeaderHeadline>

        <Button
          startIcon={<EditIcon style={{ color: PortalColors.textOnActionTertiary }} />}
          onClick={handleEditButtonClick}
          sx={{
            color: PortalColors.textOnActionTertiary,
            ...LabelMedium,
            textTransform: 'none',
            '&:hover': {
              backgroundColor: PortalColors.surfaceActionTertiaryHover
            }
          }}
        >
          {getFigmaText(TextsExtended.permitsButtonseditpermit)}
        </Button>
      </StyledHeader>
      <StyledContentContainer>
        <StatusChip status={status} />

        <Box sx={{ padding: 0 }}>
          <InfoText
            headline={getFigmaText(TextsExtended.permitsDashboardDebtorsAndPermitsquantity)}
            info={quantity && `${quantity} ${getFigmaText(TextsExtended.permitsDashboardDebtorsAndPermitspermits)}`}
          />
        </Box>

        <Box sx={{ padding: 0 }}>
          <InfoText
            headline={getFigmaText(TextsExtended.permitsDashboardDebtorsAndPermitsactiveUsers)}
            info={activeUsers && `${activeUsers} ${getFigmaText(TextsExtended.permitsDashboardDebtorsAndPermitsusers)}`}
          />
        </Box>

        <Box sx={{ padding: 0 }}>
          <InfoText
            headline={getFigmaText(TextsExtended.permitsDashboardDebtorsAndPermitsunitPrice)}
            info={price && ` ${price} kr`}
          />
        </Box>

        <Box sx={{ padding: 0, gridColumn: '1 / span 2' }}>
          <InfoText
            headline={getFigmaText(TextsExtended.permitsSetupNewPermitvalidityPeriod)}
            info={
              <>
                <StyledSpan>{getFigmaText(TextsExtended.permitsDashboardDebtorsAndPermitsdays)} </StyledSpan>
                {getValidityDays(validityPeriods)}
                <StyledSpan>| {getFigmaText(TextsExtended.permitsDashboardDebtorsAndPermitstime)} </StyledSpan>
                {getPeriods(validityPeriods)}
              </>
            }
          />
        </Box>

        <Box sx={{ padding: 0, gridColumn: '3' }}>
          <InfoText
            headline={
              includeSlotPlaceCodes ? getFigmaText(TextsExtended.permitsDashboardDebtorsAndPermitsslotnumbers) : ''
            }
            info={validityPeriods && includeSlotPlaceCodes && ` ${includeSlotPlaceCodes?.join(',')} `}
          />
        </Box>

        <Button
          startIcon={<EastIcon style={{ color: PortalColors.iconWhite }} />}
          onClick={onPermitTableOpenClick}
          sx={{
            justifySelf: 'end',
            minWidth: '36px',
            minHeight: '36px',
            gridColumn: '4',
            backgroundColor: PortalColors.surfaceActionSecondary,
            '& .MuiButton-startIcon': {
              margin: 0
            },
            '&:hover': {
              backgroundColor: PortalColors.surfaceActionSecondaryHover
            }
          }}
        />
      </StyledContentContainer>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div({
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  borderRadius: PortalRadiuses.sm,
  border: `1px solid ${PortalColors.borderDecorativeLow}`
})

const StyledHeader = styled.header({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: PortalColors.surfaceWhite,
  padding: `${PortalSpacings.x3} ${PortalSpacings.x4}`,
  boxShadow: Elevation1.boxShadow,
  borderTopLeftRadius: PortalRadiuses.sm,
  borderTopRightRadius: PortalRadiuses.sm
})

const StyledHeaderHeadline = styled.h3({
  color: PortalColors.textPrimary,
  ...TitleMediumRegular,
  margin: 0
})

const StyledContentContainer = styled.div({
  padding: PortalSpacings.x4,
  display: 'grid',
  gridTemplateColumns: ' repeat(4, 1fr)',
  gap: '1rem',
  backgroundColor: PortalColors.surfaceLevelSecond,
  borderRadius: PortalRadiuses.sm
})

const StyledInfoTextHeadline = styled.p({
  ...BodyMedium,
  color: PortalColors.textSecondary,
  margin: 0
})
const StyledTextInfo = styled.p({
  ...BodySmallStrong,
  color: PortalColors.textPrimary,
  margin: 0
})

const StyledSpanHeader = styled.span({ ...BodyMedium, color: PortalColors.textSecondary })
const StyledSpan = styled.span({ ...BodySmall, color: PortalColors.textSecondary })

export default PermitInfo
