import { PortalColors } from '@my-drifter/components/PortalColors'

export const Elevation1 = {
  boxShadow: `0px 1px 3px 0px ${PortalColors.elevationShadowPrimary}, 0px 2px 4px 0px ${PortalColors.elevationShadowSecondary}`
}

export const Elevation2 = {
  boxShadow: `0px 2px 6px 0px ${PortalColors.elevationShadowPrimary} ${PortalColors.elevationShadowSecondary}`
}

export const Elevation3 = {
  boxShadow: `0px 4px 8px 0px ${PortalColors.elevationShadowPrimary} ${PortalColors.elevationShadowSecondary}`
}
