import styled from '@emotion/styled'

import { Colors } from '@pure/assets/Colors'

import { useIsDesktop } from '@my-drifter/hooks/useIsMobile'
import { useLogout } from '@my-drifter/hooks/useLogout'
import Texts from '@my-drifter/libs/Texts'

import { Spacings } from '@web-components/enums/Spacings'

import Box from './Box'
import DropdownSelector from './DropdownSelector'
import FigmaText from './FigmaText'
import { LAYOUT_LEFT_PADDING } from './HardcodedSizes'

type DesktopHeaderProps = {
  siteOptions: { label: string; value: string }[]
  selectedSite?: string
  onSelectSite: (value: string) => void
}

const DesktopHeader = ({ siteOptions, selectedSite, onSelectSite }: DesktopHeaderProps) => {
  const logOut = useLogout()
  const isDesktop = useIsDesktop()

  const selectedSiteOption = siteOptions?.find((option) => option.value === selectedSite)

  if (!isDesktop) {
    return (
      <Box align="flex-end" onClick={logOut}>
        <FigmaText textKey={Texts.textMenuUserMenuUserMenuLogout} />
      </Box>
    )
  }

  return (
    <Container
      fullWidth
      direction="row"
      align="center"
      justify="space-between"
      zIndex={1}
      top
      left
      right
      bottom
      spacing={Spacings.s}
    >
      {siteOptions.length > 1 ? (
        <DropdownSelector dropdownType="site" options={siteOptions} selected={selectedSite} onSelect={onSelectSite} />
      ) : (
        selectedSiteOption?.label
      )}
    </Container>
  )
}

const Container = styled(Box)`
  font-family: Poppins;
  background-color: ${Colors.white};
  border-bottom: 1px solid ${Colors.topBorderBackground};
  padding-left: 24px;
  height: 56px;
  position: sticky;
  top: 0;
  left: ${LAYOUT_LEFT_PADDING};
  right: 0;
`
// TODO: Make Header flex after adding the language selector
//   display: inline-flex;
//   justify-content: flex-end;
//   align-items: center;
// `

export default DesktopHeader
