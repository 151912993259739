import styled from '@emotion/styled'
import { Box as MuiBox } from '@mui/material'
import { CSSProperties, memo, ReactNode, useEffect, useMemo, useState } from 'react'

import { HikerUserRole } from '@contracts/types/HikerUser'

import { Colors } from '@pure/assets/Colors'

import { HIKER_ENABLED_SITE_IDS } from '@session/shared/enabledSites'

import useAppState from '@my-drifter/hooks/useAppState'
import { useIsDesktop, useIsXLDesktop } from '@my-drifter/hooks/useIsMobile'
import { useSite } from '@my-drifter/hooks/useSite'
import { useUser } from '@my-drifter/hooks/useUser'
import { getSiteNames } from '@my-drifter/libs/CloudFunctionsApiHandler'

import Box from './Box'
import DesktopHeader from './DesktopHeader'
import DropdownSelector from './DropdownSelector'
import { LAYOUT_LEFT_PADDING, LAYOUT_LEFT_XL_PADDING } from './HardcodedSizes'
import LeftNavigation from './LeftNavigation'
import Loader from './Loader'

type Props = {
  children: ReactNode
  siteSVGs?: Record<string, string>
  isDrawerOpen?: boolean
  selectedSiteId?: string
  isDashboardScreen?: boolean
  isLoading?: boolean
  childrenContainerStyle?: CSSProperties
  setSelectedSiteId?: (value: string) => void
}

const Layout = ({
  children,
  siteSVGs,
  isDrawerOpen,
  selectedSiteId: initialSelectedSiteId,
  isDashboardScreen,
  isLoading,
  childrenContainerStyle,
  setSelectedSiteId
}: Props) => {
  const { state } = useAppState()
  const isDesktop = useIsDesktop()
  const isXLDesktop = useIsXLDesktop()
  const { data: user, isLoading: isLoadingUser } = useUser(localStorage.getItem('userId') || state?.userId)

  const [siteNames, setSiteNames] = useState<{ id: string; name: string }[]>([])

  const siteNameById = useMemo(
    () => siteNames.reduce((acc, site) => ({ ...acc, [site.id]: site.name }), {}),
    [siteNames]
  )

  const selectedSiteId = initialSelectedSiteId ?? user?.siteIds?.[0]

  const { data: site } = useSite(selectedSiteId)

  const siteOptions = useMemo(() => {
    // Only show dashboardscreen options for sites that we have an svg for
    if (!site || !Array.isArray(user?.siteIds)) {
      return []
    }

    if (isDashboardScreen && user.siteIds.length > 1) {
      return user.siteIds
        ?.map((siteId) => ({ label: `${siteNameById[siteId] ?? '...'} (${siteId})`, value: siteId }))
        .filter((site) => Object.keys(siteSVGs ?? []).includes(site.value))
    }

    return user.siteIds
      ?.map((siteId) => ({ label: `${siteNameById[siteId] ?? '...'} (${siteId})`, value: siteId }))
      .filter((site) => user.role === HikerUserRole.ROOT || HIKER_ENABLED_SITE_IDS.includes(site.value))
  }, [user, site, siteNameById])

  useEffect(() => {
    getSiteNames().then((sites) => {
      setSiteNames(sites.filter((site) => user?.siteIds?.includes(site.id)))
    })
  }, [user?.siteIds])

  return (
    <Container fullWidth fullHeight top={!isDesktop} left={!isDesktop} right={!isDesktop}>
      <LeftNavigation />
      <ChildrenContainer
        fullWidth
        fullHeight
        isDrawerOpen={isDrawerOpen}
        isDesktop={isDesktop}
        isXLDesktop={isXLDesktop}
        style={{
          minHeight: 0,
          flex: 1,

          ...childrenContainerStyle
        }}
      >
        {!isDesktop && !isLoadingUser && (
          <DropdownSelector
            dropdownType="site"
            options={siteOptions}
            selected={selectedSiteId}
            onSelect={(value) => setSelectedSiteId && setSelectedSiteId(value)}
          />
        )}

        {isDesktop && !isLoadingUser && (
          <DesktopHeader
            siteOptions={siteOptions}
            selectedSite={selectedSiteId}
            onSelectSite={(value) => setSelectedSiteId && setSelectedSiteId(value)}
          />
        )}
        {!isLoading ? (
          <MuiBox sx={{ p: 4, height: '100%', flex: 1, display: 'flex', flexDirection: 'column', minHeight: 0 }}>
            {children}
          </MuiBox>
        ) : (
          <Box fullWidth style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
            <Loader />
          </Box>
        )}
      </ChildrenContainer>
    </Container>
  )
}

const Container = styled(Box)`
  height: 100vh;
  background-color: ${Colors.white};
`

export const calculateLeftPadding = (isDesktop?: boolean, isXLDesktop?: boolean): string => {
  if (isXLDesktop) {
    return LAYOUT_LEFT_XL_PADDING
  }
  if (isDesktop) {
    return LAYOUT_LEFT_PADDING
  }
  return '0'
}

const ChildrenContainer = styled(Box)<{ isDrawerOpen?: boolean; isDesktop?: boolean; isXLDesktop?: boolean }>`
  padding-left: ${(props) => calculateLeftPadding(props.isDesktop, props.isXLDesktop)};
  transition: padding-right 0.2s ease-in-out;
  background-color: ${Colors.numbersBackground};
`

export default memo(Layout)
