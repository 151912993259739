import { Box } from '@mui/material'
import { grey } from '@mui/material/colors'
import { memo } from 'react'

import { Camera } from '@contracts/types/Camera'
import { HikerUserRole } from '@contracts/types/HikerUser'

import { collection, query, where } from '@firebase/firestore'

import { useFirestoreQuery } from '@web-js/hooks/useFirestoreQuery'

import CameraList from '@my-drifter/components/CameraList'
import Layout from '@my-drifter/components/Layout'
import useAppState from '@my-drifter/hooks/useAppState'
import { useSelectedSiteContext } from '@my-drifter/hooks/useSelectedSiteContext'
import { useSite } from '@my-drifter/hooks/useSite'
import { useUser } from '@my-drifter/hooks/useUser'
import { db } from '@my-drifter/libs/FirebaseOptions'

import NotAuthorizedScreen from './NotAuthorizedScreen'

const CameraScreen = () => {
  const { state } = useAppState()

  const { data: user, isLoading: isLoadingUser } = useUser(localStorage.getItem('userId') || state?.userId)

  const isAllowed =
    !isLoadingUser &&
    user?.role &&
    [HikerUserRole.ADMIN, HikerUserRole.ROOT].includes(user.role) &&
    user?.siteIds &&
    user?.siteIds?.length > 0

  const { selectedSiteId, setSelectedSiteId } = useSelectedSiteContext()

  const { data: site, isLoading: isLoadingSite } = useSite(selectedSiteId)

  const { data: cameras = [], isLoading: loading } = useFirestoreQuery<Camera[]>(
    query(collection(db, 'cameras'), where('siteId', '==', site?.id ?? '')),
    {
      enabled: Boolean(site?.id),
      fnName: 'useCameras'
    }
  )

  if (!isAllowed && !isLoadingUser) return <NotAuthorizedScreen />

  return (
    <Layout
      selectedSiteId={selectedSiteId}
      setSelectedSiteId={(id) => {
        setSelectedSiteId(id)
      }}
      isLoading={isLoadingUser}
    >
      <Box
        sx={{
          border: `1px solid ${grey[300]}`,
          borderRadius: 2,
          backgroundColor: 'white',
          m: 2,
          mt: 0,
          p: 4,
          fontFamily: 'Inter'
        }}
      >
        {site ? <CameraList cameras={cameras} site={site} /> : 'No site selected. Select one from the dropdown above'}
      </Box>
    </Layout>
  )
}

export default memo(CameraScreen)
