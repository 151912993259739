import styled from '@emotion/styled'
import WarningIcon from '@mui/icons-material/Warning'
import { Backdrop, BoxProps, Button, Divider } from '@mui/material'
import { Box } from '@mui/material'
import React, { memo, useState } from 'react'

import ZIndices from '@web-js/enums/Zindices'

import { BodyLargeRegular, TitleLarge, TitleLargeStrong } from '@my-drifter/libs/Typography'

import { LAYOUT_LEFT_PADDING } from './HardcodedSizes'
import Loader from './Loader'
import { PortalColors } from './PortalColors'
import { PortalRadiuses } from './PortalRadiuses'
import { PortalSpacings } from './PortalSpacings'

interface FullScreenModalProps {
  isOpen: boolean
  actionType?: 'deactivate' | 'reactivate'
  headerText?: string
  titleText?: string
  secondaryText?: string
  leftButtonText?: string
  leftButtonOnClick: () => void
  rightButtonText?: string
  rightButtonOnClick: () => void
  children?: React.ReactNode
}

const FullScreenModal: React.FC<FullScreenModalProps> = ({
  isOpen,
  headerText,
  titleText,
  secondaryText,
  leftButtonText,
  leftButtonOnClick,
  rightButtonText,
  rightButtonOnClick,
  children,
  actionType
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleLeftButtonClick = async () => {
    setIsLoading(true)
    try {
      await leftButtonOnClick()
    } finally {
      setIsLoading(false)
    }
  }

  const handleRightButtonClick = () => {
    rightButtonOnClick()
  }

  if (!isOpen) return null

  return (
    <CustomBackdrop open={isOpen} onClick={rightButtonOnClick}>
      {/* Stop click propagation so clicking inside the modal doesn’t close it */}
      <ModalContainer onClick={(e) => e.stopPropagation()} sx={{ position: 'relative', minHeight: '400px' }}>
        {isLoading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1
            }}
          >
            <Loader $loaderColor={PortalColors.surfaceActionSecondary} />
          </Box>
        )}
        <>
          <StyledHeader action={actionType ?? 'deactivate'}>
            {actionType === 'deactivate' && <WarningIcon sx={{ color: PortalColors.iconError }} />}
            <p>{headerText}</p>
          </StyledHeader>
          <StyledContent>
            <StyledDescription>
              <span>{titleText}</span>
              <p>{secondaryText}</p>
            </StyledDescription>
            <Divider
              sx={{
                backgroundColor: PortalColors.borderDecorativeLow
              }}
            />
            {children}
            <StyledButtonContainer>
              <Button
                onClick={handleLeftButtonClick}
                sx={{
                  backgroundColor: actionType === 'deactivate' ? PortalColors.iconError : PortalColors.iconBrand,
                  color: PortalColors.textOnActionPrimary,
                  borderRadius: PortalRadiuses.sm,
                  textTransform: 'none',
                  padding: `${PortalSpacings.x3} ${PortalSpacings.x4}`,
                  '&:hover': {
                    backgroundColor:
                      actionType === 'deactivate' ? PortalColors.borderError : PortalColors.surfaceActionPrimaryPressed
                  }
                }}
                disabled={isLoading}
              >
                {leftButtonText}
              </Button>
              <Button
                onClick={handleRightButtonClick}
                sx={{
                  backgroundColor: PortalColors.surfaceActionSecondary,
                  color: PortalColors.textOnActionSecondary,
                  borderRadius: PortalRadiuses.sm,
                  textTransform: 'none',
                  padding: `${PortalSpacings.x3} ${PortalSpacings.x4}`,
                  '&:hover': {
                    backgroundColor: PortalColors.surfaceActionSecondaryHover
                  }
                }}
              >
                {rightButtonText}
              </Button>
            </StyledButtonContainer>
          </StyledContent>
        </>
      </ModalContainer>
    </CustomBackdrop>
  )
}
interface StyledHeaderProps extends BoxProps {
  action: 'deactivate' | 'reactivate'
}
const CustomBackdrop = styled(Backdrop)({
  position: 'fixed',
  top: 0,
  left: LAYOUT_LEFT_PADDING,
  width: `calc(100% - ${LAYOUT_LEFT_PADDING})`,
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: ZIndices.modal,
  backgroundColor: 'rgba(0,0,0,0.5)'
})

const ModalContainer = styled(Box)({
  backgroundColor: PortalColors.surfaceLevelFirst,
  borderRadius: PortalRadiuses.lg,
  width: '80%',
  maxWidth: 600,
  overflow: 'hidden'
})

const StyledHeader = styled(Box)<StyledHeaderProps>(({ action }) => ({
  display: 'flex',
  gap: PortalSpacings.x4,
  backgroundColor: action === 'deactivate' ? PortalColors.surfaceError : PortalColors.surfaceLevelSecond,
  padding: PortalSpacings.x6,
  '& > p': {
    color: action === 'deactivate' ? PortalColors.textError : PortalColors.textPrimary,
    margin: 0,
    ...TitleLarge
  }
}))

const StyledDescription = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: PortalSpacings.x2,
  '& > span': { color: PortalColors.textPrimary, ...TitleLargeStrong },
  '& > p': { color: PortalColors.textPrimary, ...BodyLargeRegular, margin: 0 }
})

const StyledContent = styled(Box)({
  padding: PortalSpacings.x6,
  display: 'flex',
  flexDirection: 'column',
  gap: PortalSpacings.x6
})

const StyledButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: PortalSpacings.x4
})
export default memo(FullScreenModal)
