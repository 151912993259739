import styled from '@emotion/styled'
import CloseIcon from '@mui/icons-material/Close'
import { Box as MuiBox, Drawer, IconButton } from '@mui/material'
import { memo, ReactNode } from 'react'

import { useIsDesktop } from '@my-drifter/hooks/useIsMobile'
import { TitleLarge } from '@my-drifter/libs/Typography'

import { PortalColors } from './PortalColors'
import { PortalRadiuses } from './PortalRadiuses'
import { PortalSpacings } from './PortalSpacings'

type RightSideDrawerProps = {
  isDrawerOpen: boolean
  title: string
  setIsDrawerOpen: () => void
  children: ReactNode
}

const NewRightSideDrawer = ({ isDrawerOpen, setIsDrawerOpen, children, title }: RightSideDrawerProps) => {
  const isDesktop = useIsDesktop()
  const DRAWER_WIDTH = '350px'
  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={setIsDrawerOpen}
      PaperProps={{
        sx: {
          backgroundColor: PortalColors.surfaceWhite, // Ensure a visible background
          width: DRAWER_WIDTH,
          padding: PortalSpacings.x6,
          gap: PortalSpacings.x6,
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <MuiBox sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <StyledHeadline>{title}</StyledHeadline>

        <IconButton
          onClick={setIsDrawerOpen}
          aria-label="close"
          sx={{
            borderRadius: PortalRadiuses.sm,
            '&:hover': {
              backgroundColor: PortalColors.surfaceActionTertiaryHover
            }
          }}
        >
          <CloseIcon sx={{ color: PortalColors.textPrimary }} />
        </IconButton>
      </MuiBox>
      {children}
    </Drawer>
  )
}

const StyledHeadline = styled.p({
  margin: 0,
  color: PortalColors.textPrimary,
  ...TitleLarge
})

export default memo(NewRightSideDrawer)
