import dayjs from 'dayjs'
import invariant from 'invariant'

import { YYYY_MM_DD } from '@consts/DateConsts'
import { EUROPE_STOCKHOLM } from '@consts/DateConsts'

import { ParkingSession, ParkingSessionEventOffense } from '@contracts/types/ParkingSession'
import { SessionEventTypesWithParkingFee } from '@contracts/types/ParkingSessionEventTypeInfo'
import { ChildSession, OnSessionClosedRequest, Payment, Session, SessionSite } from '@contracts/types/Session'
import {
  SessionPricingItem,
  Site,
  SitePricingConfiguration,
  SitePricingConfigurationAbsolute,
  SitePricingConfigurationAbsoluteItem,
  SitePricingConfigurationRelativeItem
} from '@contracts/types/Site'

import { DayJS } from '@pure/libs/DayJsHelper'
import { getPartialPayment } from '@pure/libs/PaymentHelper'
import { isFalseSession } from '@pure/libs/SessionHelper'

import { endEvent } from './getParkingSessionEventsHelper'

export const getPartialPaymentForActiveParkingSession = (p: {
  parkingSession: ParkingSession
  site: Site
  now?: string
}) => {
  const events = p.parkingSession.events.filter((e) => !!e.startedAt)
  let lastEvent = events[events.length - 1]
  const endedAt = p.now || dayjs().format()
  const moment = endedAt

  if (!lastEvent) return undefined
  lastEvent = { ...endEvent({ ...lastEvent, endedAt }, moment, p.site, p.parkingSession) }

  p.parkingSession.events[events.length - 1] = lastEvent

  return getPartialPaymentForParkingSession(p)
}

export const getPartialPaymentForParkingSession = (p: {
  parkingSession: ParkingSession
  site: SessionSite
  now?: string
}): Payment | undefined =>
  getPartialPayment(
    {
      site: p.site,
      parkingSession: { events: p.parkingSession.events },
      request: getOnSessionClosedRequestForParkingSession(p)
    } as Session,
    { now: p.now }
  )

export function getOnSessionClosedRequestForParkingSession({
  parkingSession
}: {
  parkingSession: ParkingSession
  site: SessionSite
  now?: string
}): OnSessionClosedRequest {
  return {
    sessionId: parkingSession.id,
    fees: {
      parkingFee: isFalseSession({ parkingSession } as Session)
        ? 0
        : parkingSession.events
            .filter((event) => SessionEventTypesWithParkingFee.includes(event.type || 'parking'))
            .reduce((acc, event) => acc + sum(event.pricingItems || []), 0)
    },
    childSessions: parkingSession.events
      .filter((event) => event.type === 'offense')
      .map(
        (offenseEvent) =>
          ({
            costs: sum(offenseEvent.pricingItems || []),
            offense_type: (offenseEvent as ParkingSessionEventOffense).offenseType as string
          }) as ChildSession
      )
  }
}

const sum = (items: { cost?: number }[]) => items.reduce((acc, item) => acc + (item?.cost || 0), 0) || 0
export type Options = {
  pricingConfiguration: SitePricingConfiguration
  startedAt: string
  endedAt: string
  site: Site
  enableFreeDuration?: boolean
}

export const getSitePricingTimeConfigurationItems = (o: Options): SessionPricingItem[] => {
  const { type } = o.pricingConfiguration
  if (type === 'relative') return getSitePricingConfigurationRelativeItems(o)
  if (type === 'absolute') return getSitePricingConfigurationAbsoluteItems(o)

  return []
}

export const getSitePricingConfigurationRelativeItems = (o: Options): SessionPricingItem[] => {
  const { enableFreeDuration = true } = o
  invariant(o.pricingConfiguration.type === 'relative', 'type must be relative')

  const { items, freeDurationMinutes = 0, type } = o.pricingConfiguration
  invariant(type === 'relative', 'type must be relative')

  return items.map((item, index, arr) => {
    const enableOffsetMinutes = index === 0 && enableFreeDuration
    const offsetMinutes = enableOffsetMinutes ? freeDurationMinutes : 0
    return getCostForRelativePricingItem({
      item: item as SitePricingConfigurationRelativeItem,
      index,
      arr,
      offsetMinutes: offsetMinutes,
      options: o
    })
  })
}

export const getSitePricingConfigurationAbsoluteItems = (o: Options): SessionPricingItem[] => {
  invariant(o.pricingConfiguration.type === 'absolute', 'type must be absolute')

  let remainingFreeMinutes = o.pricingConfiguration.dailyFreeDurationMinutes || 0

  return o.pricingConfiguration.items
    .reduce((a, i) => {
      const item = i
      if (!item.dayOfWeek && !item.dayOfWeeks)
        return [...a, ...[1, 2, 3, 4, 5, 6, 7].map((dayOfWeek) => ({ ...item, dayOfWeek, dayOfWeeks: undefined }))]

      if (item.dayOfWeeks)
        return [...a, ...item.dayOfWeeks.map((dayOfWeek) => ({ ...item, dayOfWeek, dayOfWeeks: undefined }))]
      return [...a, item]
    }, [] as SitePricingConfigurationAbsoluteItem[])
    .map((item) => {
      const itemWithCost = getAbsolutePricingItemWithCost({
        item,
        options: o,
        remainingFreeMinutes
      })

      remainingFreeMinutes -= itemWithCost.freeDurationMinutes || 0
      return itemWithCost
    })
}

export const getCostForRelativePricingItem = ({
  item,
  index,
  arr,
  offsetMinutes,
  options
}: {
  item: SitePricingConfigurationRelativeItem
  index: number
  arr: SitePricingConfigurationRelativeItem[]
  options: Options
  offsetMinutes?: number
}): SessionPricingItem => {
  const { startedAt, endedAt } = options
  const { intervalMinutes = 0, perHourPrice } = item

  const itemEndsAtMinute = arr
    .filter((_, i) => i <= index)
    .reduce((acc, { intervalMinutes: interval = 0 }) => acc + interval, 0)

  let itemStartsAtMinute = itemEndsAtMinute - intervalMinutes

  const res: SessionPricingItem = {
    item,
    cost: 0,
    startedAt: dayjs(startedAt).add(itemStartsAtMinute, 'minute').utc().format(),
    endedAt: dayjs(startedAt).add(itemEndsAtMinute, 'minute').utc().format(),
    freeDurationMinutes: offsetMinutes
  }

  itemStartsAtMinute += offsetMinutes || 0

  const itemStartsAt = dayjs(startedAt).add(itemStartsAtMinute, 'minute').utc().format()
  const itemEndsAt = dayjs(startedAt).add(itemEndsAtMinute, 'minute').utc().format()

  // TODO WRITE TEST, should not have negative cost
  if (!intervalMinutes && dayjs(itemStartsAt).isBefore(endedAt))
    return {
      ...res,
      endedAt,
      cost: (perHourPrice * dayjs(endedAt).diff(itemStartsAt, 'seconds')) / 3600
    }

  if (dayjs(startedAt).isAfter(itemEndsAt)) return res

  if (dayjs(endedAt).isBefore(itemStartsAt)) return res

  const minDate = Math.min(dayjs(endedAt).valueOf(), dayjs(itemEndsAt).valueOf())
  const seconds = dayjs(minDate).diff(itemStartsAt, 'seconds')

  // TODO WRITE TEST, should set correct endedAt
  return { ...res, endedAt: dayjs(minDate).utc().format(), cost: (perHourPrice / 3600) * seconds }
}

export const getAbsolutePricingItemWithCost = ({
  item,
  options,
  remainingFreeMinutes = 0
}: {
  item: SitePricingConfigurationAbsoluteItem
  options: Options
  remainingFreeMinutes?: number
}): SessionPricingItem => {
  const { site, startedAt: _startedAt, endedAt = dayjs().format() } = options

  const timezone = site.timezone || EUROPE_STOCKHOLM

  const { perHourPrice } = item
  const { enableFreeDuration = true, pricingConfiguration } = options

  const { from, to } = getDateRangeSitePricingConfigurationAbsoluteItem(endedAt, item, site)

  const startedAt = dayjs(Math.max(dayjs(_startedAt).valueOf(), dayjs(from).valueOf()))
    .tz(timezone)
    .format()

  const minEndedAt = Math.min(dayjs(endedAt).valueOf(), dayjs(to).valueOf())
  const maxStartedAt = Math.max(dayjs(startedAt).valueOf(), dayjs(from).valueOf())

  // if set any absolute daily free duration minutes
  let usedFreeMinutes = 0
  const isDailyFreeDurationMinutes = (pricingConfiguration as SitePricingConfigurationAbsolute)?.dailyFreeDurationMinutes
  if (!enableFreeDuration) {
    usedFreeMinutes = 0
  } else if (isDailyFreeDurationMinutes) {
    //calculate free minutes based on daily free duration minutes
    usedFreeMinutes = Math.min(remainingFreeMinutes, dayjs(minEndedAt).diff(dayjs(startedAt), 'minutes'))
  } else {
    // use old way to calculate free minutes, free minutes set per item
    usedFreeMinutes = item.freeDurationMinutes || 0
  }

  const startedAtWithOffset = dayjs(startedAt).add(usedFreeMinutes, 'minute')
  const maxStartedAtWithOffset = Math.max(dayjs(startedAtWithOffset).valueOf(), dayjs(from).valueOf())
  const seconds = dayjs(minEndedAt).diff(dayjs(maxStartedAtWithOffset), 'seconds')

  const res = {
    item,
    startedAt,
    endedAt: to.utc().format(),
    cost: 0,
    freeDurationMinutes: Math.max(0, usedFreeMinutes) // don't allow negative free minutes
  }

  if (dayjs(endedAt).isBefore(from)) return res

  if (startedAtWithOffset.isAfter(to)) return res

  return {
    ...res,
    item,
    startedAt: dayjs(maxStartedAt).utc().format(),
    endedAt: dayjs(minEndedAt).utc().format(),
    cost: (perHourPrice * seconds) / 3600,
    freeDurationMinutes: Math.max(0, usedFreeMinutes) // don't allow negative free minutes
  }
}

const getTimeParts = (time: string): { hour: number; minutes: number } => {
  const [hour, minutes] = time.split(':').map(Number)
  invariant(hour >= 0 && hour <= 24, 'hour must be between 0 and 23, %s', time)
  invariant(minutes >= 0 && minutes <= 59, 'minutes must be between 0 and 59 %s', time)
  return { hour, minutes }
}

export function getDateRangeSitePricingConfigurationAbsoluteItem(
  date: string,
  { fromTime, toTime, dayOfWeek }: SitePricingConfigurationAbsoluteItem,
  { timezone = EUROPE_STOCKHOLM }: Site
) {
  const { hour: fromHour, minutes: fromMinutes } = getTimeParts(fromTime)
  const { hour: toHour, minutes: toMinutes } = getTimeParts(toTime)

  const startOfWeekDate = DayJS(date).isoWeekday(1).format(YYYY_MM_DD)
  const startOfWeek = dayjs(startOfWeekDate).tz(timezone, true)
  invariant(dayOfWeek, 'day of week should be defined')
  const startOfDay = startOfWeek.add(dayOfWeek - 1, 'day')

  const from = startOfDay.add(fromHour, 'hour').add(fromMinutes, 'minute')
  const to = startOfDay.add(toHour, 'hour').add(toMinutes, 'minute')
  return { from, to }
}
