import { collection, query, where } from 'firebase/firestore'

import Collections from '@contracts/enums/Collections'
import { HikerUser } from '@contracts/types/HikerUser'

import { useFirestoreQuery } from '@web-js/hooks/useFirestoreQuery'

import { db } from '@my-drifter/libs/FirebaseOptions'

export function usePermitUsers(permitId?: string) {
  return useFirestoreQuery<HikerUser[]>(
    query(collection(db, Collections.USERS), where(`permits.${permitId}`, '!=', null)),
    {
      fnName: 'useUsers',
      enabled: !!permitId
    }
  )
}
