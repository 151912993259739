import styled from '@emotion/styled'
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined'
import { Button } from '@mui/material'

import { BodyLargeRegular, LabelLargeRegular, TitleLargeStrong } from '@my-drifter/libs/Typography'

import { PortalColors } from './PortalColors'
import { PortalRadiuses } from './PortalRadiuses'
import { PortalSpacings } from './PortalSpacings'

interface EmptyStateScreen {
  title: string
  description?: string
  action?: () => void
  actionTitle?: string
}

function EmptyStateSceen({ title, description, action, actionTitle }: EmptyStateScreen) {
  return (
    <StyledCenteredArea>
      <StyledEllipse>
        <InsertPhotoOutlinedIcon sx={{ height: '48px', width: '48px', color: PortalColors.iconPrimary }} />
      </StyledEllipse>
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{description}</StyledDescription>
      {action && (
        <Button
          sx={{
            backgroundColor: PortalColors.surfaceActionPrimary,
            color: PortalColors.textOnActionPrimary,
            padding: `${PortalSpacings.x3} ${PortalSpacings.x4}`,
            ...LabelLargeRegular,
            textTransform: 'none',
            borderRadius: PortalRadiuses.sm,
            '&:hover': {
              backgroundColor: PortalColors.surfaceActionPrimaryHover
            },
            '&:active': {
              backgroundColor: PortalColors.surfaceActionPrimaryPressed
            }
          }}
          onClick={() => action()}
        >
          {actionTitle}
        </Button>
      )}
    </StyledCenteredArea>
  )
}

export default EmptyStateSceen

const StyledCenteredArea = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  maxWidth: '552px',
  gap: PortalSpacings.x6,
  margin: 'auto'
})

const StyledEllipse = styled.div({
  backgroundColor: PortalColors.surfaceLevelThird,
  height: '120px',
  width: '120px',
  borderRadius: '60px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})

const StyledTitle = styled.h2({
  ...TitleLargeStrong,
  color: PortalColors.textPrimary,
  margin: 0
})

const StyledDescription = styled.p({
  ...BodyLargeRegular,
  color: PortalColors.textSecondary,
  margin: 0
})
