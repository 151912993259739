enum InternalColors {
  blue = '#1076B0',
  blue100 = '#C3E6FA',
  blue500 = '#138BCF',
  blue700 = '#0C5B87',
  grey50 = '#F0F3FA',
  grey100 = '#D3DEED',
  grey300 = '#99A7BA',
  grey500 = '#64748B',
  grey600 = '#546378',
  emerald100 = '#ACFAD6',
  emerald400 = '#23B080',
  emerald500 = '##06946D',
  green = '#B9F0C9',
  green400 = '#2DBD56',
  green600 = '#1B943D',
  green700 = '#12732D',
  white = '#FFFFFF',
  navigationBlue = '#0A004A',
  purple50 = '#F3EEFF',
  purple100 = '#E3DFFF',
  purple200 = '#C4C0FF',
  purple500 = '#6D63F1',
  purple600 = '#5347D6',
  purple700 = '#3929BE',
  purple800 = '#2300A3',
  purple900 = '#130068',
  red100 = '#FAD3CF',
  red500 = '#D34444',
  red600 = '#B83336',
  red700 = '#911F29',
  yellow100 = '#FCE4A2',
  yellow400 = '#D1AC1D',
  yellow700 = '#826607',
  yellow800 = '#614C04',

  colorTransparencyLightest = '#0A004A08',
  colorTransparencyLight = '#0A004A16'
}

export const PortalColors = {
  borderDecorativeLow: InternalColors.grey100,
  borderDecorativeMid: InternalColors.grey300,
  borderInformation: InternalColors.blue500,
  borderSuccess: InternalColors.green400,
  borderError: InternalColors.red500,
  borderWarning: InternalColors.yellow400,
  borderStrong: InternalColors.purple900,
  borderBrand: InternalColors.purple500,
  borderAccessibleHigh: InternalColors.grey500,
  topBorderBackground: InternalColors.grey100,
  buttonHovered: InternalColors.grey100,
  textWhite: InternalColors.grey50,
  textWarning: InternalColors.yellow800,
  iconWhite: InternalColors.white,
  iconSuccess: InternalColors.green600,
  iconWarning: InternalColors.yellow700,
  iconPrimary: InternalColors.purple900,
  iconSecondary: InternalColors.grey500,
  surfaceError: InternalColors.red100,
  surfaceSuccess: InternalColors.green,
  surfaceInformation: InternalColors.blue100,
  surfaceWarning: InternalColors.yellow100,
  surfaceWhite: InternalColors.white,
  surfaceHighlightPrimary: InternalColors.purple500,
  textSelectedWhite: InternalColors.white,
  navigationBlue: InternalColors.navigationBlue,
  navigationPressed: InternalColors.purple800,
  navigationHovered: InternalColors.purple900,
  infographicsBrand: InternalColors.purple500,
  surfaceLevelFirst: InternalColors.white,
  surfaceLevelSecond: InternalColors.grey50,
  surfaceLevelThird: InternalColors.grey100,
  surfaceActionPrimary: InternalColors.purple500,
  surfaceActionPrimaryHover: InternalColors.purple600,
  surfaceActionPrimaryPressed: InternalColors.purple700,
  surfaceActionSecondary: InternalColors.purple900,
  surfaceActionSecondaryHover: InternalColors.purple800,
  surfaceActionSecondaryPressed: InternalColors.purple700,
  surfaceActionTertiary: InternalColors.purple50,
  surfaceActionTertiaryHover: InternalColors.purple200,
  surfaceActionTertiaryPressed: InternalColors.purple500,
  surfaceChipBlue: InternalColors.blue100,
  surfaceChipGreen: InternalColors.green,
  surfaceChipPurple: InternalColors.purple100,
  textOnChipSuccess: InternalColors.green700,
  textOnChipInformation: InternalColors.blue700,
  textOnDisabled: InternalColors.grey600,
  textOnHighLightSecondary: InternalColors.white,
  textPrimary: InternalColors.purple900,
  textSecondary: InternalColors.grey600,
  textTertiary: InternalColors.purple600,
  textError: InternalColors.red700,
  textOnActionPrimary: InternalColors.white,
  textOnActionSecondary: InternalColors.white,
  textOnActionTertiary: InternalColors.purple600,
  textOnActionTertiaryPressed: InternalColors.white,
  textOnNavigation: InternalColors.grey100,
  textOnNavigationSecondary: InternalColors.purple200,
  elevationShadowPrimary: InternalColors.colorTransparencyLightest,
  elevationShadowSecondary: InternalColors.colorTransparencyLight,
  iconAccent: InternalColors.emerald500,
  iconError: InternalColors.red600,
  iconBrand: InternalColors.purple600,
  infographicEmerald: InternalColors.emerald400,
  surfaceHighlightSecondary: InternalColors.purple900,
  surfaceHighlightTertiary: InternalColors.emerald100,
  infographicLightBlue: InternalColors.blue100,
  infographicBlue: InternalColors.blue,
  infographicRed: InternalColors.red500,
  progressGradientPrimary: InternalColors.purple900,
  progressGradientSecondary: InternalColors.purple800
} as const

export type PortalColors = (typeof PortalColors)[keyof typeof PortalColors]
