import { EditOutlined } from '@mui/icons-material'
import { Box, Button, Dialog, DialogActions, DialogContent, SxProps } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SitePriceConfigurationItem, SitePricingConfiguration } from '@contracts/types/Site'

import { isSitePricingConfigurationAbsoluteItemArray } from '@pure/libs/SiteHelper'

import { BodyMedium } from '@my-drifter/libs/Typography'

import AbsolutePricingTable from './AbsolutePricingtable'
import { PortalColors } from './PortalColors'
import RelativePricingTable from './RelativePricingTable'
import Title from './Title'

interface PricingConfigurationPanelProps {
  name: React.ReactNode
  pricing: SitePricingConfiguration
  sx?: SxProps
  onSave: (segment: SitePricingConfiguration) => void | Promise<void>
}

const itemWithVAT = (item: SitePriceConfigurationItem) => ({
  ...item,
  perHourPrice: Number((item.perHourPrice * 1.25).toFixed(2))
})

export default function PricingConfigurationPanel({ name, pricing, sx = [], onSave }: PricingConfigurationPanelProps) {
  const [pricingItems, setPricingItems] = useState<SitePriceConfigurationItem[]>(pricing.items ?? [].map(itemWithVAT))
  const [isEditing, setIsEditing] = useState(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    setPricingItems((pricing.items ?? []).map(itemWithVAT))
  }, [pricing])

  async function handleUpdatePricing() {
    setIsLoading(true)

    const updatedPricing = structuredClone(pricing)
    updatedPricing.items = pricingItems.map((item) => ({
      ...item,
      perHourPrice: Math.round((item.perHourPrice / 1.25) * 100) / 100
    }))

    const result = onSave(updatedPricing)

    if (result instanceof Promise) {
      await result
    }

    setIsConfirmModalOpen(false)
    setIsLoading(false)
    setIsEditing(false)
  }

  function handleItemChange(item: SitePriceConfigurationItem, index: number) {
    setPricingItems((prev) => prev.toSpliced(index, 1, item))
  }

  return (
    <>
      <Box sx={[{ width: '100%', overflow: 'hidden' }, ...(Array.isArray(sx) ? sx : [sx])]}>
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            px: 4,
            height: theme.spacing(17),
            borderTopLeftRadius: theme.spacing(4),
            borderTopRightRadius: theme.spacing(4),
            backgroundColor: PortalColors.surfaceHighlightSecondary,
            overflow: 'hidden'
          })}
        >
          <Box
            sx={{
              flex: 1,
              color: PortalColors.textOnHighLightSecondary,
              overflow: 'hidden'
            }}
          >
            {name}
          </Box>
          {!isEditing && (
            <Button
              onClick={() => setIsEditing(true)}
              size="large"
              sx={{
                fontSize: 14,
                fontWeight: 400,
                textTransform: 'none',
                backgroundColor: PortalColors.surfaceActionPrimary,
                color: PortalColors.textWhite,
                '&:hover': {
                  backgroundColor: PortalColors.surfaceActionPrimaryHover
                }
              }}
            >
              <EditOutlined sx={{ mr: 2 }} />
              {t('configurationPricing_buttonupdate')}
            </Button>
          )}
        </Box>
        <Box
          sx={(theme) => ({
            border: `1px solid ${PortalColors.borderDecorativeLow}`,
            borderBottomRightRadius: theme.spacing(4),
            borderBottomLeftRadius: theme.spacing(4),
            backgroundColor: 'white',
            overflow: 'hidden'
          })}
        >
          {isSitePricingConfigurationAbsoluteItemArray(pricingItems) ? (
            <AbsolutePricingTable items={pricingItems} isEditing={isEditing} onChange={handleItemChange} />
          ) : (
            <RelativePricingTable items={pricingItems} isEditing={isEditing} onChange={handleItemChange} />
          )}

          {isEditing && (
            <Box sx={{ p: 4, display: 'flex', gap: 5 }}>
              <Button
                onClick={() => setIsConfirmModalOpen(true)}
                size="large"
                disabled={isLoading}
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  textTransform: 'none',
                  backgroundColor: PortalColors.surfaceActionPrimary,
                  color: PortalColors.textWhite,
                  '&:hover': {
                    backgroundColor: PortalColors.surfaceActionPrimaryHover
                  }
                }}
              >
                {t('configurationPricing_buttonaction')}
              </Button>
              <Button
                onClick={() => setIsEditing(false)}
                size="large"
                variant="text"
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  textTransform: 'none',
                  color: PortalColors.surfaceActionPrimary
                }}
              >
                {t('configurationPricing_buttoncancel')}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <Dialog
        open={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ fontFamily: 'Poppins', '.MuiDialog-paper': { borderRadius: 4 } }}
      >
        <Box sx={{ p: 4, borderBottom: '1px solid', borderColor: PortalColors.borderDecorativeLow }}>
          <Title>{t('configurationPricing_policymodalheader')}</Title>
        </Box>

        <Box
          sx={{ ...BodyMedium, p: 4, color: PortalColors.textPrimary, backgroundColor: PortalColors.surfaceWarning }}
        >
          {t('configurationPricing_changesimmediateeffect')}
        </Box>
        <DialogContent
          sx={{
            ...BodyMedium,
            color: PortalColors.textPrimary,
            borderTop: '1px solid',
            borderBottom: '1px solid',
            borderColor: PortalColors.borderDecorativeLow,
            backgroundColor: PortalColors.surfaceLevelSecond
          }}
        >
          <p>
            <p>{t('configurationPricing_parkingPolicy.control')}</p>{' '}
          </p>
          <Box component="p" fontWeight={600}>
            {t('configurationPricing_parkingPolicy.platform')}
          </Box>
          <Box component="p" sx={{ fontWeight: 600 }}>
            {t('configurationPricing_parkingPolicy.acknowledgement')}
          </Box>
          <Box component="ul" sx={{ pl: 4, display: 'flex', flexDirection: 'column', gap: 1 }}>
            <li>{t('configurationPricing_parkingPolicy.responsibility')}</li>
            <li>{t('configurationPricing_parkingPolicy.disputes')}</li>
            <li>{t('configurationPricing_parkingPolicy.liability')}</li>
          </Box>
        </DialogContent>
        <Box sx={{ p: 6, ...BodyMedium, display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Box>{t('configurationPricing_policyagreementterm')}</Box>
          <DialogActions sx={{ justifyContent: 'flex-start', p: 0 }}>
            <Button
              onClick={handleUpdatePricing}
              size="large"
              disabled={isLoading}
              sx={{
                fontSize: 14,
                borderRadius: 2,
                fontWeight: 400,
                textTransform: 'none',
                backgroundColor: PortalColors.surfaceActionPrimary,
                color: PortalColors.textWhite,
                '&:hover': {
                  backgroundColor: PortalColors.surfaceActionPrimaryHover
                }
              }}
            >
              {t('configurationPricing_buttonpolicyagree')}
            </Button>
            <Button
              onClick={() => setIsConfirmModalOpen(false)}
              size="large"
              variant="text"
              sx={{
                fontSize: 14,
                fontWeight: 400,
                textTransform: 'none',
                color: PortalColors.surfaceActionPrimary
              }}
            >
              {t('configurationPricing_buttoncancel')}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  )
}
