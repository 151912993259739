import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import ErrorIcon from '@mui/icons-material/Error'
import { Alert, Box, Button, Snackbar, SnackbarCloseReason, TextField } from '@mui/material'
import { captureException } from '@sentry/react'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import * as yup from 'yup'

import { UserPermitConnection, UserPermitConnectionSchemaStatus } from '@contracts/types/Permit'

import { formatPayerAlias, isValidNumber } from '@pure/libs/PhoneNumberHelper'

import TextsExtended from '@my-drifter/assets/texts_extended.json'
import { getOrCreateUserSecure } from '@my-drifter/libs/CloudFunctionsApiHandler'
import { upsertPermit } from '@my-drifter/libs/DBApiHandler'
import { getFigmaText } from '@my-drifter/libs/TextRepository'
import Texts from '@my-drifter/libs/Texts'
import { BodySmallStrong, LabelLargeRegular, TitleLarge } from '@my-drifter/libs/Typography'

import { Spacings } from '@web-components/enums/Spacings'

import { AddPermitConnectionDrawerProps } from './AddPermitConnectionDrawer'
import { LAYOUT_HEADER_HEIGHT } from './HardcodedSizes'
import Loader from './Loader'
import { PortalColors } from './PortalColors'
import { PortalRadiuses } from './PortalRadiuses'
import { PortalSpacings } from './PortalSpacings'

const DEFAULT_FORM_DATA = {
  name: '',
  phone: '',
  email: ''
}

export default function AddNewUserForm({
  permit,
  selectedSiteId,
  selectedSitePermitDebtorId,
  isDrawerOpen,
  onCloseDrawer
}: AddPermitConnectionDrawerProps) {
  const [isUserAlreadyAdded, setIsUserAlreadyAdded] = useState(false)
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)

  const schema = yup.object().shape({
    name: yup.string().required('Name is required'),
    phone: yup
      .string()
      .required('Phone is required')
      .test('is-valid-phone', 'Invalid phone number', (value) => isValidNumber(value)),
    email: yup.string().optional()
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_FORM_DATA
  })

  const onSubmit = async (data: { name?: string; phone: string; email?: string }) => {
    if (!permit || !selectedSiteId || !selectedSitePermitDebtorId) {
      return
    }

    const formattedPhone = formatPayerAlias(data.phone)

    const user = await getOrCreateUserSecure({ phone: `+${formattedPhone}`, name: data.name, email: data.email })

    if (!user) return

    const userAlreadyExists = Boolean(
      permit.userPermitConnections?.some((connection) => String(connection.userId) === String(user.id))
    )

    if (userAlreadyExists) {
      setIsSnackbarOpen(true)
      setIsUserAlreadyAdded(true)
      return
    }

    const updatedPermit = {
      ...permit,
      userPermitConnections: [
        ...(permit.userPermitConnections as UserPermitConnection[]),
        {
          acceptedAt: dayjs().format(),
          createdAt: dayjs().format(),
          status: UserPermitConnectionSchemaStatus.ACTIVE,
          updatedAt: dayjs().format(),
          updatedBy: 'MyDrifter',
          userId: user?.id?.toString()
        } as UserPermitConnection
      ] as UserPermitConnection[]
    }
    try {
      await upsertPermit(selectedSiteId, selectedSitePermitDebtorId, permit.id, updatedPermit)
    } catch (e) {
      captureException(e)
      setIsSnackbarOpen(true)
      console.log(e)
    }

    onCloseDrawer()
  }

  function handleSnackbarClose(event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) {
    if (reason === 'clickaway') {
      return
    }
    setIsSnackbarOpen(false)
  }

  useEffect(() => {
    if (!isDrawerOpen) {
      reset({ name: '', phone: '', email: '' })
      setIsUserAlreadyAdded(false)
    }
  }, [isDrawerOpen])

  return (
    <Box sx={{ display: 'flex', flex: 1 }}>
      {isSubmitting ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
          <Loader $loaderColor={PortalColors.surfaceActionSecondary} />
          <StyledLoaderText>{getFigmaText(TextsExtended.permitsSetupNewuserloader)}</StyledLoaderText>
        </Box>
      ) : (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <StyledFormLabel htmlFor="name">{getFigmaText(TextsExtended.permitsInputFieldsfullnames)}</StyledFormLabel>
            <Controller
              control={control}
              name="name"
              render={({ field, fieldState }) => (
                <TextField
                  id="name"
                  {...field}
                  value={field.value}
                  fullWidth
                  size="small"
                  placeholder="John Doe"
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                  sx={textFieldStyle}
                />
              )}
            />
          </Box>
          <Box>
            <StyledFormLabel htmlFor="phone">{getFigmaText(TextsExtended.permitsInputFieldsnumber)}</StyledFormLabel>
            <Controller
              control={control}
              name="phone"
              render={({ field, fieldState }) => (
                <TextField
                  id="phone"
                  {...field}
                  value={field.value}
                  fullWidth
                  type="tel"
                  size="small"
                  placeholder="+46 00 000 0000"
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                  sx={textFieldStyle}
                />
              )}
            />
          </Box>
          <Box>
            <StyledFormLabel htmlFor="email">
              {getFigmaText(TextsExtended.permitsInputfieldsnotrequiredemail)}
            </StyledFormLabel>
            <Controller
              control={control}
              name="email"
              render={({ field, fieldState }) => (
                <TextField
                  id="email"
                  type="email"
                  {...field}
                  value={field.value}
                  fullWidth
                  size="small"
                  placeholder="name@company.se"
                  sx={textFieldStyle}
                />
              )}
            />
          </Box>

          <Box
            sx={{
              marginTop: 'auto',
              marginBottom: PortalSpacings.x8,
              display: 'flex',
              flexDirection: 'row',
              padding: PortalSpacings.x4,
              gap: PortalSpacings.x4
            }}
          >
            <Button
              onClick={onCloseDrawer}
              sx={{
                color: PortalColors.textOnActionTertiary,
                textTransform: 'none',
                ...LabelLargeRegular,
                padding: PortalSpacings.x2,
                borderRadius: PortalRadiuses.sm,
                flex: 1,
                '&:hover': {
                  backgroundColor: PortalColors.surfaceActionTertiaryHover
                }
              }}
            >
              {getFigmaText(TextsExtended.permitsButtonscancel)}
            </Button>
            <Button
              type="submit"
              sx={{
                backgroundColor: PortalColors.surfaceActionSecondary,
                color: PortalColors.textOnActionSecondary,
                textTransform: 'none',
                ...LabelLargeRegular,
                padding: PortalSpacings.x2,
                borderRadius: PortalRadiuses.sm,
                flex: 1,
                '&:hover': {
                  backgroundColor: PortalColors.surfaceActionSecondaryHover
                }
              }}
            >
              {getFigmaText(TextsExtended.permitsButtonsassigntopermit)}
            </Button>
          </Box>
        </StyledForm>
      )}

      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          variant="filled"
          icon={<ErrorIcon />}
          sx={{
            width: '100%',
            marginTop: LAYOUT_HEADER_HEIGHT,
            backgroundColor: PortalColors.surfaceError,
            color: PortalColors.textPrimary,
            border: `1px solid ${PortalColors.borderError}`,
            '& .MuiAlert-icon': {
              color: PortalColors.iconError
            }
          }}
        >
          {isUserAlreadyAdded
            ? getFigmaText(Texts.textMyPortalErrorUserAlreadyAdded)
            : getFigmaText(TextsExtended.permitsInputfieldsErrorsystem)}
        </Alert>
      </Snackbar>
    </Box>
  )
}

const StyledForm = styled.form`
  display: flex;
  gap: ${Spacings.s};
  flex-direction: column;
  flex: 1;
`

const StyledFormLabel = styled.label({
  color: PortalColors.textPrimary,
  margin: 0,
  ...BodySmallStrong,
  display: 'block',
  marginBottom: PortalSpacings.x2
})

const StyledLoaderText = styled.p({
  color: PortalColors.textPrimary,
  ...TitleLarge
})

const textFieldStyle = {
  '& .MuiOutlinedInput-root': {
    backgroundColor: PortalColors.surfaceLevelFirst, // default background
    '& fieldset': {
      borderColor: PortalColors.borderAccessibleHigh // empty border color
    },
    // When empty and hovered
    '&:hover input:placeholder-shown': {
      backgroundColor: PortalColors.surfaceLevelSecond
    },
    // When focused (whether empty or not)
    '&.Mui-focused fieldset': {
      borderColor: PortalColors.borderStrong
    },
    // When typing (input not empty)
    '& input:not(:placeholder-shown) + fieldset': {
      borderColor: PortalColors.borderBrand
    }
  }
}
