import styled from '@emotion/styled'
import CheckIcon from '@mui/icons-material/Check'
import FilterListIcon from '@mui/icons-material/FilterList'
import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuItem,
  TextField
} from '@mui/material'
import { useEffect, useState } from 'react'

import { DebtorType } from '@contracts/types/Debtor'
import { PermitDebtor } from '@contracts/types/PermitDebtor'

import TextsExtended from '@my-drifter/assets/texts_extended.json'
import Images from '@my-drifter/libs/Images'
import { getFigmaText } from '@my-drifter/libs/TextRepository'
import { LabelLargeRegular, TitleMediumRegular } from '@my-drifter/libs/Typography'

import FigmaImage from './FigmaImage'
import { PortalColors } from './PortalColors'
import { PortalRadiuses } from './PortalRadiuses'
import { PortalSpacings } from './PortalSpacings'

interface DebtorPanelProps {
  permitDebtors: PermitDebtor[]
  selectedDebtorId?: string
  onSelectDebtor: (id: string) => void
  onAddDebtor: () => void
}

function DebtorIcon({ debtor }: { debtor: PermitDebtor }) {
  if (debtor.type === DebtorType.Company) {
    return <FigmaImage imageKey={Images.sourceEnvironment} />
  }
  if (debtor.type === DebtorType.Person) {
    return <FigmaImage imageKey={Images.person} />
  }

  return <FigmaImage imageKey={Images.folder} />
}

const ALL_FILTER_OPTION = 'all'

function DebtorPanel({ permitDebtors, selectedDebtorId, onSelectDebtor, onAddDebtor }: DebtorPanelProps) {
  const [searchTerm, setSearchTerm] = useState('')
  const [filterOption, setFilterOption] = useState(ALL_FILTER_OPTION)
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    setSearchTerm('')
    setFilterOption(ALL_FILTER_OPTION)
  }, [permitDebtors])

  const open = Boolean(anchorEl)

  const handleFilterButtonClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleFilterSelect = (option) => {
    setFilterOption(option)
    handleMenuClose()
  }

  const filteredDebtors =
    filterOption === ALL_FILTER_OPTION ? permitDebtors : permitDebtors.filter((item) => item.type === filterOption)

  const searchedDebtors = filteredDebtors.filter((debtor) =>
    debtor.name?.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const displayedDebtors = searchedDebtors.length > 0 ? searchedDebtors : permitDebtors
  const sortedDebtors = displayedDebtors.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))

  const hasOrgs = permitDebtors.filter((debtor) => debtor.type === DebtorType.Company).length > 0
  const hasPersons = permitDebtors.filter((debtor) => debtor.type === DebtorType.Person).length > 0
  const hasGroups = permitDebtors.filter((debtor) => debtor.type === DebtorType.Group).length > 0

  return (
    <Box
      sx={{
        width: '240px',
        borderRight: `1px solid ${PortalColors.borderDecorativeLow}`,
        minHeight: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          borderBottom: `1px solid ${PortalColors.borderDecorativeLow}`
        }}
      >
        <TextField
          size="small"
          variant="outlined"
          placeholder={getFigmaText(TextsExtended.permitsInputFieldssearchDebtors)}
          value={searchTerm}
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none'
            }
          }}
          onChange={(e) => setSearchTerm(e.target.value)}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: PortalColors.iconPrimary }} />
              </InputAdornment>
            )
          }}
        />

        <IconButton
          onClick={handleFilterButtonClick}
          sx={{
            borderLeft: `1px solid ${PortalColors.borderDecorativeLow}`,
            borderRadius: `0 ${PortalRadiuses.xs}  ${PortalRadiuses.xs} 0`,
            color: PortalColors.iconPrimary
          }}
        >
          <FilterListIcon />
        </IconButton>
      </Box>
      <Box sx={{ padding: PortalSpacings.x1, display: 'flex', justifyContent: 'center' }}>
        <Button
          fullWidth
          variant="contained"
          onClick={() => onAddDebtor()}
          sx={{
            backgroundColor: PortalColors.surfaceActionPrimary,
            color: 'white',
            textTransform: 'none',
            ...LabelLargeRegular,
            padding: `${PortalSpacings.x3} ${PortalSpacings.x4}`,
            borderRadius: PortalRadiuses.sm,
            '&:hover': {
              backgroundColor: PortalColors.surfaceActionPrimaryHover
            },
            '&:focus': {
              backgroundColor: PortalColors.surfaceActionPrimaryPressed
            }
          }}
        >
          {getFigmaText(TextsExtended.permitsButtonsadddebtor)}
        </Button>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem onClick={() => handleFilterSelect(ALL_FILTER_OPTION)}>
          {filterOption === ALL_FILTER_OPTION && <CheckIcon sx={{ fontSize: '16px', mr: 1 }} />}
          {getFigmaText(TextsExtended.permitsButtonsalldebtors)}
        </MenuItem>
        {hasPersons && (
          <MenuItem onClick={() => handleFilterSelect(DebtorType.Person)}>
            {filterOption === DebtorType.Person && <CheckIcon sx={{ fontSize: '16px', mr: 1 }} />}
            {getFigmaText(TextsExtended.permitsButtonsindividuals)}
          </MenuItem>
        )}
        {hasOrgs && (
          <MenuItem onClick={() => handleFilterSelect(DebtorType.Company)}>
            {filterOption === DebtorType.Company && <CheckIcon sx={{ fontSize: '16px', mr: 1 }} />}
            {getFigmaText(TextsExtended.permitsButtonsorganisations)}
          </MenuItem>
        )}
        {hasGroups && (
          <MenuItem onClick={() => handleFilterSelect(DebtorType.Group)}>
            {filterOption === DebtorType.Group && <CheckIcon sx={{ fontSize: '16px', mr: 1 }} />}
            {getFigmaText(TextsExtended.permitsButtonsgroups)}
          </MenuItem>
        )}
      </Menu>

      <List sx={{ paddingTop: 0, overflowY: 'auto', flex: 1, minHeight: 0 }}>
        {sortedDebtors.map((debtor) => (
          <ListItemButton
            selected={selectedDebtorId === debtor.id}
            onClick={(e) => onSelectDebtor(debtor.id)}
            key={debtor.id}
            sx={{
              padding: '0 10px 0 10px',
              '&.Mui-selected': {
                backgroundColor: PortalColors.surfaceLevelThird
              },
              '&:hover': {
                backgroundColor: PortalColors.surfaceLevelSecond
              },
              '&:focus-visible': {
                border: `2px solid ${PortalColors.borderStrong}`,
                backgroundColor: PortalColors.surfaceLevelFirst
              }
            }}
          >
            <ListItemIcon sx={{ minWidth: 'auto', marginRight: '10px' }}>
              <DebtorIcon debtor={debtor} />
            </ListItemIcon>
            <StyledDebtorName>{debtor.name}</StyledDebtorName>
          </ListItemButton>
        ))}
      </List>
    </Box>
  )
}

const StyledDebtorName = styled.p({
  color: PortalColors.textPrimary,
  ...TitleMediumRegular,
  textOverflow: 'ellipsis'
})

export default DebtorPanel
