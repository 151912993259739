import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Box, Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useEffect, useState } from 'react'
import { useControls } from 'react-zoom-pan-pinch'

import Images from '@my-drifter/libs/Images'

import FigmaImage from './FigmaImage'
import { LAYOUT_LEFT_PADDING, LAYOUT_LEFT_XL_PADDING } from './HardcodedSizes'
import { PortalColors } from './PortalColors'
import { PortalSpacings } from './PortalSpacings'

const centeredIcon = {
  background: PortalColors.textSelectedWhite,
  width: '48px',
  border: 'none',
  justifyContent: 'center',
  '&:hover': {
    background: PortalColors.buttonHovered,
    border: 'none'
  },
  '& .MuiButton-startIcon': {
    margin: 0
  }
}

const strokeIcon = {
  ...centeredIcon,
  borderTop: 'none',
  borderBottom: 'none',
  borderLeft: '1px #D3DEED solid',
  '&:hover': {
    backgroundColor: PortalColors.buttonHovered,
    borderTop: 'none',
    borderRight: 'none',
    borderBottom: 'none',
    borderLeft: '1px #D3DEED solid'
  }
}

interface ZoomControlsProps {
  selectedSiteId?: string
}

export default function ZoomControls({ selectedSiteId }: ZoomControlsProps) {
  const { setTransform, zoomIn, zoomOut, resetTransform } = useControls()
  const [scale, setScale] = useState(1)
  const onScaleChange = (newScale: number) => {
    setScale(newScale)
    setTransform(0, 0, newScale)
  }

  // reset zoom when selectedSiteId changes
  useEffect(() => {
    setScale(1)
    setTransform(0, 0, 1)
  }, [selectedSiteId])

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        position: 'fixed',
        bottom: PortalSpacings.x6,
        left: LAYOUT_LEFT_PADDING,
        gap: PortalSpacings.x2,
        padding: `0 ${PortalSpacings.x6} 0 ${PortalSpacings.x6}`,
        [theme.breakpoints.up('xl')]: {
          left: LAYOUT_LEFT_XL_PADDING
        }
      })}
    >
      <FormControl
        size="small"
        sx={{
          border: 'none',
          '& .MuiInputLabel-root': {
            border: 'none' // Remove label border
          }
        }}
      >
        <InputLabel sx={{ border: 'none' }} id="demo-simple-select-label"></InputLabel>
        <Select
          sx={{
            background: PortalColors.textSelectedWhite,
            paddingLeft: '8px',
            paddingRight: '0px',
            border: 'none',
            '& .MuiSelect-select': {
              paddingRight: '0px !important'
            },
            '&:hover': {
              backgroundColor: PortalColors.buttonHovered,
              border: 'none'
            },
            '&.Mui-focused': {
              outline: 'none',
              border: 'none'
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none'
            },
            boxShadow: `0px 1px 3px 0px ${PortalColors.elevationShadowPrimary}, 0px 2px 4px 0px ${PortalColors.elevationShadowSecondary}`
          }}
          IconComponent={() => (
            <FigmaImage
              imageKey={Images.statMinus}
              imageStyle={{ width: '48px', height: '48px', paddingRight: PortalSpacings.x2 }}
            />
          )}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={scale}
          label="Age"
          onChange={(e) => onScaleChange(parseFloat(e.target.value as string))}
        >
          <MenuItem value={0.5}>50%</MenuItem>
          <MenuItem value={0.8}>80%</MenuItem>
          <MenuItem value={1}>100%</MenuItem>
          <MenuItem value={1.5}>150%</MenuItem>
          <MenuItem value={2}>200%</MenuItem>
        </Select>
      </FormControl>
      <ButtonGroup
        sx={{
          background: PortalColors.textSelectedWhite,
          boxShadow: `0px 1px 3px 0px ${PortalColors.elevationShadowPrimary}, 0px 2px 4px 0px ${PortalColors.elevationShadowSecondary}`
        }}
      >
        <Button size="small" sx={centeredIcon} startIcon={<AddIcon />} onClick={() => zoomIn()} />
        <Button size="small" sx={strokeIcon} startIcon={<RemoveIcon />} onClick={() => zoomOut()} />
      </ButtonGroup>
      <Button
        size="small"
        sx={centeredIcon}
        style={{
          minWidth: '24px',
          padding: PortalSpacings.x2,
          boxShadow: `0px 1px 3px 0px ${PortalColors.elevationShadowPrimary}, 0px 2px 4px 0px ${PortalColors.elevationShadowSecondary}`
        }}
        startIcon={<FigmaImage imageKey={Images.mapCollapse} />}
        onClick={() => resetTransform()}
      />
    </Box>
  )
}
