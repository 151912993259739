import { Tabs } from '@base-ui-components/react'
import styled from '@emotion/styled'
import { Box, MenuItem, Select } from '@mui/material'
import { doc, updateDoc } from 'firebase/firestore'
import { memo, PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BorderRadiusesPx } from '@contracts/types/BorderRadixes'
import { HikerUserRole } from '@contracts/types/HikerUser'
import { OffenseType } from '@contracts/types/OffenseType'
import { Site, SitePricingConfiguration } from '@contracts/types/Site'

import Layout from '@my-drifter/components/Layout'
import { PortalColors } from '@my-drifter/components/PortalColors'
import { PortalSpacings } from '@my-drifter/components/PortalSpacings'
import PricingConfigurationPanel from '@my-drifter/components/PricingConfigurationPanel'
import Title from '@my-drifter/components/Title'
import useAppState from '@my-drifter/hooks/useAppState'
import { useSelectedSiteContext } from '@my-drifter/hooks/useSelectedSiteContext'
import { useSite } from '@my-drifter/hooks/useSite'
import { useUser } from '@my-drifter/hooks/useUser'
import { db } from '@my-drifter/libs/FirebaseOptions'
import { BodyMedium } from '@my-drifter/libs/Typography'

import NotAuthorizedScreen from './NotAuthorizedScreen'

interface TabProps {
  site: Site
}

const TabHeader = ({ title, description, children }: PropsWithChildren<{ title: string; description: string }>) => {
  return (
    <Box
      sx={{
        p: 4,
        pb: 8,
        backgroundColor: PortalColors.surfaceWhite,
        display: 'flex',
        flexDirection: 'column',
        gap: 4
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Title weight="strong">{title}</Title>
        <Box sx={{ ...BodyMedium, color: PortalColors.textSecondary }}>{description}</Box>
      </Box>
      {children}
    </Box>
  )
}

const PricingTab = ({ site }: TabProps) => {
  const { segments = [] } = site
  const { t } = useTranslation()
  function handleUpdatePricing(parking: SitePricingConfiguration, index: number) {
    const updatedSegments = segments.map((s, i) => (i === index ? { ...s, pricing: { ...s.pricing, parking } } : s))

    return updateDoc(doc(db, 'sites', site.id), { segments: updatedSegments })
  }

  const segmentsWithPricing = segments.filter((segment) => segment?.pricing?.parking)

  return (
    <Box>
      <TabHeader
        title={t('configurationPricing_headerparking')}
        description={t('configurationPricing_descriptionparking')}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', mt: -3, gap: 6 }}>
        {segmentsWithPricing.map((segment, index) => (
          <PricingConfigurationPanel
            name={
              <span>
                {t('configurationPricing_parkingzone')} <strong style={{ fontWeight: 600 }}>{segment.name}</strong>
              </span>
            }
            pricing={segment.pricing.parking}
            key={segment.id ?? segment.name}
            onSave={(pricing) => handleUpdatePricing(pricing, index)}
          />
        ))}
      </Box>
    </Box>
  )
}

const OffensesTab = ({ site }: TabProps) => {
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState<number>(0)

  const { segments = [] } = site
  const { t } = useTranslation()

  const offenseTypeLabel: Record<OffenseType, string> = {
    [OffenseType.ChargeSlotOffense]: t('configurationPricing_offenceevcharging'),
    [OffenseType.MarkedSlot]: t('configurationPricing_offencemarkedslot'),
    [OffenseType.DisabledSlot]: t('configurationPricing_offencedisabledparking'),
    [OffenseType.QuickstopOverstay]: t('configurationPricing_offenceoverstaycharge'),
    [OffenseType.VehiclePosition]: 'Felaktig fordonplacering'
  }

  const offenses = (segments[selectedSegmentIndex]?.pricing?.offense ?? []).filter(
    (o): o is SitePricingConfiguration & { offenseType: OffenseType } => Boolean(o.offenseType)
  )

  function handleUpdatePricing(pricing: SitePricingConfiguration, index: number) {
    const updatedOffenses = offenses.map((o, i) => (i === index ? pricing : o))

    const updatedSegments = segments.map((s, i) =>
      i === index ? { ...s, pricing: { ...s.pricing, offense: updatedOffenses } } : s
    )

    return updateDoc(doc(db, 'sites', site.id), { segments: updatedSegments })
  }

  return (
    <Box>
      <TabHeader
        title={t('configurationPricing_headeroffences')}
        description={t('configurationPricing_descriptionoffences')}
      >
        {segments.length > 1 && (
          <Select
            value={selectedSegmentIndex}
            onChange={(e) => setSelectedSegmentIndex(Number(e.target.value))}
            sx={{ alignSelf: 'flex-start', width: 200 }}
          >
            {segments.map((segment, index) => (
              <MenuItem key={`${segment.id ?? segment.name}`} value={index}>
                {segment.name}
              </MenuItem>
            ))}
          </Select>
        )}
      </TabHeader>
      <Box sx={{ display: 'flex', flexDirection: 'column', mt: -3, gap: 6 }}>
        {offenses.map((offensePricing, index) => (
          <PricingConfigurationPanel
            key={offensePricing.offenseType}
            name={
              <span>
                {t('configurationPricing_offence')}{' '}
                <strong style={{ fontWeight: 600 }}>{offenseTypeLabel[offensePricing.offenseType]}</strong>
              </span>
            }
            pricing={offensePricing}
            onSave={(pricing) => handleUpdatePricing(pricing, index)}
          />
        ))}
      </Box>
    </Box>
  )
}

const SiteConfigurationScreen = () => {
  const { state } = useAppState()
  const { data: user, isLoading: isLoadingUser } = useUser(localStorage.getItem('userId') || state?.userId)
  const { t } = useTranslation()
  const isAllowed =
    !isLoadingUser &&
    user?.role &&
    [HikerUserRole.ADMIN, HikerUserRole.ROOT].includes(user.role) &&
    user?.siteIds &&
    user?.siteIds?.length > 0

  const { selectedSiteId, setSelectedSiteId } = useSelectedSiteContext()
  const { data: site } = useSite(selectedSiteId)

  const hasParkingFees = site?.segments?.some((segment) => (segment.pricing?.parking?.items ?? []).length > 0)
  const hasOffenseFees = site?.segments?.some((segment) => (segment.pricing?.offense ?? []).length > 0)

  if (!isAllowed && !isLoadingUser) return <NotAuthorizedScreen />

  return (
    <Layout
      selectedSiteId={selectedSiteId}
      setSelectedSiteId={(id) => {
        setSelectedSiteId(id)
      }}
      isLoading={isLoadingUser}
    >
      <TabsRoot defaultValue={hasParkingFees ? 'parking' : 'offenses'}>
        <TabsList>
          {hasParkingFees && <TabsTab value="parking">{t('configurationPricing_tabparking')}</TabsTab>}
          {hasOffenseFees && <TabsTab value="offenses">{t('configurationPricing_taboffences')}</TabsTab>}
          <TabsIndicator />
        </TabsList>
        {hasParkingFees && <Tabs.Panel value="parking">{site && <PricingTab site={site} />}</Tabs.Panel>}
        {hasOffenseFees && <Tabs.Panel value="offenses">{site && <OffensesTab site={site} />}</Tabs.Panel>}
      </TabsRoot>
    </Layout>
  )
}

export default memo(SiteConfigurationScreen)

const TabsRoot = styled(Tabs.Root)`
  --tab-height: ${PortalSpacings.x10};
  font-family: Poppins;
  max-width: 1000px;
`

const TabsList = styled(Tabs.List)`
  display: flex;
  position: relative;
  z-index: 0;
  gap: ${PortalSpacings.x1};
  background-color: ${PortalColors.surfaceWhite};
  align-items: center;
  padding: ${PortalSpacings.x1};
  border-top-left-radius: ${BorderRadiusesPx.minimum};
  border-top-right-radius: ${BorderRadiusesPx.minimum};
`

const TabsTab = styled(Tabs.Tab)`
  all: unset;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  user-select: none;
  height: var(--tab-height);
  padding-inline: ${PortalSpacings.x6};

  color: ${PortalColors.textPrimary};
  border-radius: ${BorderRadiusesPx.minimum};

  &[data-selected] {
    color: ${PortalColors.surfaceWhite};
  }

  @media (hover: hover) {
    &:hover {
      background-color: hsl(from ${PortalColors.surfaceHighlightSecondary} h s l / 10%);
    }
  }

  transition-property: background-color color;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
`

const TabsIndicator = styled(Tabs.Indicator)`
  position: absolute;
  z-index: -1;
  left: 0;
  translate: var(--active-tab-left);
  width: var(--active-tab-width);
  height: var(--tab-height);
  border-radius: ${BorderRadiusesPx.minimum};
  background-color: ${PortalColors.surfaceHighlightSecondary};

  transition-property: translate, width;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
`
