import { Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { deleteDoc, doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore'
import type React from 'react'
import { useId, useState } from 'react'

import { Camera } from '@contracts/types/Camera'
import { Site } from '@contracts/types/Site'

import { db } from '@my-drifter/libs/FirebaseOptions'

interface CameraFormProps {
  initialData?: Camera
  site: Site
  onSave: (camera: Camera) => void
  onCancel?: () => void
}

const DEFAULT_CAMERA_DATA = {
  sensorTypes: ['lmt.tmp'],
  lastUpdate: serverTimestamp()
}

export default function CameraForm({ initialData, site, onSave, onCancel }: CameraFormProps) {
  const [formData, setFormData] = useState<{
    id: string
    type: string
  }>({
    id: initialData?.id ?? '',
    type: initialData?.type ?? 'slot'
  })
  const [saving, setSaving] = useState(false)

  const deviceTypeLabelId = useId()

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    setSaving(true)

    const cameraData: Omit<Camera, 'id'> = {
      ...DEFAULT_CAMERA_DATA,
      type: formData.type,
      siteId: site?.id,
      location: site?.address ?? site?.name
    }

    try {
      if (initialData) {
        let deletePromise = Promise.resolve()

        if (initialData.id !== formData.id) {
          deletePromise = deleteDoc(doc(db, 'cameras', initialData.id))
        }

        // Delete the old camera and set the new one at the same time so that the interface doesn't flicker
        await Promise.all([deletePromise, setDoc(doc(db, 'cameras', formData.id), cameraData, { merge: true })])
      } else {
        const existingCamera = await getDoc(doc(db, 'cameras', formData.id))

        if (existingCamera.exists()) {
          alert('A camera with this ID already exists. Please enter a different ID.')
        }
        if (!formData.id) {
          alert('Please enter a device ID.')
        }

        await setDoc(doc(db, 'cameras', formData.id), cameraData)
      }

      setFormData({
        ...formData,
        id: ''
      })
      onSave({
        id: formData.id,
        ...cameraData
      })
    } catch (error) {
      console.error('Error saving camera:', error)
      alert('Failed to save camera. Please try again.')
    } finally {
      setSaving(false)
    }
  }

  return (
    <Box>
      <h3 style={{ margin: 0 }}>{initialData ? 'Edit Camera' : 'Add New Camera'}</h3>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ display: 'flex', flexDirection: 'column', gap: 4, mt: 4 }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <TextField
            id="type"
            value={formData.id}
            required
            label="Device ID"
            onChange={(e) =>
              setFormData({
                ...formData,
                id: e.target.value
              })
            }
            InputLabelProps={{
              shrink: true
            }}
            placeholder="123"
            fullWidth
          />
          <FormControl fullWidth required>
            <InputLabel id={deviceTypeLabelId}>Camera Type</InputLabel>
            <Select
              labelId={deviceTypeLabelId}
              id="type"
              defaultValue="slot"
              value={formData.type}
              label="Camera Type"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  type: e.target.value
                })
              }}
            >
              <MenuItem value="slot">Slot</MenuItem>
              <MenuItem value="gate">Gate</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          {onCancel && (
            <Button variant="outlined" onClick={onCancel} disabled={saving}>
              Cancel
            </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={saving}
            startIcon={saving ? <CircularProgress size={20} /> : null}
          >
            {saving ? 'Saving...' : 'Save Camera'}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
