import { PermitValidityPeriod } from '@contracts/types/PermitTemplate'

import { arrayEquals } from '@pure/libs/Common'

export const VALIDITY_ALL_WEEK = [1, 2, 3, 4, 5, 6, 7]
export const VALIDITY_WEEKDAYS = [1, 2, 3, 4, 5]
export const VALIDITY_WEEKEND = [6, 7]

export const DaysValidityOptions = {
  All: 'all',
  Weekdays: 'week',
  Weekend: 'weekend',
  Custom: 'custom'
} as const

export type DaysValidityOptions = (typeof DaysValidityOptions)[keyof typeof DaysValidityOptions]

export const TimeValidityOptions = {
  AllDay: 'allday',
  Custom: 'custom'
} as const

export type TimeValidityOptions = (typeof TimeValidityOptions)[keyof typeof TimeValidityOptions]

export function generateTimeAndDayOfWeekValidity(startHour: number, endHour: number, days: number[]) {
  // If the period wraps around midnight, create two validity values as our logic doesn't support it
  if (startHour > endHour) {
    return [
      ...(endHour > 0 ? days.map((n) => ({ startHour: 0, endHour, dayOfWeek: n })) : []),
      ...days.map((n) => ({ startHour, endHour: 24, dayOfWeek: n }))
    ]
  }
  return days.map((n) => ({ startHour, endHour, dayOfWeek: n }))
}

export function getTimeValidityOptions(validityPeriods: PermitValidityPeriod[]) {
  if (validityPeriods.length === 0) {
    return TimeValidityOptions.AllDay
  }

  const firstPeriod = validityPeriods[0]

  if (firstPeriod.startHour === 0 && firstPeriod.endHour === 24) {
    return TimeValidityOptions.AllDay
  }

  return TimeValidityOptions.Custom
}

export function getStartAndEndHour(validityPeriods: PermitValidityPeriod[]) {
  if (validityPeriods.length === 0) {
    return { startHour: 0, endHour: 24 }
  }

  const groupedByDays = Object.groupBy(validityPeriods, ({ dayOfWeek }) => dayOfWeek)
  const firstDay = Object.values(groupedByDays)[0]

  if (firstDay && firstDay.length > 1) {
    return { startHour: firstDay[1].startHour, endHour: firstDay[0].endHour }
  }
  return { startHour: validityPeriods[0].startHour, endHour: validityPeriods[0].endHour }
}

export function getCustomDays(validityPeriods: PermitValidityPeriod[]) {
  if (validityPeriods.length === 0) {
    return [1]
  }
  const days = validityPeriods.map((period) => period.dayOfWeek)
  return [...new Set(days)]
}

export function getDaysValidityOptions(validityPeriods: PermitValidityPeriod[]) {
  if (validityPeriods.length === 0) {
    return DaysValidityOptions.All
  }

  const days = validityPeriods.map((period) => period.dayOfWeek)
  const uniqueDays = [...new Set(days)]

  const isWeekend = uniqueDays.includes(6) && uniqueDays.includes(7) && uniqueDays.length === 2
  if (isWeekend) {
    return DaysValidityOptions.Weekend
  }

  const isAllDaysInWeek = uniqueDays.length === 7
  if (isAllDaysInWeek) {
    return DaysValidityOptions.All
  }

  const isOnlyWeekDays = arrayEquals(VALIDITY_WEEKDAYS, uniqueDays)
  if (isOnlyWeekDays) {
    return DaysValidityOptions.Weekdays
  }

  return DaysValidityOptions.Custom
}
