import styled from '@emotion/styled'
import { memo } from 'react'

import Texts from '@my-drifter/libs/Texts'

import FigmaText from './FigmaText'

type DropdownMenuItemProps = {
  option: {
    label?: string
    value?: string
  }
  handleOptionClick: (value: string) => void
}

const DropdownSelectorMenuItem = ({ option, handleOptionClick }: DropdownMenuItemProps) => {
  return (
    <DropdownOption key={option?.value} onClick={() => handleOptionClick(option?.value ? option?.value : '')}>
      <FigmaText textKey={Texts.textPermitManagementPermitCssUserName} text={option?.label} />
    </DropdownOption>
  )
}

const DropdownOption = styled.li`
  display: flex;
  height: 45px;
  align-items: center;
  cursor: pointer;
  padding: 5px 10px;

  &:hover {
    background-color: #f5f5f5;
  }
`

export default memo(DropdownSelectorMenuItem)
