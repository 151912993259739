import styled from '@emotion/styled'
import { Add, Remove } from '@mui/icons-material'
import CheckIcon from '@mui/icons-material/Check'
import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material'
import { useEffect, useId, useState } from 'react'
import { Control, Controller, UseFormReset, UseFormSetValue, UseFormWatch } from 'react-hook-form'

import { DebtorType } from '@contracts/types/Debtor'
import { UpsertPermitRequest } from '@contracts/types/Permit'
import { PermitDebtor } from '@contracts/types/PermitDebtor'
import { PermitTemplate } from '@contracts/types/PermitTemplate'
import { Site } from '@contracts/types/Site'

import TextsExtended from '@my-drifter/assets/texts_extended.json'
import { getFigmaText } from '@my-drifter/libs/TextRepository'
import {
  BodyMediumEmphasis,
  BodySmallStrong,
  LabelLargeRegular,
  LabelLargeStrong,
  TitleLarge,
  TitleMediumStrong
} from '@my-drifter/libs/Typography'

import { PortalColors } from './PortalColors'
import { PortalRadiuses } from './PortalRadiuses'
import { PortalSpacings } from './PortalSpacings'
import SlotCodeInput from './SlotCodeInput'
import ValidityPeriodForm from './ValidityPeriodForm'

interface PermitFormProps {
  site: Site
  permitDebtor: PermitDebtor
  permitTemplates: PermitTemplate[]
  setValue: UseFormSetValue<PermitFormState>
  watch: UseFormWatch<PermitFormState>
  control: Control<PermitFormState, any>
  reset: UseFormReset<PermitFormState>
}

export type PermitFormState = Omit<UpsertPermitRequest, 'maxNumberOfConcurrentParkedVehicles'> & {
  permitDebtorId: string
  maxNumberOfConcurrentParkedVehicles: number
}

function PermitForm({ site, permitDebtor, permitTemplates, watch, setValue, control, reset }: PermitFormProps) {
  const [selectedTemplate, setSelectedTemplate] = useState(permitTemplates[0])
  const selectedTemplateHasMarkedSlots = (selectedTemplate.includeSlotPlaceCodes?.length ?? 0) > 0
  const [isMarkedSlotPermit, setIsMarkedSlotPermit] = useState(selectedTemplateHasMarkedSlots)

  const slotCodes = site.segments.flatMap((segment) => segment.slots.map((s) => s.placeCode))
  const isPriceDisabled = permitDebtor.type === DebtorType.Group
  const { id: permitDebtorId } = permitDebtor

  useEffect(() => {
    const { id, ...selectedTemplateWithoutId } = selectedTemplate
    reset({
      ...selectedTemplateWithoutId,
      price: isPriceDisabled ? 0 : selectedTemplate.price,
      featureToggleMaxConcurrentPermit: true,
      includeSlotPlaceCodes: [],
      permitDebtorId
    })
  }, [selectedTemplate, reset])

  const maxNumberOfConcurrentParkedVehicles = watch('maxNumberOfConcurrentParkedVehicles') // This property is what controls the number of permits

  const handleTemplateChange = (event) => {
    setSelectedTemplate(permitTemplates.find((template) => template.name === event.target.value) ?? permitTemplates[0])
  }

  const handleSlotPermitToggle = (_event, isMarked: boolean) => {
    setIsMarkedSlotPermit(isMarked)

    if (!isMarked) {
      setValue('includeSlotPlaceCodes', [])
    }
  }

  const templateId = useId()
  const templateLabelId = useId()
  return (
    <Box
      sx={{
        backgroundColor: PortalColors.surfaceLevelFirst,
        margin: `${PortalSpacings.x6}`,
        padding: `${PortalSpacings.x4} ${PortalSpacings.x6} ${PortalSpacings.x6} ${PortalSpacings.x6}`,
        borderRadius: PortalRadiuses.lg
      }}
    >
      <StyledHeadline>{getFigmaText(TextsExtended.permitsSetupNewPermitheader)}</StyledHeadline>

      <FormControl
        fullWidth
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: PortalSpacings.x6
        }}
      >
        <Box sx={{ gridColumn: '1 / span 2' }}>
          <StyledLabel>{getFigmaText(TextsExtended.permitsSetupNewPermitpermitType)}</StyledLabel>
          <ToggleButtonGroup
            value={isMarkedSlotPermit}
            exclusive
            onChange={handleSlotPermitToggle}
            aria-label="toggle slotinput display"
          >
            <ToggleButton value={false} aria-label="floating" sx={toggleButtonStyle}>
              {isMarkedSlotPermit === false && <CheckIcon sx={{ fontSize: '16px', mr: 1 }} />}
              {getFigmaText(TextsExtended.permitsButtonsfloatingPermit)}
            </ToggleButton>
            <ToggleButton value={true} aria-label="marked-spot" sx={toggleButtonStyle}>
              {isMarkedSlotPermit === true && <CheckIcon sx={{ fontSize: '16px', mr: 1 }} />}
              {getFigmaText(TextsExtended.permitsButtonsreservedPermit)}
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <StyledEmphasisText>{getFigmaText(TextsExtended.permitsInputFieldsrequired)}</StyledEmphasisText>

        <Box sx={{ gridColumn: 1 }}>
          <StyledLabel id={templateLabelId}>{getFigmaText(TextsExtended.permitsInputFieldspermit)}</StyledLabel>
          <Select
            labelId={templateLabelId}
            id={templateId}
            value={selectedTemplate.name}
            onChange={handleTemplateChange}
            sx={comboboxFieldStyle}
          >
            {permitTemplates.map((template) => (
              <MenuItem key={template.id} value={template.name}>
                {template.name}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box sx={{ gridColumn: 2, maxWidth: '152px' }}>
          <StyledLabel>{getFigmaText(TextsExtended.permitsInputFieldquantity)}</StyledLabel>

          <Box sx={{ display: 'flex', border: `1px solid ${PortalColors.borderAccessibleHigh}`, borderRadius: '5px' }}>
            <IconButton
              onClick={() =>
                setValue(
                  'maxNumberOfConcurrentParkedVehicles',
                  Math.max(0, Number(maxNumberOfConcurrentParkedVehicles ?? 1) - 1),
                  { shouldValidate: true }
                )
              }
              sx={{ borderRight: `1px solid ${PortalColors.borderAccessibleHigh}`, borderRadius: 0 }}
            >
              <Remove />
            </IconButton>
            <Controller
              name="maxNumberOfConcurrentParkedVehicles"
              control={control}
              rules={{
                required: 'Ange ett antal',
                min: { value: 1, message: getFigmaText(TextsExtended.permitsInputfieldserrorquantitynone) },
                validate: {
                  noDecimals: (value) => Number.isInteger(value) || 'Decimal numbers are not allowed'
                }
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="number"
                  variant="outlined"
                  required
                  error={!!fieldState.error}
                  inputProps={{ min: 1, style: { textAlign: 'center' } }}
                  sx={{
                    // Use conditional styling: if there's an error, set border color to red, otherwise use the default.
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: fieldState.error ? '1px solid red' : 'none'
                    },
                    // Hide the default browser spinner
                    '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button':
                      {
                        '-webkit-appearance': 'none'
                      },
                    // For Firefox
                    '& input[type=number]': {
                      '-moz-appearance': 'textfield'
                    }
                  }}
                />
              )}
            />
            <IconButton
              onClick={() =>
                setValue('maxNumberOfConcurrentParkedVehicles', Number(maxNumberOfConcurrentParkedVehicles ?? 0) + 1, {
                  shouldValidate: true
                })
              }
              sx={{ borderLeft: `1px solid ${PortalColors.borderAccessibleHigh}`, borderRadius: 0 }}
            >
              <Add />
            </IconButton>
          </Box>
          <Controller
            name="maxNumberOfConcurrentParkedVehicles"
            control={control}
            render={({ fieldState }) => (
              <Box
                component="span"
                sx={{
                  // Simulate MUI default styling of error messages
                  color: '#d32f2f',
                  fontSize: '0.75rem',
                  marginTop: '4px',
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
                }}
              >
                {fieldState.error?.message}
              </Box>
            )}
          />
        </Box>

        {isMarkedSlotPermit && (
          <Box sx={{ gridColumn: 1 }}>
            <StyledLabel htmlFor="slotnumbers">{getFigmaText(TextsExtended.permitsInputFieldsslotNumbers)}</StyledLabel>
            <SlotCodeInput
              slots={slotCodes}
              control={control}
              startValue={selectedTemplate.includeSlotPlaceCodes}
              setValue={setValue}
            />
          </Box>
        )}
        {!isPriceDisabled && (
          <Box sx={{ gridColumn: 1 }}>
            <StyledLabel htmlFor="price">{getFigmaText(TextsExtended.permitsSetupNewPermitpricing)}</StyledLabel>
            <Controller
              name="price"
              disabled={isPriceDisabled}
              control={control}
              rules={{
                required: getFigmaText(TextsExtended.permitsInputfieldserrorrequired),
                pattern: { value: /^[0-9]+$/, message: 'Invalid price' }
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  id="price"
                  fullWidth
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  sx={textFieldStyle(!!fieldState.error)}
                />
              )}
            />
          </Box>
        )}
        <Box sx={{ gridColumn: '1 / span 2' }}>
          <StyledFormLabelTitle htmlFor="giltlighetsperiod">
            {getFigmaText(TextsExtended.permitsSetupNewPermitvalidityPeriod)}
          </StyledFormLabelTitle>
          <ValidityPeriodForm
            validityPeriods={selectedTemplate.validityPeriods}
            setValidityPeriods={(validityPeriods) => setValue('validityPeriods', validityPeriods)}
          />
        </Box>
      </FormControl>
    </Box>
  )
}

export default PermitForm

const toggleButtonStyle = {
  backgroundColor: 'white',
  color: PortalColors.textOnActionTertiary,
  ...LabelLargeRegular,
  textTransform: 'none',
  borderRadius: PortalRadiuses.sm,
  width: '170px',
  '&:hover': {
    backgroundColor: PortalColors.surfaceActionTertiaryHover
  },
  '&.Mui-selected': {
    backgroundColor: PortalColors.surfaceActionTertiaryPressed,
    color: PortalColors.textOnActionPrimary,
    ...LabelLargeStrong,
    '&:hover': {
      backgroundColor: PortalColors.surfaceActionPrimaryHover
    }
  }
}

const textFieldStyle = (hasError: boolean) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: PortalColors.surfaceLevelFirst, // default background
    '& fieldset': {
      borderColor: hasError ? 'red' : PortalColors.borderAccessibleHigh // red if error, otherwise default
    },
    // When empty and hovered
    '&:hover input:placeholder-shown': {
      backgroundColor: PortalColors.surfaceLevelSecond
    },
    // When focused (whether empty or not)
    '&.Mui-focused fieldset': {
      borderColor: hasError ? 'red' : PortalColors.borderStrong
    },
    // When typing (input not empty)
    '& input:not(:placeholder-shown) + fieldset': {
      borderColor: hasError ? 'red' : PortalColors.borderBrand
    }
  }
})

const comboboxFieldStyle = {
  width: '100%',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: PortalColors.borderBrand
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: PortalColors.borderStrong // border color when the combobox is focused/selected
  }
}

const StyledEmphasisText = styled.p({
  ...BodyMediumEmphasis,
  color: PortalColors.textPrimary,
  gridColumn: '1 / span 2'
})

const StyledHeadline = styled.h2({
  ...TitleLarge,
  color: PortalColors.textPrimary
})

const StyledLabel = styled.label({
  ...BodySmallStrong,
  display: 'block',
  marginBottom: PortalSpacings.x2,
  color: PortalColors.textPrimary
})

const StyledFormLabelTitle = styled.label({
  ...TitleMediumStrong,
  display: 'block',
  marginBottom: PortalSpacings.x2,
  color: PortalColors.textPrimary
})
