import { Chip } from '@mui/material'

import TextsExtended from '@my-drifter/assets/texts_extended.json'
import { getFigmaText } from '@my-drifter/libs/TextRepository'
import { LabelMediumStrongUppercase } from '@my-drifter/libs/Typography'

import { PortalColors } from './PortalColors'
import { PortalRadiuses } from './PortalRadiuses'

type Status = 'active' | 'terminated' | 'invited' | 'inactive' | 'cancelled' | undefined

interface StatusChipProps {
  status: Status
}

const getChipStyles = (status: Status) => {
  switch (status) {
    case 'active':
      return {
        backgroundColor: PortalColors.surfaceChipGreen,
        color: PortalColors.textOnChipSuccess,
        border: `1px solid ${PortalColors.borderSuccess}`
      }
    case 'invited':
      return {
        backgroundColor: PortalColors.surfaceChipBlue,
        color: PortalColors.textOnChipInformation,
        border: `1px solid ${PortalColors.borderInformation}`
      }
    case 'terminated':
      return {
        backgroundColor: PortalColors.surfaceError,
        color: PortalColors.textError,
        border: `1px solid ${PortalColors.borderError}`
      }
    case 'cancelled':
      return {
        backgroundColor: PortalColors.surfaceWarning,
        color: PortalColors.textWarning,
        border: `1px solid ${PortalColors.borderWarning}`
      }
    case 'inactive':
    default:
      return {
        backgroundColor: PortalColors.surfaceLevelThird,
        color: PortalColors.textPrimary,
        border: `1px solid ${PortalColors.borderAccessibleHigh}`
      }
  }
}

const getChipLabel = (status: Status) => {
  switch (status) {
    case 'active':
      return getFigmaText(TextsExtended.permitsTablestatusactive)

    case 'invited':
      return getFigmaText(TextsExtended.permitsTablestatusinvited)
    case 'cancelled':
      return getFigmaText(TextsExtended.permitsTablestatuscanceled)
    case 'inactive':
      return getFigmaText(TextsExtended.permitsDashboardDebtorsAndPermitsdebtorinactive)

    case 'terminated':
      return getFigmaText(TextsExtended.permitsTablestatusdeactivated)
  }
}

function StatusChip({ status }: StatusChipProps) {
  const chipStyles = getChipStyles(status)
  const label = getChipLabel(status)

  return (
    <Chip
      label={label}
      sx={{
        ...chipStyles,
        justifySelf: 'start',
        borderRadius: PortalRadiuses.xs,
        ...LabelMediumStrongUppercase
      }}
    />
  )
}

export default StatusChip
