import styled from '@emotion/styled'
import { Box, Button } from '@mui/material'

import { Permit } from '@contracts/types/Permit'

import TextsExtended from '@my-drifter/assets/texts_extended.json'
import { getFigmaText } from '@my-drifter/libs/TextRepository'
import { TitleLarge } from '@my-drifter/libs/Typography'

import EmptyStateScreen from './EmptyStateScreen'
import Loader from './Loader'
import PermitInfo from './PermitInfo'
import { PortalColors } from './PortalColors'
import { PortalRadiuses } from './PortalRadiuses'
import { PortalSpacings } from './PortalSpacings'

interface PermitInfoContainerProps {
  permits?: Permit[]
  setSelectedPermit: (permitId) => void
  onAddPermit: () => void
  onShowPermitUsers: () => void
  onEditPermit: (id: string) => void
  isLoadingPermits: boolean
}

function PermitInfoContainer({
  permits,
  setSelectedPermit,
  onAddPermit,
  onShowPermitUsers,
  onEditPermit,
  isLoadingPermits
}: PermitInfoContainerProps) {
  if (!permits || permits.length === 0) {
    return (
      <EmptyStateScreen
        title={getFigmaText(TextsExtended.permitsDashboardDebtorsAndPermitsNoPermitsYet)}
        action={onAddPermit}
        actionTitle={getFigmaText(TextsExtended.permitsButtonsaddpermit)}
      />
    )
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: PortalSpacings.x6,
        gap: PortalSpacings.x6,
        flex: 1
      }}
    >
      <StyledHeader>
        <StyledHeaderHeadline>
          {getFigmaText(TextsExtended.permitsDashboardDebtorsAndPermitsheading)}
        </StyledHeaderHeadline>
        <Button
          variant="contained"
          sx={{
            backgroundColor: PortalColors.surfaceActionPrimary,
            padding: `${PortalSpacings.x3} ${PortalSpacings.x4}`,
            textTransform: 'none',
            borderRadius: PortalRadiuses.sm,
            '&:hover': { backgroundColor: PortalColors.surfaceActionPrimaryHover }
          }}
          onClick={onAddPermit}
        >
          {getFigmaText(TextsExtended.permitsButtonsaddpermit)}
        </Button>
      </StyledHeader>
      {isLoadingPermits ? (
        <Box sx={{ margin: 'auto' }}>
          <Loader $loaderColor={PortalColors.surfaceActionSecondary} />
        </Box>
      ) : (
        permits.map((p) => (
          <PermitInfo
            key={p.id}
            permit={p}
            onPermitSelected={() => setSelectedPermit(p.id)}
            onShowPermitUsers={onShowPermitUsers}
            onEditPermit={onEditPermit}
          />
        ))
      )}
    </Box>
  )
}

export default PermitInfoContainer

const StyledHeader = styled.header({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
})

const StyledHeaderHeadline = styled.h3({
  color: PortalColors.textPrimary,
  ...TitleLarge,
  margin: 0
})
