import { createContext } from 'react'

import useUpsertPermitData from '@my-drifter/hooks/useUpsertPermitData'

export const UpsertPermitContext = createContext({} as ReturnType<typeof useUpsertPermitData>)

export function UpsertPermitProvider({ children }) {
  const upsertPermitData = useUpsertPermitData()

  return <UpsertPermitContext.Provider value={upsertPermitData}>{children}</UpsertPermitContext.Provider>
}
