import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import translationEN from './assets/locales/en/translations.json'
import translationSV from './assets/locales/sv/translations.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: translationEN },
      sv: { translation: translationSV }
    },
    detection: {
      order: ['navigator'],
      caches: []
    }
  })

export default i18n
