import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { HikerUserRole } from '@contracts/types/HikerUser'

import useAppState from './useAppState'
import { useSelectedSiteContext } from './useSelectedSiteContext'
import { useSite } from './useSite'
import { useSitePermitDebtorPermit } from './useSitePermitDebtorPermit'
import { useSitePermitDebtors } from './useSitePermitDebtors'
import { useSitePermitDebtorsPermits } from './useSitePermitDebtorsPermits'
import { useUser } from './useUser'

export default function useUpsertPermitData() {
  const { state } = useAppState()
  const { data: user, isLoading: isLoadingUser } = useUser(localStorage.getItem('userId') || state?.userId)
  const isAllowed =
    !isLoadingUser &&
    user?.role &&
    [HikerUserRole.ADMIN, HikerUserRole.ROOT].includes(user.role) &&
    user?.siteIds &&
    user?.siteIds?.length > 0

  const isRoot = user?.role === HikerUserRole.ROOT

  const { selectedSiteId, setSelectedSiteId } = useSelectedSiteContext()
  const { data: site, isLoading: isLoadingSite } = useSite(selectedSiteId)

  const [selectedSitePermitDebtorId, setSelectedSitePermitDebtorId] = useState<string>()
  const [selectedSitePermitDebtorPermitId, setSelectedSitePermitDebtorPermitId] = useState<string>()

  const { data: sitePermitDebtors, isLoading: isLoadingSitePermitDebtors } = useSitePermitDebtors(selectedSiteId)
  const { data: sitePermitDebtorsPermits, isLoading: isLoadingSitePermitDebtorsPermits } = useSitePermitDebtorsPermits(
    selectedSiteId,
    selectedSitePermitDebtorId
  )
  const { data: selectedPermit } = useSitePermitDebtorPermit(
    selectedSiteId,
    selectedSitePermitDebtorId,
    selectedSitePermitDebtorPermitId
  )

  const selectedPermitDebtor = sitePermitDebtors?.find((d) => d.id === selectedSitePermitDebtorId)
  const [searchParams] = useSearchParams()
  useEffect(() => {
    const debtorId = searchParams.get('debtorId')
    const permitId = searchParams.get('permitId')
    if (debtorId) {
      setSelectedSitePermitDebtorId(debtorId)
    }
    if (permitId) {
      setSelectedSitePermitDebtorPermitId(permitId)
    }
  }, [searchParams])

  return {
    isRoot,
    isAllowed,
    isLoadingUser,
    isLoadingSite,
    isLoadingSitePermitDebtors,
    isLoadingSitePermitDebtorsPermits,
    selectedSiteId,
    setSelectedSiteId,
    selectedSitePermitDebtorId,
    setSelectedSitePermitDebtorId,
    selectedSitePermitDebtorPermitId,
    setSelectedSitePermitDebtorPermitId,
    selectedPermit,
    selectedPermitDebtor,
    site,
    sitePermitDebtors,
    sitePermitDebtorsPermits
  }
}
