import { CircularProgress, Dialog, DialogContent, DialogTitle } from '@mui/material'

import { LAYOUT_LEFT_PADDING } from './HardcodedSizes'
import { PortalColors } from './PortalColors'

interface LoadingModalProps {
  open: boolean
  title: string
}
function LoadingModal({ open, title }: LoadingModalProps) {
  return (
    <Dialog
      open={open}
      aria-labelledby="submission-dialog-title"
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(255, 255, 255, 0.7)' // Set your desired backdrop color here
          }
        }
      }}
      sx={{
        '& .MuiDialog-paper': {
          marginLeft: `${LAYOUT_LEFT_PADDING}px`, // Offset the dialog by the menu width
          transform: 'translateX(+50%)' // Center within the remaining space
        }
      }}
    >
      <DialogTitle id="submission-dialog-title">{title}</DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <CircularProgress sx={{ color: PortalColors.surfaceActionSecondary }} />
      </DialogContent>
    </Dialog>
  )
}

export default LoadingModal
