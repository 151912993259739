/*
    Combined Drawer for both UserPermitConnection and FleetVehicle
 */
import styled from '@emotion/styled'
import CheckIcon from '@mui/icons-material/Check'
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { useState } from 'react'

import { Permit, PermitConnectionType } from '@contracts/types/Permit'

import TextsExtended from '@my-drifter/assets/texts_extended.json'
import { getFigmaText } from '@my-drifter/libs/TextRepository'
import { BodySmallStrong, LabelLargeRegular, LabelLargeStrong } from '@my-drifter/libs/Typography'

import AddNewUserForm from './AddNewUserForm'
import AddNewVehicleForm from './AddNewVehicleForm'
import NewRightSideDrawer from './NewRightSideDrawer'
import { PortalColors } from './PortalColors'
import { PortalRadiuses } from './PortalRadiuses'
import { PortalSpacings } from './PortalSpacings'

export interface AddPermitConnectionDrawerProps {
  permit?: Permit
  selectedSiteId?: string
  selectedSitePermitDebtorId?: string
  isDrawerOpen: boolean
  onCloseDrawer: () => void
}

export default function AddPermitConnectionDrawer({
  permit,
  selectedSiteId,
  selectedSitePermitDebtorId,
  isDrawerOpen,
  onCloseDrawer
}: AddPermitConnectionDrawerProps) {
  const [tab, setTab] = useState<PermitConnectionType>(PermitConnectionType.User)

  const FormComponent = {
    [PermitConnectionType.User]: AddNewUserForm,
    [PermitConnectionType.Vehicle]: AddNewVehicleForm
  }[tab]

  const onToggleButtonChange = (_event, value) => {
    if (value) {
      setTab(value)
    }
  }
  return (
    <NewRightSideDrawer
      isDrawerOpen={isDrawerOpen}
      setIsDrawerOpen={onCloseDrawer}
      title={getFigmaText(TextsExtended.permitsSetupPermitHolder)}
    >
      <Box>
        <StyledLabel>{getFigmaText(TextsExtended.permitsInputFieldsuserType)}</StyledLabel>
        <ToggleButtonGroup value={tab} exclusive onChange={onToggleButtonChange} sx={{ width: '100%' }}>
          <ToggleButton value={PermitConnectionType.User} aria-label="Organization" sx={toggleButtonStyle}>
            {tab === PermitConnectionType.User && <CheckIcon sx={{ fontSize: '16px', mr: 1 }} />}
            {getFigmaText(TextsExtended.permitsButtonsuserindividual)}
          </ToggleButton>
          <ToggleButton value={PermitConnectionType.Vehicle} aria-label="Individual" sx={toggleButtonStyle}>
            {tab === PermitConnectionType.Vehicle && <CheckIcon sx={{ fontSize: '16px', mr: 1 }} />}
            {getFigmaText(TextsExtended.permitsButtonsuserfleet)}
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <FormComponent
        permit={permit}
        selectedSiteId={selectedSiteId}
        selectedSitePermitDebtorId={selectedSitePermitDebtorId}
        isDrawerOpen={isDrawerOpen}
        onCloseDrawer={onCloseDrawer}
      />
    </NewRightSideDrawer>
  )
}

const toggleButtonStyle = {
  backgroundColor: 'white',
  color: PortalColors.textOnActionTertiary,
  ...LabelLargeRegular,
  textTransform: 'none',
  borderRadius: PortalRadiuses.sm,
  flex: 1,
  '&:hover': {
    backgroundColor: PortalColors.surfaceActionTertiaryHover
  },
  '&.Mui-selected': {
    backgroundColor: PortalColors.surfaceActionTertiaryPressed,
    color: PortalColors.textOnActionPrimary,
    ...LabelLargeStrong,
    '&:hover': {
      backgroundColor: PortalColors.surfaceActionPrimaryHover
    }
  }
}

const StyledLabel = styled.p({
  color: PortalColors.textPrimary,
  margin: 0,
  ...BodySmallStrong,
  display: 'block',
  marginBottom: PortalSpacings.x2
})
