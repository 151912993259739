import styled from '@emotion/styled'
import { Box, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select } from '@mui/material'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'

import { PermitValidityPeriod } from '@contracts/types/PermitTemplate'

import {
  DaysValidityOptions,
  generateTimeAndDayOfWeekValidity,
  getCustomDays,
  getDaysValidityOptions,
  getStartAndEndHour,
  getTimeValidityOptions,
  TimeValidityOptions,
  VALIDITY_ALL_WEEK,
  VALIDITY_WEEKDAYS,
  VALIDITY_WEEKEND
} from '@pure/libs/permit/validityPeriodHelper'

import TextsExtended from '@my-drifter/assets/texts_extended.json'
import { getFigmaText } from '@my-drifter/libs/TextRepository'
import { BodyMedium, BodySmallStrong } from '@my-drifter/libs/Typography'

import { PortalColors } from './PortalColors'
import { PortalRadiuses } from './PortalRadiuses'
import { PortalSpacings } from './PortalSpacings'
import WeekdaySelector from './WeekdaySelector'

const getNumbersFromDaysValidity = {
  [DaysValidityOptions.All]: VALIDITY_ALL_WEEK,
  [DaysValidityOptions.Weekdays]: VALIDITY_WEEKDAYS,
  [DaysValidityOptions.Weekend]: VALIDITY_WEEKEND
}

interface ValidityPeriodFormProps {
  validityPeriods?: PermitValidityPeriod[]
  setValidityPeriods: (validityPeriods: PermitValidityPeriod[]) => void
}

function ValidityPeriodForm({ validityPeriods = [], setValidityPeriods }: ValidityPeriodFormProps) {
  const defaultDaysValidity = getDaysValidityOptions(validityPeriods)
  const defaultTimeValidity = getTimeValidityOptions(validityPeriods)
  const { startHour, endHour } = getStartAndEndHour(validityPeriods)
  const defaultCustomDays = getCustomDays(validityPeriods)
  const [daysValidity, setDaysValidity] = useState<DaysValidityOptions>(defaultDaysValidity)
  const [timeValidity, setTimeValidity] = useState<TimeValidityOptions>(defaultTimeValidity)
  const [startHourValidity, setStartHourValidity] = useState<Dayjs | null>(dayjs().hour(startHour))
  const [endHourValidity, setEndHourValidity] = useState<Dayjs | null>(dayjs().hour(endHour))
  const [customDays, setCustomDays] = useState<number[]>(defaultCustomDays)

  useEffect(() => {
    const startHour = timeValidity === TimeValidityOptions.AllDay ? 0 : (startHourValidity?.get('hour') ?? 0)
    const endHour = timeValidity === TimeValidityOptions.AllDay ? 24 : (endHourValidity?.get('hour') ?? 0)
    const days = daysValidity === DaysValidityOptions.Custom ? customDays : getNumbersFromDaysValidity[daysValidity]
    const validityPeriods = generateTimeAndDayOfWeekValidity(startHour, endHour, days)
    setValidityPeriods(validityPeriods)
  }, [daysValidity, startHourValidity, endHourValidity, timeValidity, customDays])

  return (
    <FormControl
      fullWidth
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: PortalSpacings.x6
      }}
    >
      <Box sx={{}}>
        <StyledFormLabel id="demo-controlled-radio-buttons-group">
          {getFigmaText(TextsExtended.permitsInputFieldstimeOfDay)}
        </StyledFormLabel>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={timeValidity}
          onChange={(event) => setTimeValidity(event.currentTarget.value as TimeValidityOptions)}
          sx={{
            padding: '10px',
            gap: PortalSpacings.x2,
            '& .MuiButtonBase-root': {
              padding: 0,
              marginRight: PortalSpacings.x2
            }
          }}
        >
          <FormControlLabel
            value={TimeValidityOptions.AllDay}
            control={<Radio size="small" />}
            label={getFigmaText(TextsExtended.permitsButtonsallDay)}
            sx={{
              '& .MuiTypography-root': {
                ...BodyMedium,
                paddingLeft: '1+0x'
              }
            }}
          />
          <FormControlLabel
            value={TimeValidityOptions.Custom}
            control={<Radio size="small" />}
            label={getFigmaText(TextsExtended.permitsButtonsspecificTime)}
            sx={{
              '& .MuiTypography-root': {
                ...BodyMedium
              }
            }}
          />
        </RadioGroup>
      </Box>

      <Box sx={{ gridColumn: 2 }}>
        <StyledFormLabel id="validity-days-label">
          {getFigmaText(TextsExtended.permitsInputFieldsdaysofweek)}
        </StyledFormLabel>
        <Select
          labelId="validity-days-label"
          id="demo-simple-select"
          value={daysValidity}
          onChange={(event) => setDaysValidity(event.target.value as DaysValidityOptions)}
          sx={{ display: 'block', ...comboboxFieldStyle }}
        >
          <MenuItem key={DaysValidityOptions.All} value={DaysValidityOptions.All}>
            {getFigmaText(TextsExtended.permitsInputFieldsalldays)}
          </MenuItem>
          <MenuItem key={DaysValidityOptions.Weekdays} value={DaysValidityOptions.Weekdays}>
            {getFigmaText(TextsExtended.permitsInputFieldsweekdays)}
          </MenuItem>
          <MenuItem key={DaysValidityOptions.Weekend} value={DaysValidityOptions.Weekend}>
            {getFigmaText(TextsExtended.permitsInputFieldsweekends)}
          </MenuItem>
          <MenuItem key={DaysValidityOptions.Custom} value={DaysValidityOptions.Custom}>
            {getFigmaText(TextsExtended.permitsInputFieldscustom)}
          </MenuItem>
        </Select>
      </Box>

      {timeValidity === TimeValidityOptions.Custom && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: PortalColors.surfaceLevelSecond,
              borderRadius: PortalRadiuses.sm,
              alignItems: 'center',
              padding: PortalSpacings.x6,
              gap: PortalSpacings.x6,
              justifyContent: 'center'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: PortalSpacings.x2 }}>
              <StyledFormLabel> {getFigmaText(TextsExtended.permitsInputFieldsstartTime)} </StyledFormLabel>

              <TimePicker
                value={startHourValidity}
                onChange={setStartHourValidity}
                views={['hours']}
                ampm={false}
                sx={{ backgroundColor: PortalColors.surfaceLevelFirst, maxWidth: '143px' }}
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: PortalSpacings.x2 }}>
              <StyledFormLabel>{getFigmaText(TextsExtended.permitsInputFieldsendTime)} </StyledFormLabel>

              <TimePicker
                value={endHourValidity}
                onChange={setEndHourValidity}
                views={['hours']}
                ampm={false}
                sx={{ backgroundColor: PortalColors.surfaceLevelFirst, maxWidth: '143px' }}
              />
            </Box>
          </Box>
        </LocalizationProvider>
      )}

      {daysValidity === DaysValidityOptions.Custom && (
        <Box
          sx={{
            backgroundColor: PortalColors.surfaceLevelSecond,
            display: 'flex',
            borderRadius: PortalRadiuses.sm,
            gridColumn: 2,
            padding: PortalSpacings.x6,
            gap: PortalSpacings.x6,
            justifyContent: 'center'
          }}
        >
          <WeekdaySelector selectedDays={customDays} setSelectedDays={setCustomDays} />
        </Box>
      )}
    </FormControl>
  )
}
export default ValidityPeriodForm

const StyledFormLabel = styled.label({
  ...BodySmallStrong,
  display: 'block',
  marginBottom: PortalSpacings.x2,
  color: PortalColors.textPrimary
})

const comboboxFieldStyle = {
  width: '100%',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: PortalColors.borderBrand
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: PortalColors.borderStrong // border color when the combobox is focused/selected
  }
}
