import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import ErrorIcon from '@mui/icons-material/Error'
import { Alert, Box as MuiBox, Button, Snackbar, SnackbarCloseReason, TextField } from '@mui/material'
import { captureException } from '@sentry/react'
import { memo, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Permit } from '@contracts/types/Permit'

import TextsExtended from '@my-drifter/assets/texts_extended.json'
import { upsertPermit } from '@my-drifter/libs/DBApiHandler'
import { getFigmaText } from '@my-drifter/libs/TextRepository'
import Texts from '@my-drifter/libs/Texts'
import { BodySmallStrong, LabelLargeRegular } from '@my-drifter/libs/Typography'

import { Spacings } from '@web-components/enums/Spacings'

import { LAYOUT_HEADER_HEIGHT } from './HardcodedSizes'
import Loader from './Loader'
import NewRightSideDrawer from './NewRightSideDrawer'
import { PortalColors } from './PortalColors'
import { PortalRadiuses } from './PortalRadiuses'
import { PortalSpacings } from './PortalSpacings'

type NewUpdateVehicleDrawerProps = {
  permit?: Permit
  selectedSiteId?: string
  selectedSitePermitDebtorId?: string
  isDrawerOpen: boolean
  onCloseDrawer: () => void
  plate: string
  description?: string
}

const NewUpdateVehicleDrawer = ({
  permit,
  selectedSiteId,
  selectedSitePermitDebtorId,
  isDrawerOpen,
  onCloseDrawer,
  plate,
  description
}: NewUpdateVehicleDrawerProps) => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const schema = yup.object().shape({
    plate: yup.string().required(getFigmaText(Texts.textMyPortalVehicleValidation)),
    description: yup.string().optional()
  })

  const {
    handleSubmit,
    reset,
    register,
    formState: { isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      plate,
      description
    }
  })

  const onSubmit = async (data: { plate: string; description?: string }) => {
    const { plate, description } = data
    if (!permit || !selectedSiteId || !selectedSitePermitDebtorId) return

    const fleetVehicles = permit.fleetVehicles ?? {}

    fleetVehicles[plate] = { plate: plate.trim().replace(/\s/g, '').toUpperCase(), description }
    const updatedPermit = {
      ...permit,
      fleetVehicles
    }
    try {
      await upsertPermit(selectedSiteId, selectedSitePermitDebtorId, permit.id, updatedPermit)
    } catch (e) {
      captureException(e)
      setIsSnackbarOpen(true)
      console.log(e)
    }

    onCloseDrawer()
  }

  function handleSnackbarClose(event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) {
    if (reason === 'clickaway') {
      return
    }
    setIsSnackbarOpen(false)
  }

  useEffect(() => {
    reset({ plate, description })
  }, [plate, description])

  return (
    <NewRightSideDrawer isDrawerOpen={isDrawerOpen} setIsDrawerOpen={onCloseDrawer} title="Update vehicle">
      <MuiBox sx={{ display: 'flex', flex: 1 }}>
        {isSubmitting ? (
          <MuiBox sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
            <Loader />
          </MuiBox>
        ) : (
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <MuiBox>
              <StyledDisabledLabel>Plate number</StyledDisabledLabel>

              <TextField id="plate" value={plate} fullWidth size="small" disabled />
            </MuiBox>
            <MuiBox>
              <StyledLabel>Description</StyledLabel>

              <TextField id="description" {...register('description')} fullWidth size="small" sx={textFieldStyle} />
            </MuiBox>
            <MuiBox
              sx={{
                marginTop: 'auto',
                marginBottom: PortalSpacings.x8,
                display: 'flex',
                flexDirection: 'row',
                padding: PortalSpacings.x4,
                gap: PortalSpacings.x4
              }}
            >
              <Button
                onClick={onCloseDrawer}
                sx={{
                  color: PortalColors.textOnActionTertiary,
                  textTransform: 'none',
                  ...LabelLargeRegular,
                  padding: PortalSpacings.x2,
                  borderRadius: PortalRadiuses.sm,
                  flex: 1,
                  '&:hover': {
                    backgroundColor: PortalColors.surfaceActionTertiaryHover
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{
                  backgroundColor: PortalColors.surfaceActionSecondary,
                  color: PortalColors.textOnActionSecondary,
                  textTransform: 'none',
                  ...LabelLargeRegular,
                  padding: PortalSpacings.x2,
                  borderRadius: PortalRadiuses.sm,
                  flex: 1,
                  '&:hover': {
                    backgroundColor: PortalColors.surfaceActionSecondaryHover
                  }
                }}
              >
                Update vehicle
              </Button>
            </MuiBox>
          </StyledForm>
        )}
      </MuiBox>

      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          variant="filled"
          icon={<ErrorIcon />}
          sx={{
            width: '100%',
            marginTop: LAYOUT_HEADER_HEIGHT,
            backgroundColor: PortalColors.surfaceError,
            color: PortalColors.textPrimary,
            border: `1px solid ${PortalColors.borderError}`,
            '& .MuiAlert-icon': {
              color: PortalColors.iconError
            }
          }}
        >
          {getFigmaText(TextsExtended.permitsInputfieldsErrorsystem)}
        </Alert>
      </Snackbar>
    </NewRightSideDrawer>
  )
}

const StyledForm = styled.form`
  display: flex;
  gap: ${Spacings.s};
  flex-direction: column;
  flex: 1;
`

const StyledLabel = styled.p({
  color: PortalColors.textPrimary,
  margin: 0,
  ...BodySmallStrong,
  display: 'block',
  marginBottom: PortalSpacings.x2
})

const StyledDisabledLabel = styled.p({
  color: 'grey',
  margin: 0,
  ...BodySmallStrong,
  display: 'block',
  marginBottom: PortalSpacings.x2
})

const textFieldStyle = {
  '& .MuiOutlinedInput-root': {
    backgroundColor: PortalColors.surfaceLevelFirst, // default background
    '& fieldset': {
      borderColor: PortalColors.borderAccessibleHigh // empty border color
    },
    // When empty and hovered
    '&:hover input:placeholder-shown': {
      backgroundColor: PortalColors.surfaceLevelSecond
    },
    // When focused (whether empty or not)
    '&.Mui-focused fieldset': {
      borderColor: PortalColors.borderStrong
    },
    // When typing (input not empty)
    '& input:not(:placeholder-shown) + fieldset': {
      borderColor: PortalColors.borderBrand
    }
  }
}

export default memo(NewUpdateVehicleDrawer)
