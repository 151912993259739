import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import {
  Box,
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { Fragment, useMemo, useState } from 'react'

import { groupOccupancyByDominantType, groupOccupancyByHourInterval } from '@my-drifter/libs/OccupancyHelper'
import { SlotOccupancy } from '@my-drifter/screens/SessionOverview'

dayjs.extend(weekday)
dayjs.extend(weekOfYear)
dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
  weekStart: 1
})

interface WeeklyOccupancyTableProps {
  occupancy: SlotOccupancy[]
  slotCount: number
  date: Dayjs
}

const now = dayjs()

const currentWeekNumber = now.week()
const currentWeekday = now.weekday()

const categoryLabels = {
  free: 'Gratisparkering',
  permit: 'Parkering med tillstånd',
  parking: 'Betalparkering',
  offense: 'Parkeringsöverträdelser'
}
const weekdayLabels = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

// start and end are minute indices 0 - 23
const timeIntervals = [
  { start: 0, end: 6, label: '00:00 - 06:00' },
  { start: 6, end: 12, label: '06:00 - 12:00' },
  { start: 12, end: 18, label: '12:00 - 18:00' },
  { start: 18, end: 24, label: '18:00 - 24:00' }
]

export default function WeeklyOccupancyTable({ occupancy, slotCount, date }: WeeklyOccupancyTableProps) {
  const [expandedRow, setExpandedRow] = useState<number>(-1)

  const rows = useMemo(() => processOccupancyData(occupancy, slotCount, date), [occupancy, slotCount, date])

  const handleToggleRow = (dayIndex: number) => {
    setExpandedRow(expandedRow === dayIndex ? -1 : dayIndex)
  }

  const getRowStyle = (day: string) => {
    if (day === 'Thursday') return { backgroundColor: '#f0f5ff' }
    if (['Friday', 'Saturday', 'Sunday'].includes(day)) return { color: '#a0a0a0' }
    return {}
  }

  return (
    <Box>
      <TableContainer component={Paper} sx={{ mb: 2, borderRadius: 2, overflow: 'hidden' }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f8f9fa' }}>
              <TableCell sx={{ fontWeight: 'bold', color: '#1a237e' }}>DAY</TableCell>
              {timeIntervals.map((interval, index) => (
                <TableCell key={index} align="center" sx={{ fontWeight: 'bold', color: '#1a237e' }}>
                  {interval.label}
                </TableCell>
              ))}
              <TableCell>{/* Empty cell for the expand/collapse icon */}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, dayIndex) => (
              <Fragment key={weekdayLabels[dayIndex]}>
                <TableRow
                  sx={{
                    ...getRowStyle(weekdayLabels[dayIndex]),
                    '&:hover': { backgroundColor: '#f5f5f5' },
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    if (row.intervals.every((interval) => interval === '-')) return

                    handleToggleRow(dayIndex)
                  }}
                >
                  <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {weekdayLabels[dayIndex]}
                      {row.status && (
                        <Chip
                          label={row.status}
                          size="small"
                          sx={{
                            backgroundColor: '#e6f7e6',
                            color: '#2e7d32',
                            fontWeight: 'bold',
                            ml: 1
                          }}
                        />
                      )}
                    </Box>
                  </TableCell>

                  {row.intervals.map((intervalValue, index) => (
                    <Fragment key={`${dayIndex}-${index}`}>
                      <TableCell align="center" sx={index === row.intervals.length - 1 ? { position: 'relative' } : {}}>
                        {intervalValue}
                      </TableCell>
                      {index === row.intervals.length - 1 && (
                        <TableCell align="right">
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            disabled={row.intervals.every((interval) => interval === '-')}
                            onClick={(e) => {
                              e.stopPropagation()
                              handleToggleRow(dayIndex)
                            }}
                          >
                            {expandedRow === dayIndex ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                          </IconButton>
                        </TableCell>
                      )}
                    </Fragment>
                  ))}
                </TableRow>
                {expandedRow === dayIndex &&
                  row.details &&
                  Object.entries(row.details).map(([type, values], index) => (
                    <TableRow key={`${type}-${dayIndex}`} sx={{ backgroundColor: '#fafafa' }}>
                      <TableCell sx={{ pl: 4, color: '#1a237e', fontWeight: index === 0 ? 'bold' : 'normal' }}>
                        {categoryLabels[type]}
                      </TableCell>
                      <TableCell align="center">{values[0] ?? '-'}</TableCell>
                      <TableCell align="center">{values[1] ?? '-'}</TableCell>
                      <TableCell align="center">{values[2] ?? '-'}</TableCell>
                      <TableCell align="center">{values[3] ?? '-'}</TableCell>
                      <TableCell align="center">{/* Empty cell for the expand/collapse icon */}</TableCell>
                    </TableRow>
                  ))}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

function processOccupancyData(occupancy: SlotOccupancy[], slotCount: number, date: Dayjs = dayjs()) {
  const weekdayGroups = groupOccupancyByWeekday(occupancy, date)

  const rows = weekdayGroups.map((daySessions, index) => {
    const isCurrentWeek = date.week() === currentWeekNumber
    const isToday = isCurrentWeek && index === currentWeekday
    const intervals: string[] = []
    const details = {
      free: [],
      permit: [],
      parking: [],
      offense: []
    }

    const totalOccupancyInDay = daySessions.length

    if (totalOccupancyInDay === 0) {
      return {
        intervals: ['-', '-', '-', '-'],
        details: []
      }
    }

    const dayIntervals = Array.from(new Set(timeIntervals.flatMap((interval) => [interval.start, interval.end])))

    const occupancyByInterval = groupOccupancyByHourInterval(
      daySessions,
      date.startOf('day').weekday(index).format(),
      dayIntervals
    )

    occupancyByInterval.forEach((occupancies) => {
      const occupancyByType = groupOccupancyByDominantType(occupancies)

      const occupancyInIntervalCount = occupancies.length

      const occupancyPercent = Math.round((occupancyInIntervalCount / slotCount) * 100)
      intervals.push(occupancyInIntervalCount > 0 ? `${occupancyInIntervalCount} (${occupancyPercent}%)` : '-')

      Object.entries(occupancyByType).forEach(([type, count]) => {
        const typePercent = Math.round((count / occupancyInIntervalCount) * 100)
        details[type].push(count > 0 ? `${count} (${typePercent}%)` : '-')
      })
    })

    return {
      status: isToday ? 'ONGOING' : undefined,
      details: details,
      intervals
    }
  })

  return rows
}

function groupOccupancyByWeekday(occupancies: SlotOccupancy[], date: Dayjs = dayjs()) {
  const weekdayGroups: SlotOccupancy[][] = Array.from({ length: 7 }, () => [])

  const startOfWeek = date.startOf('week')
  const endOfWeek = date.endOf('week')

  for (const occupancy of occupancies) {
    const firstEvent = occupancy.events[0]
    const lastEvent = occupancy.events[occupancy.events.length - 1]

    if (!firstEvent.startedAt || !lastEvent.endedAt) continue

    const startedAt = dayjs(firstEvent.startedAt)
    const endedAt = dayjs(lastEvent.endedAt)

    const overlapStart = startedAt.isBefore(startOfWeek) ? startOfWeek : startedAt
    const overlapEnd = endedAt.isAfter(endOfWeek) ? endOfWeek : endedAt

    const daysDiff = overlapEnd.diff(overlapStart, 'day')

    for (let i = 0; i <= daysDiff; i++) {
      const day = overlapStart.clone().add(i, 'day')
      const dayIndex = day.weekday()
      weekdayGroups[dayIndex].push(occupancy)
    }
  }

  return weekdayGroups
}
