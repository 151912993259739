import { createContext } from 'react'

import useNewPermitsScreenData from '@my-drifter/hooks/useNewPermitsScreenData'

export const NewPermitsContext = createContext({} as ReturnType<typeof useNewPermitsScreenData>)

export function NewPermitsProvider({ children }) {
  const permitsScreenData = useNewPermitsScreenData()

  return <NewPermitsContext.Provider value={permitsScreenData}>{children}</NewPermitsContext.Provider>
}
