import styled from '@emotion/styled'

import { InvoiceDeliveryMethod } from '@contracts/types/PermitDebtor'

import TextsExtended from '@my-drifter/assets/texts_extended.json'
import { getFigmaText } from '@my-drifter/libs/TextRepository'
import { BodySmall, LabelMediumRegular, TitleMediumStrong } from '@my-drifter/libs/Typography'

import { PortalColors } from './PortalColors'
import { PortalRadiuses } from './PortalRadiuses'
import { PortalSpacings } from './PortalSpacings'
interface BillingInformationDetailsProps {
  invoiceDeliveryMethod: string | undefined
  contactName: string | undefined
  email: string | undefined
  adressLine1: string | undefined
  adressLine2: string | undefined
  zipCode: number | undefined
  city: string | undefined
}

function BillingInformationDetails({
  invoiceDeliveryMethod,
  email,
  adressLine1,
  adressLine2,
  zipCode,
  city
}: BillingInformationDetailsProps) {
  return (
    <Wrapper>
      <StyledHeadline>{getFigmaText(TextsExtended.permitsInvoicebillingDetails)}</StyledHeadline>

      <DetailsContainer>
        <StyledRow>
          <StyledLabel>{getFigmaText(TextsExtended.permitsInvoicedetailsheader)}</StyledLabel>
          <StyledDetails>
            <StyledDetailsText>{invoiceDeliveryMethod}</StyledDetailsText>
          </StyledDetails>
        </StyledRow>

        {invoiceDeliveryMethod === InvoiceDeliveryMethod.Email && (
          <StyledRow>
            <StyledLabel>{getFigmaText(TextsExtended.permitsInvoicedeliveryEmail)}</StyledLabel>
            <StyledDetails>
              <StyledDetailsText>{email}</StyledDetailsText>
            </StyledDetails>
          </StyledRow>
        )}

        <StyledRow>
          <StyledLabel>{getFigmaText(TextsExtended.permitsInvoiceheadlineAddress)}</StyledLabel>
          <StyledDetails>
            <StyledDetailsText>{adressLine1}</StyledDetailsText>
            <StyledDetailsText>{adressLine2}</StyledDetailsText>
            <StyledDetailsText>{zipCode}</StyledDetailsText>
            <StyledDetailsText>{city}</StyledDetailsText>
          </StyledDetails>
        </StyledRow>
      </DetailsContainer>
    </Wrapper>
  )
}

export default BillingInformationDetails

const Wrapper = styled.div({
  backgroundColor: PortalColors.surfaceLevelFirst,
  borderTopRightRadius: PortalRadiuses.sm,
  display: 'flex',
  flexDirection: 'column',
  padding: `${PortalSpacings.x2} ${PortalSpacings.x6} ${PortalSpacings.x6} ${PortalSpacings.x6}`
})

const StyledHeadline = styled.h3({
  color: PortalColors.textPrimary,
  ...TitleMediumStrong
})

const DetailsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: PortalSpacings.x3
})

const StyledRow = styled.div({
  display: 'grid',
  gridTemplateColumns: ' 1fr 2fr',
  alignItems: 'center',
  gap: PortalSpacings.x6
})

const StyledDetails = styled.div({
  backgroundColor: PortalColors.surfaceLevelSecond,
  borderRadius: PortalRadiuses.xs,
  minHeight: '24px',
  alignContent: 'center',
  padding: `${PortalSpacings.x1} ${PortalSpacings.x2}`
})

const StyledDetailsText = styled.p({
  ...LabelMediumRegular,
  color: PortalColors.textPrimary,
  margin: 0
})

const StyledLabel = styled.p({
  color: PortalColors.textSecondary,
  textAlign: 'left',
  ...BodySmall,
  margin: 0
})
