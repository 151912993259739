import {
  CloudFunctions,
  GetBillectaDebtorRequest,
  GetOrCreateUserSecureRequest,
  GetSessionColumnDataForSiteRequest,
  PermitRequest
} from '@contracts/types/CloudFunctionTypes'
import {
  GetSessionDataForSiteRequest,
  GetSessionDataForSiteResponse
} from '@contracts/types/GetSessionDataForSiteRequest'
import { HikerUser } from '@contracts/types/HikerUser'
import { Permit, UpsertPermitRequest } from '@contracts/types/Permit'
import { PermitDebtor, UpsertPermitDebtorRequest } from '@contracts/types/PermitDebtor'

import { GetSessionStatsForSiteResponse } from '@functions-my-drifter/types/GetSessionStatsForSite'
import {
  PermitIncomeByMonthRequest,
  PermitIncomeByMonthResponse
} from '@functions-my-drifter/types/PermitIncomeByMonthForSite'

import { callFunction, callFunctionWithAuthorized } from '@web-js/libs/CloudFunctionsApiHandlerHelper'

import { auth, functions } from './FirebaseOptions'

export const getOrCreateUserSecure = (req: GetOrCreateUserSecureRequest): Promise<HikerUser> =>
  callFunction({ functions, cloudfunction: CloudFunctions.getOrCreateUserSecure, data: req }) as Promise<HikerUser>

export const getSessionColumnDataForSite = (
  req: GetSessionColumnDataForSiteRequest
): Promise<GetSessionStatsForSiteResponse> =>
  callFunction({
    functions,
    cloudfunction: CloudFunctions.getSessionColumnDataForSite,
    data: req
  }) as Promise<GetSessionStatsForSiteResponse>

export function getRevenueDataForSite(req: GetSessionDataForSiteRequest): Promise<GetSessionDataForSiteResponse> {
  return callFunction({
    functions,
    cloudfunction: CloudFunctions.getFinancialDataForSite,
    data: req
  }) as Promise<GetSessionDataForSiteResponse>
}

export function getPermitIncomeByMonthForSite(req: PermitIncomeByMonthRequest) {
  return callFunction({
    functions,
    cloudfunction: CloudFunctions.getPermitIncomeByMonthForSite,
    data: req
  }) as Promise<PermitIncomeByMonthResponse>
}

export const getLinkToGuestParkingFeauture = (req: {
  siteId: string
  expiresAt?: string
  key?: string
}): Promise<{ url: string }> =>
  callFunction({
    functions,
    cloudfunction: CloudFunctions.getLinkToGuestParkingFeauture,
    data: req
  }) as Promise<{ url: string }>

export const terminatePermitConnection = (data: PermitRequest): Promise<Permit> =>
  callFunctionWithAuthorized({
    auth: auth,
    functions,
    cloudfunction: CloudFunctions.terminatePermitConnection,
    data
  }) as Promise<Permit>

export const upsertPermitDebtor = (data: UpsertPermitDebtorRequest): Promise<PermitDebtor> =>
  callFunctionWithAuthorized({
    auth,
    functions,
    cloudfunction: CloudFunctions.upsertPermitDebtor,
    data
  }) as Promise<PermitDebtor>

export const upsertPermit = (data: UpsertPermitRequest): Promise<Permit> =>
  callFunctionWithAuthorized({
    auth,
    functions,
    cloudfunction: CloudFunctions.upsertPermit,
    data
  }) as Promise<Permit>

export const getBillectaDebtor = (data: GetBillectaDebtorRequest): Promise<PermitDebtor> =>
  callFunctionWithAuthorized({
    auth,
    functions,
    cloudfunction: CloudFunctions.getBillectaDebtor,
    data
  }) as Promise<PermitDebtor>

export const getSiteNames = () =>
  callFunction({
    functions,
    cloudfunction: CloudFunctions.getSiteNames
  }) as Promise<{ id: string; name: string }[]>
