import styled from '@emotion/styled'
import ControlPoint from '@mui/icons-material/ControlPoint'
import { memo, useContext, useState } from 'react'

import { BorderRadiusesPx } from '@contracts/types/BorderRadixes'
import { Permit } from '@contracts/types/Permit'

import { Colors } from '@pure/assets/Colors'

import Texts from '@my-drifter/libs/Texts'
import { PermitsContext } from '@my-drifter/store/PermitsProvider'

import { Spacings } from '@web-components/enums/Spacings'

import AddVehicleDrawer from './AddVehicleDrawer'
import Box from './Box'
import FigmaText from './FigmaText'
import UpdateVehicleDrawer from './UpdateVehicleDrawer'

type PermitsConnectedVehiclesProps = {
  permit?: Permit
  selectedSiteId?: string
  selectedSitePermitDebtorId?: string
}

const PermitsConnectedVehicles = ({
  permit,
  selectedSiteId,
  selectedSitePermitDebtorId
}: PermitsConnectedVehiclesProps) => {
  const {
    isDesktop,
    isAnyDrawerOpen,
    isVehicleDrawerOpen,
    setIsVehicleDrawerOpen,
    isUpdateVehicleDrawerOpen,
    setIsUpdateVehicleDrawerOpen
  } = useContext(PermitsContext)
  const [plateNumberToDelete, setPlateNumberToDelete] = useState('')

  const fleetVehiclesList = Object.values(permit?.fleetVehicles ?? {})

  return (
    <>
      <Container fullWidth align="center" isDrawerOpen={isAnyDrawerOpen} isDesktop={isDesktop}>
        <Box fullWidth direction="row" align="center" justify="space-between" top spacing={Spacings.xxxl}>
          <FigmaText textKey={Texts.textMyPortalConnectedVehiclesHeader} />
          <AddVehicleButton onClick={() => setIsVehicleDrawerOpen(!isVehicleDrawerOpen)}>
            <ControlPoint fontSize="medium" />
          </AddVehicleButton>
        </Box>
        <Box fullWidth top>
          {fleetVehiclesList.map(({ plate }) => {
            return (
              <NumberPlateBox
                key={plate}
                fullWidth
                fullPadding
                spacing={Spacings.xs}
                onClick={() => {
                  setIsUpdateVehicleDrawerOpen(true)
                  setPlateNumberToDelete(plate)
                }}
              >
                {plate}
              </NumberPlateBox>
            )
          })}
        </Box>
      </Container>
      <AddVehicleDrawer
        permit={permit}
        selectedSiteId={selectedSiteId}
        selectedSitePermitDebtorId={selectedSitePermitDebtorId}
        isDrawerOpen={isVehicleDrawerOpen}
        setIsDrawerOpen={setIsVehicleDrawerOpen}
      />
      <UpdateVehicleDrawer
        permit={permit}
        selectedSiteId={selectedSiteId}
        selectedSitePermitDebtorId={selectedSitePermitDebtorId}
        isDrawerOpen={isUpdateVehicleDrawerOpen}
        setIsDrawerOpen={setIsUpdateVehicleDrawerOpen}
        plate={plateNumberToDelete}
      />
    </>
  )
}

const Container = styled(Box)<{ isDrawerOpen?: boolean; isDesktop: boolean }>`
  transition: max-width 0.2s ease-in-out;
  max-width: ${(props) => (props.isDrawerOpen ? '46%' : props.isDesktop ? '520px' : '100%')};
`

const AddVehicleButton = styled(Box)`
  cursor: pointer;
`

const NumberPlateBox = styled(Box)`
  cursor: pointer;
  border: 1px solid white;
  font-family: 'Inter';
  background-color: #f0f2ff;
  margin-bottom: ${Spacings.s};
  border-radius: ${BorderRadiusesPx.minimum};

  :hover {
    border-color: ${Colors.primary};
    transition: border-color 0.2s ease-in-out;
  }
`

export default memo(PermitsConnectedVehicles)
