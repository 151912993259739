import CheckIcon from '@mui/icons-material/Check'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'

import { VALIDITY_ALL_WEEK } from '@pure/libs/permit/validityPeriodHelper'

import TextsExtended from '@my-drifter/assets/texts_extended.json'
import { getFigmaText } from '@my-drifter/libs/TextRepository'
import { LabelLargeRegular } from '@my-drifter/libs/Typography'

import { PortalColors } from './PortalColors'
import { PortalRadiuses } from './PortalRadiuses'

interface WeekdaySelectorInterface {
  selectedDays: number[]
  setSelectedDays: (days: number[]) => void
}

export function getWeekDayText(day: number) {
  switch (day) {
    case 1:
      return getFigmaText(TextsExtended.sessionsAverageParkingdaymon)
    case 2:
      return getFigmaText(TextsExtended.sessionsAverageParkingdaytue)
    case 3:
      return getFigmaText(TextsExtended.sessionsAverageParkingdaywed)
    case 4:
      return getFigmaText(TextsExtended.sessionsAverageParkingdaythu)
    case 5:
      return getFigmaText(TextsExtended.sessionsAverageParkingdayfri)
    case 6:
      return getFigmaText(TextsExtended.sessionsAverageParkingdaysat)
    case 7:
      return getFigmaText(TextsExtended.sessionsAverageParkingdaysun)
  }
}

export default function WeekdaySelector({ selectedDays, setSelectedDays }: WeekdaySelectorInterface) {
  const handleSelection = (_: any, newDays: number[]) => {
    if (newDays.length > 0) {
      setSelectedDays(newDays.sort())
    }
  }

  return (
    <ToggleButtonGroup
      value={selectedDays}
      onChange={handleSelection}
      aria-label="weekdays"
      sx={{ width: '100%', display: 'flex', minWidth: 0 }}
    >
      {VALIDITY_ALL_WEEK.map((day) => (
        <ToggleButton key={day} value={day} sx={toggleButtonStyle}>
          {selectedDays.includes(day) && <CheckIcon sx={{ fontSize: '12px', marginRight: 1 }} />}
          {getWeekDayText(day)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

const toggleButtonStyle = {
  backgroundColor: 'white',
  color: PortalColors.textOnActionTertiary,
  ...LabelLargeRegular,
  textTransform: 'none',
  borderRadius: PortalRadiuses.sm,
  flex: 1,
  '&:hover': {
    backgroundColor: PortalColors.surfaceActionTertiaryHover
  },
  '&.Mui-selected': {
    backgroundColor: PortalColors.surfaceActionTertiaryPressed,
    color: PortalColors.textOnActionPrimary,
    '&:hover': {
      backgroundColor: PortalColors.surfaceActionPrimaryHover
    }
  }
}
