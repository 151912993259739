import styled, { CSSObject } from '@emotion/styled'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ErrorIcon from '@mui/icons-material/Error'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import WestIcon from '@mui/icons-material/West'
import { Alert, Box, Button, Menu, MenuItem, Snackbar, SnackbarCloseReason, Tooltip } from '@mui/material'
import { DataGrid, GridColDef, GridRowId } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { useContext, useState } from 'react'

import { SlackWebhooks } from '@contracts/enums/SlackWebhooks'
import {
  Permit,
  PermitConnectionType,
  PermitUserDeletionRequest,
  UserPermitConnectionSchemaStatus
} from '@contracts/types/Permit'
import { PermitDebtor } from '@contracts/types/PermitDebtor'

import { captureException } from '@web-js/libs/SentryHelper'
import { slack } from '@web-js/libs/SlackHelperClient'

import TextsExtended from '@my-drifter/assets/texts_extended.json'
import { useAuthUserContext } from '@my-drifter/hooks/useFirebaseAuthUser'
import { usePermitUsers } from '@my-drifter/hooks/usePermitUsers'
import { terminatePermitConnection } from '@my-drifter/libs/CloudFunctionsApiHandler'
import { deletePermitUserDeletion, upsertPermit } from '@my-drifter/libs/DBApiHandler'
import { Elevation2 } from '@my-drifter/libs/Elevation'
import { getFigmaText } from '@my-drifter/libs/TextRepository'
import Texts from '@my-drifter/libs/Texts'
import {
  BodyLargeRegular,
  BodySmall,
  LabelMediumRegular,
  LabelMediumRegularUppercase,
  LabelMediumStrongUppercase,
  TitleLarge
} from '@my-drifter/libs/Typography'
import { NewPermitsContext } from '@my-drifter/store/NewPermitsProvider'

import AddPermitConnectionDrawer from './AddPermitConnectionDrawer'
import FullScreenModal from './FullScreenModal'
import { LAYOUT_HEADER_HEIGHT } from './HardcodedSizes'
import NewUpdateVehicleDrawer from './NewUpdateVehicleDrawer'
import { PortalColors } from './PortalColors'
import { PortalRadiuses } from './PortalRadiuses'
import { PortalSpacings } from './PortalSpacings'
import StatusChip from './StatusChip'

type PermitUsersTableProps = {
  permit?: Permit
  selectedSiteId?: string
  selectedSitePermitDebtorId?: string
  onHidePermitUsers: () => void
  permitDebtor: PermitDebtor
}
const enum Option {
  REACTIVATE = ' reactivate',
  TERMINATE = 'terminate',
  DELETE_VEHICLE = 'delete_vehicle',
  EDIT_VEHICLE = 'edit_vehicle'
}

interface TableInfoRow {
  id: string // user.id or vehicle.plate
  name?: string /// user.name or vehicle.description
  status: UserPermitConnectionSchemaStatus | 'cancelled'
  email?: string
  phone?: string
  type: PermitConnectionType
  plates?: string[]
  expiryDate?: string
}

function MenuRows({
  selectedRowData,
  handleOnOptionClick
}: {
  selectedRowData: TableInfoRow
  handleOnOptionClick: (option: Option) => void
}) {
  const UserMenuRows = {
    [UserPermitConnectionSchemaStatus.ACTIVE]: () => {
      return (
        <MenuItem onClick={() => handleOnOptionClick(Option.TERMINATE)}>
          {getFigmaText(TextsExtended.permitsTabletitleDeactivate)}
        </MenuItem>
      )
    },
    cancelled: () => {
      return (
        <MenuItem onClick={() => handleOnOptionClick(Option.REACTIVATE)}>
          {getFigmaText(TextsExtended.permitsButtonsreactivate)}
        </MenuItem>
      )
    },
    [UserPermitConnectionSchemaStatus.TERMINATED]: () => {
      return (
        <MenuItem onClick={() => handleOnOptionClick(Option.REACTIVATE)}>
          {getFigmaText(TextsExtended.permitsButtonsreactivate)}
        </MenuItem>
      )
    }
  }[selectedRowData.status]
  switch (selectedRowData.type) {
    case PermitConnectionType.User:
      return <UserMenuRows />
    case PermitConnectionType.Vehicle:
      return (
        <>
          <MenuItem onClick={() => handleOnOptionClick(Option.EDIT_VEHICLE)}>
            {getFigmaText(TextsExtended.permitsButtonseditdetails)}
          </MenuItem>
          <MenuItem onClick={() => handleOnOptionClick(Option.DELETE_VEHICLE)}>
            {getFigmaText(TextsExtended.permitsButtonsremovepermanently)}
          </MenuItem>
        </>
      )
  }
}

function Plates({ plates }) {
  const plateNumbers: string[] = plates || []

  const filteredPlates = plateNumbers.filter((plate) => plate !== null && plate !== undefined)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  if (filteredPlates.length === 0) {
    return
  }

  const renderPlateNumber = (plate) => {
    if (plate.length > 6) {
      return (
        <Tooltip title={plate} arrow>
          <span>{`${plate.substring(0, 6)}...`}</span>
        </Tooltip>
      )
    }
    return <span>{plate}</span>
  }
  if (filteredPlates.length === 1) {
    return <StyledRegNumbers>{renderPlateNumber(filteredPlates[0])}</StyledRegNumbers>
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        sx={{
          border: `1px solid ${PortalColors.borderAccessibleHigh}`,
          backgroundColor: PortalColors.surfaceLevelThird,
          color: PortalColors.textPrimary,
          borderRadius: `${PortalRadiuses.xs} 0 0 ${PortalRadiuses.xs}`,
          ...(LabelMediumStrongUppercase as CSSObject),
          height: '24px',
          width: '64px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {renderPlateNumber(filteredPlates[0])}
      </Box>
      <Button
        aria-controls="plate-number-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          borderRadius: `0 ${PortalRadiuses.xs} ${PortalRadiuses.xs} 0`,
          backgroundColor: PortalColors.surfaceWhite,
          border: `1px solid ${PortalColors.borderAccessibleHigh}`,
          borderLeft: 'none',
          minWidth: 'auto',
          padding: 0
        }}
      >
        <ArrowDropDownIcon />
      </Button>
      <Menu
        id="plate-number-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {filteredPlates.map((plate, index) => (
          <MenuItem key={index}>{plate}</MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

function PermitUsersTable({
  permit,
  selectedSiteId,
  selectedSitePermitDebtorId,
  onHidePermitUsers,
  permitDebtor
}: PermitUsersTableProps) {
  const {
    isPermitConnectionDrawerOpen,
    setIsPermitConnectionDrawerOpen,
    isUpdateVehicleDrawerOpen,
    setIsUpdateVehicleDrawerOpen
  } = useContext(NewPermitsContext)
  const { authUserId } = useAuthUserContext()

  const { data: users, isLoading: isLoadingUsers } = usePermitUsers(permit?.id)

  const [selectedRow, setSelectedRow] = useState<GridRowId | null>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [currentOpenOptionModal, setCurrentOpenOptionModal] = useState<Option | null>(null)
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const isMenuOpen = Boolean(anchorEl)
  const isReserved = !!permit?.includeSlotPlaceCodes?.length

  const getFormattedText = (figmaText: string, placeholder: string, replacement: string) => {
    return figmaText.replace(placeholder, replacement)
  }

  const nextMonthEndDate = dayjs().endOf('month').add(1, 'month').format('DD MMMM YYYY')
  const rawText = getFigmaText(TextsExtended.permitsTabledescriptionDeactivate)
  const placeholder = navigator.language.startsWith('en') ? '[next month]' : '[nästa månad]'
  const formattedDeactivateUserText = getFormattedText(rawText, placeholder, nextMonthEndDate)
  const formattedDeactivateVehicleText = getFormattedText(
    getFigmaText(TextsExtended.permitsTabledescriptionvehicledeactivate),
    placeholder,
    nextMonthEndDate
  )

  function handleSnackbarClose(event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) {
    if (reason === 'clickaway') {
      return
    }
    setIsSnackbarOpen(false)
  }

  function handleMenuClose() {
    setAnchorEl(null)
  }

  function handleOnOptionClick(option: Option) {
    setAnchorEl(null)

    if (option === Option.EDIT_VEHICLE) {
      setIsUpdateVehicleDrawerOpen(true)
      return
    }

    setCurrentOpenOptionModal(option)
  }

  async function handleDeleteVehicle(plate?: string) {
    if (!plate || !permit || !selectedSiteId || !selectedSitePermitDebtorId) {
      return
    }

    if (permit.fleetVehicles) {
      const clonedPermit = structuredClone(permit)
      if (clonedPermit.fleetVehicles) {
        delete clonedPermit.fleetVehicles[plate]
      }
      await upsertPermit(selectedSiteId, selectedSitePermitDebtorId, permit.id, clonedPermit)
    }

    setCurrentOpenOptionModal(null)
  }

  async function handleTerminate(userId?: string) {
    if (!userId || !permit || !selectedSiteId || !selectedSitePermitDebtorId) {
      return
    }

    // call cloud function removePermitFromUser
    try {
      await terminatePermitConnection({
        permitId: permit.id,
        updatedById: authUserId, // who's updating the permit connection
        userId, // which user with permit connection to terminate
        source: 'my-drifter'
      })
    } catch (error) {
      setIsSnackbarOpen(true)
      captureException(error)
      console.error(error)
    }
    //TODO Ska den stängas här?
    setCurrentOpenOptionModal(null)
  }

  async function handleReactivate(userId?: string) {
    if (!userId || !permit || !selectedSiteId || !selectedSitePermitDebtorId || !permit.userPermitConnections) {
      setCurrentOpenOptionModal(null)

      return
    }
    const clonedPermit = structuredClone(permit)
    const userPermitConnectionToEditIndex = clonedPermit.userPermitConnections?.findIndex(
      (permitUserPermitConnection) => permitUserPermitConnection.userId === userId
    )

    if (
      !clonedPermit.userPermitConnections ||
      userPermitConnectionToEditIndex === -1 ||
      typeof userPermitConnectionToEditIndex !== 'number'
    ) {
      setCurrentOpenOptionModal(null)

      return
    }

    const existingUserId = clonedPermit.userPermitConnections[userPermitConnectionToEditIndex].userId
    clonedPermit.userPermitConnections[userPermitConnectionToEditIndex] = {
      createdAt: dayjs().format(),
      status: UserPermitConnectionSchemaStatus.ACTIVE,
      updatedAt: dayjs().format(),
      updatedBy: 'MyDrifter',
      acceptedAt: dayjs().format(),
      userId: existingUserId
    }
    try {
      await slack(
        `my-drifter: onReactivateUser admin (id: ${authUserId}) called to reactivate user ${userId} for permit ${clonedPermit.id} (${clonedPermit.name}) in site ${clonedPermit.siteId}`,
        SlackWebhooks.OPS_HIKER_USER_PERMIT_CONNECTIONS
      )

      await upsertPermit(selectedSiteId, selectedSitePermitDebtorId, clonedPermit.id, clonedPermit)
      if (existingUserId) {
        // make sure user-permit is not scheduled for deletion
        const deleteRequest: PermitUserDeletionRequest = {
          permitId: clonedPermit.id,
          userId: existingUserId
        }
        await deletePermitUserDeletion(deleteRequest)
      }
    } catch (error) {
      setIsSnackbarOpen(true)
      captureException(error)
      console.error(error)
    }

    setCurrentOpenOptionModal(null)
  }

  function getComputedStatus(status: UserPermitConnectionSchemaStatus, expiryDate?: string) {
    switch (status) {
      case UserPermitConnectionSchemaStatus.ACTIVE:
      case UserPermitConnectionSchemaStatus.INVITED:
        return status

      case UserPermitConnectionSchemaStatus.TERMINATED: {
        if (!expiryDate) {
          return UserPermitConnectionSchemaStatus.TERMINATED
        }
        const today = dayjs()
        const expiry = dayjs(expiryDate)
        const isExpired = expiry.isBefore(today)

        return isExpired ? UserPermitConnectionSchemaStatus.TERMINATED : 'cancelled'
      }
      default:
        return status
    }
  }

  const userMap = {}
  users?.forEach((user) => {
    userMap[user.id] = user
  })
  const usersInfo =
    isLoadingUsers || !permit?.userPermitConnections
      ? []
      : permit.userPermitConnections.reduce<TableInfoRow[]>((acc, curr) => {
          const user = userMap[curr.userId]

          if (user) {
            const { id, name, email, phone, plates } = user
            const computedStatus = getComputedStatus(curr.status, curr.expiryDate)
            const parsedName = name && name !== 'null null' && name !== 'undefined undefined' ? name : ''

            acc.push({
              id,
              name: parsedName,
              email,
              phone,
              status: computedStatus,
              type: PermitConnectionType.User,
              plates,
              expiryDate: curr.expiryDate ? dayjs(curr.expiryDate).format('DD MMMM YYYY') : ''
            })
          }

          return acc
        }, [])

  const mappedFleetInfo = permit?.fleetVehicles
    ? Object.values(permit.fleetVehicles).map(({ plate, description }) => ({
        id: plate,
        name: description,
        email: '',
        phone: '',
        plates: [plate],
        type: PermitConnectionType.Vehicle,
        status: UserPermitConnectionSchemaStatus.ACTIVE //Placeholder to make typescript happy until new fleet vehicles array is done
      }))
    : []

  const rows: TableInfoRow[] = isLoadingUsers ? [] : [...usersInfo, ...mappedFleetInfo]
  const selectedRowData = rows.find((row) => row.id === selectedRow)

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: getFigmaText(TextsExtended.permitsTablepermitUser),
      headerClassName: 'table-head-cell',
      cellClassName: 'table-col-cell',
      flex: 1
    },
    {
      field: 'email',
      headerName: getFigmaText(TextsExtended.permitsTableemail),
      headerClassName: 'table-head-cell',
      cellClassName: 'table-email-cell',
      flex: 1,
      sortable: false
    },
    {
      field: 'phone',
      headerName: getFigmaText(TextsExtended.permitsTablephone),
      headerClassName: 'table-head-cell',
      cellClassName: 'table-col-cell',
      flex: 1,
      sortable: false
    },
    {
      field: 'plates',
      headerName: getFigmaText(TextsExtended.permitsTableregNumbers),
      headerClassName: 'table-head-cell',
      cellClassName: 'table-plates-cell',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return <Plates plates={params.value} />
      }
    },
    {
      field: 'status',
      headerName: getFigmaText(TextsExtended.status),
      headerClassName: 'table-head-cell',
      cellClassName: 'table-col-cell',
      flex: 1,
      renderCell: (params) =>
        params.value ? (
          params.value === 'cancelled' ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: PortalSpacings.x0_5 }}>
              <StatusChip status={params.value} />

              <Tooltip
                title={
                  <>
                    <div>This permit it scheduled to be deactivated by </div>
                    {params.row.expiryDate}
                  </>
                }
                placement="top"
                arrow
                slotProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: PortalColors.surfaceHighlightSecondary,
                      color: PortalColors.textOnHighLightSecondary,
                      boxShadow: Elevation2.boxShadow,
                      ...BodySmall
                    }
                  },
                  arrow: {
                    sx: {
                      color: PortalColors.surfaceHighlightSecondary
                    }
                  }
                }}
              >
                <InfoOutlinedIcon sx={{ color: PortalColors.textPrimary }} />
              </Tooltip>
            </Box>
          ) : (
            <StatusChip status={params.value} />
          )
        ) : null
    },
    {
      field: 'actions',
      headerName: '',
      headerClassName: 'table-head-cell',
      cellClassName: 'table-actions-cell',
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Button
          startIcon={<MoreVertIcon style={{ color: PortalColors.iconWhite }} />}
          sx={actionButtonStyles}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation() // Prevent row selection.
            setAnchorEl(event.currentTarget)
            setSelectedRow(params.id)
          }}
        />
      )
    }
  ]

  function OptionModal({ option }: { option: Option | null }) {
    const isOpen = Boolean(option)
    const onCloseModal = () => setCurrentOpenOptionModal(null)
    switch (option) {
      case Option.TERMINATE:
        return (
          <FullScreenModal
            isOpen={isOpen}
            actionType="deactivate"
            headerText={getFigmaText(TextsExtended.permitsTabletitleDeactivate)}
            titleText={getFigmaText(TextsExtended.permitsTableheadingDeactivate)}
            secondaryText={formattedDeactivateUserText}
            leftButtonText={getFigmaText(TextsExtended.permitsButtonsdeactivate)}
            leftButtonOnClick={() => handleTerminate(selectedRowData?.id)}
            rightButtonText={getFigmaText(Texts.textButtonsManageCancel)}
            rightButtonOnClick={onCloseModal}
          >
            <Box sx={modalContentStyle}>
              <StyledModalRow>
                <p>{permit?.name}</p>
              </StyledModalRow>
              <StyledModalRow>
                <span>{selectedRowData?.name}</span>
              </StyledModalRow>

              <Box sx={{ display: 'flex', gap: PortalSpacings.x2 }}>
                <Plates plates={selectedRowData?.plates} />
              </Box>
            </Box>
          </FullScreenModal>
        )
      case Option.DELETE_VEHICLE:
        return (
          <FullScreenModal
            isOpen={isOpen}
            actionType="deactivate"
            headerText={getFigmaText(TextsExtended.permitsTabletitlevehicledeactivate)}
            titleText={getFigmaText(TextsExtended.permitsTableheadingvehicledeactivate)}
            secondaryText={formattedDeactivateVehicleText}
            leftButtonText={getFigmaText(TextsExtended.permitsButtonsdeactivate)}
            leftButtonOnClick={() => handleDeleteVehicle(selectedRowData?.id)}
            rightButtonText={getFigmaText(Texts.textButtonsManageCancel)}
            rightButtonOnClick={onCloseModal}
          >
            <Box sx={modalContentStyle}>
              <StyledModalRow>
                <p>{permit?.name}</p>
              </StyledModalRow>

              <Box sx={{ display: 'flex', gap: PortalSpacings.x2 }}>
                <Plates plates={selectedRowData?.plates} />
              </Box>
            </Box>
          </FullScreenModal>
        )
      case Option.REACTIVATE:
        return (
          <FullScreenModal
            isOpen={isOpen}
            actionType="reactivate"
            headerText={getFigmaText(TextsExtended.permitsTabletitleuserreactivate)}
            titleText={getFigmaText(TextsExtended.permitsTableheadinguserreactivate)}
            secondaryText={getFigmaText(TextsExtended.permitsTabledescriptionuserreactivate)}
            leftButtonText={getFigmaText(TextsExtended.permitsButtonsreactivate)}
            leftButtonOnClick={() => handleReactivate(selectedRowData?.id)}
            rightButtonText={getFigmaText(Texts.textButtonsManageCancel)}
            rightButtonOnClick={onCloseModal}
          >
            <Box sx={modalContentStyle}>
              <StyledModalRow>
                <p>{permit?.name}</p>
              </StyledModalRow>
              <StyledModalRow>
                <span>{selectedRowData?.name}</span>
              </StyledModalRow>

              <Box sx={{ display: 'flex', gap: PortalSpacings.x2 }}>
                <Plates plates={selectedRowData?.plates} />
              </Box>
            </Box>
          </FullScreenModal>
        )
      default:
        return null
    }
  }

  return (
    <>
      <StyledWrapper>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <StyledPermitViewHeader>
            <Button
              onClick={() => onHidePermitUsers()}
              sx={{
                minWidth: '36px',
                minHeight: '36px',
                backgroundColor: PortalColors.surfaceActionSecondary,
                borderRadius: PortalRadiuses.sm,
                padding: PortalSpacings.x2,
                '& .MuiButton-startIcon': {
                  margin: 0
                },
                '&: hover': {
                  backgroundColor: PortalColors.surfaceActionSecondaryHover
                }
              }}
              startIcon={
                <WestIcon
                  style={{
                    color: PortalColors.iconWhite,
                    height: PortalSpacings.x6,
                    width: PortalSpacings.x6
                  }}
                />
              }
            />
            <div>
              <p>{permitDebtor.name}</p>
              <h3>
                {permit?.name} |{' '}
                <span>
                  {isReserved
                    ? getFigmaText(TextsExtended.permitsButtonsreservedPermit)
                    : getFigmaText(TextsExtended.permitsButtonsfloatingPermit)}
                </span>
              </h3>
            </div>
          </StyledPermitViewHeader>

          <Button
            sx={{
              backgroundColor: PortalColors.surfaceActionPrimary,
              borderRadius: PortalRadiuses.sm,
              color: PortalColors.textOnActionPrimary,
              padding: `${PortalSpacings.x3} ${PortalSpacings.x4}`,
              textTransform: 'none',
              '&: hover': {
                backgroundColor: PortalColors.surfaceActionPrimaryHover
              }
            }}
            onClick={() => setIsPermitConnectionDrawerOpen(true)}
          >
            {getFigmaText(TextsExtended.permitsButtonsaddusers)}
          </Button>
        </Box>

        <DataGrid
          disableColumnMenu
          disableRowSelectionOnClick
          rows={rows}
          columns={columns}
          // checkboxSelection // temporarily outcommented due to lack of time to add functionality atm
          sx={dataGridStyles}
          loading={isLoadingUsers}
        />

        <StyledFooter>
          <p>{`${rows.length} ${getFigmaText(TextsExtended.permitsTableof)} ${permit?.maxNumberOfConcurrentParkedVehicles} ${getFigmaText(TextsExtended.permitsTableassignedPermits)}`}</p>
        </StyledFooter>
      </StyledWrapper>

      <AddPermitConnectionDrawer
        isDrawerOpen={isPermitConnectionDrawerOpen}
        onCloseDrawer={() => setIsPermitConnectionDrawerOpen(false)}
        selectedSiteId={selectedSiteId}
        selectedSitePermitDebtorId={selectedSitePermitDebtorId}
        permit={permit}
      />

      {selectedRowData && (
        <NewUpdateVehicleDrawer
          isDrawerOpen={isUpdateVehicleDrawerOpen}
          onCloseDrawer={() => setIsUpdateVehicleDrawerOpen(false)}
          selectedSiteId={selectedSiteId}
          selectedSitePermitDebtorId={selectedSitePermitDebtorId}
          permit={permit}
          plate={selectedRowData.id}
          description={selectedRowData.name}
        />
      )}

      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        transitionDuration={100}
      >
        {selectedRowData && <MenuRows selectedRowData={selectedRowData} handleOnOptionClick={handleOnOptionClick} />}
      </Menu>
      <OptionModal option={currentOpenOptionModal} />
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          variant="filled"
          icon={<ErrorIcon />}
          sx={{
            width: '100%',
            marginTop: LAYOUT_HEADER_HEIGHT,
            backgroundColor: PortalColors.surfaceError,
            color: PortalColors.textPrimary,
            border: `1px solid ${PortalColors.borderError}`,
            '& .MuiAlert-icon': {
              color: PortalColors.iconError
            }
          }}
        >
          {getFigmaText(TextsExtended.permitsInputfieldsErrorsystem)}
        </Alert>
      </Snackbar>
    </>
  )
}

export default PermitUsersTable

const StyledWrapper = styled.div({
  margin: PortalSpacings.x6,
  display: 'flex',
  flexDirection: 'column',
  gap: PortalSpacings.x6
})

const StyledPermitViewHeader = styled.div({
  marginBottom: PortalSpacings.x6,
  display: 'flex',
  alignItems: 'center',

  gap: PortalSpacings.x4,
  '& > div > p': {
    color: PortalColors.textPrimary,
    ...LabelMediumRegular,
    margin: 0,
    marginBottom: PortalSpacings.x1
  },
  '& > div > h3 > span': {
    color: PortalColors.textSecondary
  },
  '& > div > h3': {
    color: PortalColors.textPrimary,
    ...TitleLarge,
    margin: 0
  }
})

const StyledRegNumbers = styled('div')({
  border: `1px solid ${PortalColors.borderAccessibleHigh}`,
  backgroundColor: PortalColors.surfaceLevelThird,
  color: PortalColors.textPrimary,
  borderRadius: PortalRadiuses.xs,
  ...(LabelMediumStrongUppercase as CSSObject),
  height: '24px',
  width: '64px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& span': {
    padding: PortalSpacings.x2,
    width: '100%',
    textAlign: 'center'
  }
})

const StyledFooter = styled.div({
  display: 'flex',
  gap: PortalSpacings.x6,
  '& > p': {
    color: PortalColors.textPrimary,
    ...LabelMediumRegular
  }
})

const dataGridStyles = {
  '& .table-head-cell': {
    color: PortalColors.textPrimary,
    ...LabelMediumRegularUppercase,
    backgroundColor: PortalColors.surfaceLevelSecond
  },
  '& .table-col-cell': {
    color: PortalColors.textPrimary,
    display: 'flex',
    alignItems: 'center',
    '&:focus-within': {
      outline: 'none'
    }
  },
  '& .table-plates-cell': {
    display: 'flex',
    alignItems: 'center',
    gap: PortalSpacings.x1,
    '&:focus-within': {
      outline: 'none'
    }
  },
  '& .table-email-cell': {
    color: PortalColors.textOnActionTertiary,
    '&:focus-within': {
      outline: 'none'
    }
  },
  '& .table-actions-cell': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '&:focus': {
      outline: 'none'
    },
    '&:focus-within': {
      outline: 'none'
    }
  },
  '& .MuiDataGrid-columnHeaderCheckbox': {
    backgroundColor: PortalColors.surfaceLevelSecond
  },
  '& .MuiTablePagination-toolbar': {
    padding: PortalSpacings.x4,
    backgroundColor: PortalColors.surfaceWhite
  },
  '& .MuiDataGrid-row': {
    backgroundColor: PortalColors.surfaceWhite
  }
}

const actionButtonStyles = {
  backgroundColor: PortalColors.surfaceActionTertiaryPressed,
  minHeight: '36px',
  minWidth: '36px',
  '& .MuiButton-startIcon': {
    margin: 0
  },
  '&:hover': {
    backgroundColor: PortalColors.surfaceActionPrimaryHover
  }
}

const modalContentStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: PortalSpacings.x2,
  marginBotton: PortalSpacings.x6
}

const StyledModalRow = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  '& > p': { color: PortalColors.textPrimary, ...BodyLargeRegular, margin: 0 },
  '& > span': { color: PortalColors.textPrimary, ...TitleLarge }
})
