import { Add, Delete, Edit } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { deleteDoc, doc, updateDoc } from 'firebase/firestore'
import { useState } from 'react'

import { BorderRadiuses } from '@contracts/types/BorderRadixes'
import { Camera } from '@contracts/types/Camera'
import { Site } from '@contracts/types/Site'

import { db } from '@my-drifter/libs/FirebaseOptions'

import CameraForm from './CameraForm'
import { LAYOUT_LEFT_PADDING, LAYOUT_LEFT_XL_PADDING } from './HardcodedSizes'

interface CameraListProps {
  cameras: Camera[]
  site: Site
}

interface CameraListEntry {
  deviceId: string
  id: string
  name: string
  parkId: string // legacy term for siteId
}

export default function CameraList({ cameras, site }: CameraListProps) {
  const [openForm, setOpenForm] = useState(false)
  const [selectedCamera, setSelectedCamera] = useState<Camera | null>(null)

  const handleAddCamera = () => {
    setSelectedCamera(null)
    setOpenForm(true)
  }

  const handleEditCamera = (camera: Camera) => {
    setSelectedCamera(camera)
    setOpenForm(true)
  }

  const updateCameraList = (cameraList: CameraListEntry[]) => {
    return updateDoc(doc(db, 'sites', site.id), { cameraList })
  }

  const handleDeleteCamera = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this camera?')) {
      const cameraList: CameraListEntry[] = site.cameraList ?? []
      const newCameraList = cameraList.filter((entry) => entry.deviceId !== id)

      await Promise.all([deleteDoc(doc(db, 'cameras', id)), updateCameraList(newCameraList)])
    }
  }

  const handleCloseForm = () => {
    setOpenForm(false)
    setSelectedCamera(null)
  }

  const handleFormSuccess = async (camera: Camera) => {
    const cameraList: CameraListEntry[] = site.cameraList ?? []

    const existingEntryIndex = cameraList.findIndex(
      (entry) => (entry.deviceId && entry.deviceId === selectedCamera?.id) ?? camera.id
    )

    if (existingEntryIndex === -1) {
      cameraList.push({
        deviceId: camera.id,
        id: camera.id,
        name: camera.id,
        parkId: site.id
      })
    } else {
      // Only update device ID, so that recordings list in Backoffice (Retool) can be linked to the correct camera
      // Not sure what the "id" and "name" fields are for
      cameraList[existingEntryIndex].deviceId = camera.id
    }

    await updateDoc(doc(db, 'sites', site.id), { cameraList })

    handleCloseForm()
  }

  return (
    <Box sx={{ fontFamily: 'Inter' }}>
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3,
          maxWidth: theme.breakpoints.values.sm
        })}
      >
        <h1 style={{ margin: 0 }}>Cameras</h1>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={handleAddCamera}
            sx={(theme) => ({ [theme.breakpoints.up('lg')]: { display: 'none' } })}
          >
            Add Camera
          </Button>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', gap: 4 }}>
        <TableContainer
          sx={(theme) => ({
            border: `1px solid ${grey[300]}`,
            borderRadius: BorderRadiuses.lightlyRounded + 'px',
            maxWidth: theme.breakpoints.values.sm
          })}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Device ID</TableCell>
                <TableCell>Type</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cameras.map((camera) => (
                <TableRow key={camera.id}>
                  <TableCell>{camera.id}</TableCell>
                  <TableCell sx={{ textTransform: 'capitalize' }}>{camera.type}</TableCell>
                  <TableCell align="right">
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Tooltip title="Edit">
                        <IconButton onClick={() => handleEditCamera(camera)}>
                          <Edit fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton onClick={() => handleDeleteCamera(camera.id)}>
                          <Delete fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={(theme) => ({
            display: 'none',
            flex: 1,
            border: `1px solid ${grey[300]}`,
            borderRadius: BorderRadiuses.lightlyRounded + 'px',
            p: 4,
            maxWidth: 320,
            alignSelf: 'flex-start',
            [theme.breakpoints.up('lg')]: { display: 'block' }
          })}
        >
          <CameraForm site={site} onSave={handleFormSuccess} />
        </Box>
      </Box>

      <Dialog
        open={openForm}
        onClose={handleCloseForm}
        maxWidth="sm"
        sx={(theme) => ({
          '.MuiDialog-container': {
            paddingLeft: `${LAYOUT_LEFT_PADDING}`,
            [theme.breakpoints.up('xl')]: { paddingLeft: `${LAYOUT_LEFT_XL_PADDING}` }
          }
        })}
      >
        <DialogContent>
          <CameraForm
            initialData={selectedCamera || undefined}
            site={site}
            onSave={handleFormSuccess}
            onCancel={handleCloseForm}
          />
        </DialogContent>
      </Dialog>
    </Box>
  )
}
