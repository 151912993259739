import styled from '@emotion/styled'
import CheckIcon from '@mui/icons-material/Check'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'

import { DebtorType } from '@contracts/types/Debtor'

import TextsExtended from '@my-drifter/assets/texts_extended.json'
import { Elevation2 } from '@my-drifter/libs/Elevation'
import { getFigmaText } from '@my-drifter/libs/TextRepository'
import {
  BodySmall,
  BodySmallStrong,
  LabelLargeRegular,
  LabelLargeStrong,
  TitleLarge
} from '@my-drifter/libs/Typography'

import { PortalColors } from './PortalColors'
import { PortalRadiuses } from './PortalRadiuses'
import { PortalSpacings } from './PortalSpacings'

export interface DebtorFormProps {
  children: React.ReactNode
  debtorType: DebtorType
  onSetDebtorType: (debtorType: DebtorType) => void
}

function DebtorForm({ debtorType, onSetDebtorType, children }: DebtorFormProps) {
  const tooltipInfo =
    'En gäldenär är en enhet som har tilldelats tillstånd för att använda parkeringsanläggningen. Gäldenärer kan vara organisationer, individer eller interna grupper, var och en med olika fakturerings- och användningsregler.'
  function handleDebtorTypeChange(_: React.MouseEvent<HTMLElement>, newDebtorType: DebtorType) {
    if (newDebtorType) {
      onSetDebtorType(newDebtorType)
    }
  }
  return (
    <StyledContainer>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: PortalSpacings.x2 }}>
        <StyledHeadline>{getFigmaText(TextsExtended.permitsSetupNewDebtorheader)}</StyledHeadline>
        <Tooltip
          title={tooltipInfo}
          placement="right"
          arrow
          slotProps={{
            tooltip: {
              sx: {
                backgroundColor: PortalColors.surfaceHighlightSecondary,
                color: PortalColors.textOnHighLightSecondary,
                boxShadow: Elevation2.boxShadow,
                ...BodySmall
              }
            },
            arrow: {
              sx: {
                color: PortalColors.surfaceHighlightSecondary
              }
            }
          }}
        >
          <InfoOutlinedIcon sx={{ color: PortalColors.textPrimary }} />
        </Tooltip>
      </Box>

      <div>
        <StyledDebtorTypeLabel id="debtor-type-label">
          {getFigmaText(TextsExtended.permitsSetupNewDebtordebtorType)}
        </StyledDebtorTypeLabel>

        <ToggleButtonGroup
          value={debtorType}
          exclusive
          onChange={handleDebtorTypeChange}
          aria-labelledby="debtor-type-label"
        >
          <ToggleButton value={DebtorType.Company} aria-label="Organization" sx={toggleButtonStyle}>
            {debtorType === DebtorType.Company && <CheckIcon sx={{ fontSize: '16px', mr: 1 }} />}
            {getFigmaText(TextsExtended.permitsButtonsorganisation)}
          </ToggleButton>
          <ToggleButton value={DebtorType.Person} aria-label="Individual" sx={toggleButtonStyle}>
            {debtorType === DebtorType.Person && <CheckIcon sx={{ fontSize: '16px', mr: 1 }} />}
            {getFigmaText(TextsExtended.permitsButtonsindividual)}
          </ToggleButton>
          <ToggleButton value={DebtorType.Group} aria-label="Group" sx={toggleButtonStyle}>
            {debtorType === DebtorType.Group && <CheckIcon sx={{ fontSize: '16px', mr: 1 }} />}
            {getFigmaText(TextsExtended.permitsButtonsgroup)}
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <Box sx={{ backgroundColor: 'white' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>{children}</Box>
      </Box>
    </StyledContainer>
  )
}

export default DebtorForm

const StyledHeadline = styled.h2({
  color: PortalColors.textPrimary,
  margin: 0,
  ...TitleLarge
})

const StyledDebtorTypeLabel = styled.label({
  color: PortalColors.textPrimary,
  margin: 0,
  ...BodySmallStrong,
  marginBottom: PortalSpacings.x2,
  display: 'block'
})

const StyledContainer = styled(Box)({
  backgroundColor: PortalColors.surfaceLevelFirst,
  padding: PortalSpacings.x6,
  display: 'flex',
  flexDirection: 'column',
  gap: PortalSpacings.x6,
  borderRadius: PortalRadiuses.sm,
  margin: PortalSpacings.x6
})

const toggleButtonStyle = {
  backgroundColor: 'white',
  color: PortalColors.textOnActionTertiary,
  ...LabelLargeRegular,
  textTransform: 'none',
  borderRadius: PortalRadiuses.sm,
  width: '140px',
  '&:hover': {
    backgroundColor: PortalColors.surfaceActionTertiaryHover
  },
  '&.Mui-selected': {
    backgroundColor: PortalColors.surfaceActionTertiaryPressed,
    color: PortalColors.textOnActionPrimary,
    ...LabelLargeStrong,
    '&:hover': {
      backgroundColor: PortalColors.surfaceActionPrimaryHover
    }
  }
}
