import styled from '@emotion/styled'
import { Box, TextField } from '@mui/material'
import { FieldErrors } from 'react-hook-form'

import { getInternationalPhoneNumber } from '@pure/libs/PhoneNumberHelper'

import TextsExtended from '@my-drifter/assets/texts_extended.json'
import { Elevation1 } from '@my-drifter/libs/Elevation'
import { getFigmaText } from '@my-drifter/libs/TextRepository'
import { BodyMedium, BodyMediumEmphasis, BodySmallStrong } from '@my-drifter/libs/Typography'

import DebtorForm from './DebtorForm'
import { DebtorFormImplProps, IndFormData } from './DebtorFormContainer'
import { PortalColors } from './PortalColors'
import { PortalRadiuses } from './PortalRadiuses'
import { PortalSpacings } from './PortalSpacings'

interface DebtorIndFormProps extends Omit<DebtorFormImplProps, 'errors'> {
  errors: FieldErrors<IndFormData>
}

function IndividualDebtorForm({ debtorType, onSetDebtorType, register, errors }: DebtorIndFormProps) {
  return (
    <DebtorForm debtorType={debtorType} onSetDebtorType={onSetDebtorType}>
      <StyledInformationContainer>
        <p>{getFigmaText(TextsExtended.permitsSetupNewDebtordescriptionindvdebtor)}</p>
      </StyledInformationContainer>
      <StyledEmphasisText>{getFigmaText(TextsExtended.permitsInputFieldsrequired)}</StyledEmphasisText>
      <Box sx={{ display: 'flex', gap: PortalSpacings.x6 }}>
        <Box sx={{ flex: 1 }}>
          <StyledFormLabel htmlFor="full-name">
            {getFigmaText(TextsExtended.permitsInputFieldsfullnames)}
          </StyledFormLabel>
          <TextField
            id="full-name"
            placeholder="John Doe"
            {...register('name')}
            fullWidth
            sx={textFieldStyle}
            error={!!errors.name}
            helperText={errors.name?.message}
          />
        </Box>

        <Box sx={{ flex: 1 }}>
          <StyledFormLabel htmlFor="ssn">{getFigmaText(TextsExtended.permitsInputFieldsSSN)}</StyledFormLabel>
          <TextField
            id="ssn"
            placeholder="YYYYMMDDXXXX"
            {...register('ssn')}
            fullWidth
            sx={textFieldStyle}
            error={!!errors.ssn}
            helperText={errors.ssn?.message}
            onBlur={(event) => {
              // Remove hyphens and spaces from the input value
              const formattedValue = event.target.value.replace(/[\s-]/g, '')
              event.target.value = formattedValue
            }}
          />
        </Box>
      </Box>

      <Box sx={{ display: 'flex', gap: PortalSpacings.x6 }}>
        <Box sx={{ flex: 1 }}>
          <StyledFormLabel htmlFor="email">{getFigmaText(TextsExtended.permitsInputfieldsemail)}</StyledFormLabel>
          <TextField
            id="email"
            placeholder="name@company.se"
            {...register('email')}
            type="email"
            fullWidth
            sx={textFieldStyle}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        </Box>

        <Box sx={{ flex: 1 }}>
          <StyledFormLabel htmlFor="phone">{getFigmaText(TextsExtended.permitsInputFieldsnumber)}</StyledFormLabel>
          <TextField
            id="phone"
            placeholder="+46 00 000 0000"
            {...register('phone')}
            type="tel"
            fullWidth
            sx={textFieldStyle}
            error={!!errors.phone}
            helperText={errors.phone?.message}
            onBlur={(event) => {
              // Format number to SE format
              const formattedNumber = getInternationalPhoneNumber(event.target.value)
              event.target.value = formattedNumber
            }}
          />
        </Box>
      </Box>
    </DebtorForm>
  )
}

export default IndividualDebtorForm

const StyledFormLabel = styled.label({
  color: PortalColors.textPrimary,
  margin: 0,
  ...BodySmallStrong,
  display: 'block',
  marginBottom: PortalSpacings.x2
})

const StyledInformationContainer = styled.div({
  boxShadow: Elevation1.boxShadow,
  backgroundColor: PortalColors.surfaceInformation,
  border: `1px solid ${PortalColors.borderInformation}`,
  borderRadius: PortalRadiuses.xs,
  ...BodyMedium,
  padding: `${PortalSpacings.x3} ${PortalSpacings.x4}`,

  '& > p': {
    color: PortalColors.textPrimary,
    margin: 0
  }
})

const StyledEmphasisText = styled.p({
  ...BodyMediumEmphasis,
  color: PortalColors.textPrimary
})

const textFieldStyle = {
  '& .MuiOutlinedInput-root': {
    backgroundColor: PortalColors.surfaceLevelFirst, // default background
    '& fieldset': {
      borderColor: PortalColors.borderAccessibleHigh // empty border color
    },
    // When empty and hovered
    '&:hover input:placeholder-shown': {
      backgroundColor: PortalColors.surfaceLevelSecond
    },
    // When focused (whether empty or not)
    '&.Mui-focused fieldset': {
      borderColor: PortalColors.borderStrong
    },
    // When typing (input not empty)
    '& input:not(:placeholder-shown) + fieldset': {
      borderColor: PortalColors.borderBrand
    }
  }
}
