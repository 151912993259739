import styled from '@emotion/styled'
import { memo, useEffect, useRef, useState } from 'react'

import { useIsDesktop } from '@my-drifter/hooks/useIsMobile'
import Images from '@my-drifter/libs/Images'

import { Spacings } from '@web-components/enums/Spacings'

import Box from './Box'
import DropdownSelectedItem from './DropdownSelectedItem'
import DropdownSelectorMenuItem from './DropdownSelectorMenuItem'
import FigmaImage from './FigmaImage'

interface Props {
  options?: {
    label?: string
    value?: string
  }[]
  selected?: string
  onSelect: (value: string) => void
  dropdownType: 'site' | 'siteDebtor' | 'permit' | 'month'
}

const DropdownSelector = ({ options, selected, onSelect, dropdownType }: Props) => {
  const isDesktop = useIsDesktop()
  const [isOpen, setIsOpen] = useState(false)
  const menuRef = useRef<HTMLUListElement>(null)
  const triggerRef = useRef(null)

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  const handleOptionClick = (value: string) => {
    onSelect(value)
    setIsOpen(false)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      if (event.target !== triggerRef.current) {
        setIsOpen(false)
      }
    }
  }

  const isParkingSite = dropdownType === 'site'

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [isOpen])

  return (
    <DropdownContainer>
      {isParkingSite && <FigmaImage imageKey={Images.parking} imageStyle={iconStyle} />}
      <DropdownTrigger left right spacing={Spacings.xs} onClick={handleClick} isDesktop={isDesktop}>
        <Box ref={triggerRef} fullWidth fullHeight direction="row" align="center" justify="space-between">
          <DropdownSelectedItem selected={selected} options={options} dropdownType={dropdownType} />
          {/* <ExpandMore /> */}
          <FigmaImage imageKey={Images.statMinus} imageStyle={iconStyle} />
        </Box>
      </DropdownTrigger>
      <DropdownMenu ref={menuRef} className={isOpen ? '' : 'hidden'} isDesktop={isDesktop}>
        {dropdownType !== 'month'
          ? options
              ?.sort((a, b) => (a?.label ?? '').localeCompare(b?.label ?? ''))
              ?.map((option) => (
                <DropdownSelectorMenuItem key={option?.value} option={option} handleOptionClick={handleOptionClick} />
              ))
          : options?.map((option) => (
              <DropdownSelectorMenuItem key={option?.value} option={option} handleOptionClick={handleOptionClick} />
            ))}
      </DropdownMenu>
    </DropdownContainer>
  )
}

const iconStyle = {}

const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
`

const DropdownTrigger = styled(Box)<{ isDesktop: boolean }>`
  height: 45px;
  cursor: pointer;
  user-select: none;
  width: ${(props) => (props.isDesktop ? '520px' : '100%')};

  &:hover {
    background-color: #f5f5f5;
  }
`

const DropdownMenu = styled.ul<{ isDesktop: boolean }>`
  overflow-y: auto;
  max-height: 50vh;
  left: 0;
  margin: 0;
  top: 100%;
  padding: 0;
  list-style: none;
  border-radius: 4px;
  position: absolute;
  z-index: 999 !important;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: ${(props) => (props.isDesktop ? '520px' : '94%')};

  &.hidden {
    display: none;
  }
`

export default memo(DropdownSelector)
