import { z } from 'zod'

export const SiteSessionLogicSchema = z.strictObject({
  validRecordCount: z.number().optional(), // used in autoEndedJob. If to foew camera events then mark session as FALSE.
  autoCloseGapTimeMinutes: z.number().optional(), // used in autoEndedJob
  validNationalPlateWeightIncreaseRatio: z.number().optional(), // used in the competition to disqualify plates that are not according to national plate system
  startNewSessionGapTimeSeconds: z.number().optional(), // 1 min for <site>, 3 min for garage, its a way to not start new sessions, used when ending sessions in getParkingSession fure function.
  allowRecalculateSessionPaymentOnDemand: z.boolean().optional(), // DPM-1940 recalculate session cost even if insufficient camera events for users paying after the free time has already elapsed
  sessionCloseScheduleTimeInMinutes: z.number().optional(), // how long to wait before ended session will close (would default to 48*60)
  setLastSessionClosedAtForFalseSessions: z.boolean().optional() // set lastSessionClosedAt for vehicles even if it's a False session, note that if enabled the session will not recover when the vehicle exits a gate
})

export type SiteSessionLogic = z.infer<typeof SiteSessionLogicSchema>
