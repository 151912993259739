import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import { memo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { BorderRadiusesPx } from '@contracts/types/BorderRadixes'
import { Permit } from '@contracts/types/Permit'

import { Colors } from '@pure/assets/Colors'

import { upsertPermit } from '@my-drifter/libs/DBApiHandler'
import { getFigmaText } from '@my-drifter/libs/TextRepository'
import Texts from '@my-drifter/libs/Texts'

import { Spacings } from '@web-components/enums/Spacings'

import Box from './Box'
import Button from './Button'
import FigmaText from './FigmaText'
import FullScreenModal from './FullScreenModal'
import RightSideDrawer from './RightSideDrawer'
import TextFieldForm from './TextFieldForm'

type DeleteVehicleDrawerProps = {
  permit?: Permit
  selectedSiteId?: string
  selectedSitePermitDebtorId?: string
  isDrawerOpen: boolean
  setIsDrawerOpen: (isOpen: boolean) => void
  plate: string
  description?: string
}

const UpdateVehicleDrawer = ({
  permit,
  selectedSiteId,
  selectedSitePermitDebtorId,
  isDrawerOpen,
  setIsDrawerOpen,
  plate,
  description
}: DeleteVehicleDrawerProps) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const schema = yup.object().shape({
    plate: yup.string().required(getFigmaText(Texts.textMyPortalVehicleValidation)),
    description: yup.string().optional()
  })

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      plate,
      description
    }
  })

  const onDeletePlateNumber = () => {
    if (!permit || !selectedSiteId || !selectedSitePermitDebtorId) return

    if (permit.fleetVehicles) {
      const clonedPermit = structuredClone(permit)
      if (clonedPermit.fleetVehicles) {
        delete clonedPermit.fleetVehicles[plate]
      }
      upsertPermit(selectedSiteId, selectedSitePermitDebtorId, permit.id, clonedPermit)
    }

    setIsDeleteModalOpen(false)
    setIsDrawerOpen(false)
  }

  const onSubmit = (data: { plate: string; description?: string }) => {
    const { plate, description } = data
    if (!permit || !selectedSiteId || !selectedSitePermitDebtorId) return

    const fleetVehicles = permit.fleetVehicles ?? {}

    fleetVehicles[plate] = { plate: plate.trim().replace(/\s/g, '').toUpperCase(), description }
    const updatedPermit = {
      ...permit,
      fleetVehicles
    }

    upsertPermit(selectedSiteId, selectedSitePermitDebtorId, permit.id, updatedPermit)

    setIsDrawerOpen(false)
  }

  return (
    <>
      <RightSideDrawer isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen}>
        <Box fullWidth>
          <FigmaText textKey={Texts.textMyPortalEditVehicleHeader} />
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <Box fullWidth gap={Spacings.xs}>
              <FigmaText textKey={Texts.textMyPortalPermitTitlePlateNumber} />
              <Controller
                control={control}
                name="plate"
                render={({ field, fieldState }) => (
                  <TextFieldForm
                    {...field}
                    value={field.value}
                    fullWidth
                    fieldState={fieldState}
                    autoFocus
                    placeholder={plate}
                  />
                )}
              />
              <Controller
                control={control}
                name="description"
                render={({ field, fieldState }) => (
                  <TextFieldForm
                    {...field}
                    value={field.value}
                    fullWidth
                    fieldState={fieldState}
                    autoFocus
                    placeholder={description}
                  />
                )}
              />
            </Box>
            <Button fullWidth type="submit" textKey={Texts.textButtonsSaveButton} />
          </StyledForm>
          <RemoveVehicleButton fullWidth align="center" top onClick={() => setIsDeleteModalOpen(true)}>
            <FigmaText textKey={Texts.textMyPortalRemoveVehiclePermit} />
          </RemoveVehicleButton>
        </Box>
      </RightSideDrawer>
      <FullScreenModal
        isOpen={isDeleteModalOpen}
        titleText={getFigmaText(Texts.textNotificationsNotificationVerifyRemovalOfVehcileFromPermit)}
        leftButtonText={getFigmaText(Texts.textButtonsCtaVerifyAndRemoveVehicle)}
        leftButtonOnClick={onDeletePlateNumber}
        rightButtonText={getFigmaText(Texts.textButtonsManageCancel)}
        rightButtonOnClick={() => setIsDeleteModalOpen(false)}
      />
    </>
  )
}

const StyledForm = styled.form`
  display: flex;
  gap: ${Spacings.s};
  flex-direction: column;
  margin-top: ${Spacings.m};
`

const ErrorBox = styled(Box)`
  margin-top: ${Spacings.s};
  border: 1px solid ${Colors.yellow1};
  background-color: ${Colors.yellow4};
  border-radius: ${BorderRadiusesPx.minimum};
`

const RemoveVehicleButton = styled(Box)`
  color: red;
  cursor: pointer;
  text-decoration: underline;

  span {
    color: red !important;
  }
`

export default memo(UpdateVehicleDrawer)
