import { useContext } from 'react'

import DebtorFormContainer from '@my-drifter/components/DebtorFormContainer'
import Layout from '@my-drifter/components/Layout'
import { PermitsContext } from '@my-drifter/store/PermitsProvider'

import NotAuthorizedScreen from './NotAuthorizedScreen'

function CreateDebtorScreen() {
  const {
    isRoot,
    isLoadingUser,
    selectedSiteId,
    setSelectedSiteId,
    selectedSitePermitDebtorId,
    setSelectedSitePermitDebtorId,
    selectedSitePermitDebtorPermitId,
    setSelectedSitePermitDebtorPermitId,
    selectedPermit,
    sitePermitDebtors,
    sitePermitDebtorOptions,
    sitePermitDebtorPermitOptions,
    isAnyDrawerOpen,
    sitePermitDebtorsPermits
  } = useContext(PermitsContext)

  if (!isRoot && !isLoadingUser) return <NotAuthorizedScreen />

  return (
    <Layout
      selectedSiteId={selectedSiteId}
      setSelectedSiteId={setSelectedSiteId}
      isDrawerOpen={isAnyDrawerOpen}
      isLoading={isLoadingUser}
    >
      {selectedSiteId && <DebtorFormContainer siteId={selectedSiteId} />}
    </Layout>
  )
}

export default CreateDebtorScreen
