import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

dayjs.extend(isSameOrBefore)

/**
 * Splits an event into segments based on hour boundaries
 */
export function splitEventByHourInterval<T>(
  event: T & {
    startedAt: string
    endedAt: string
  },
  hourBoundaries: number[] = [0, 6, 12, 18, 24]
): Array<typeof event> {
  const start = dayjs(event.startedAt)
  const end = dayjs(event.endedAt)
  const results: (typeof event)[] = []

  // Normalize hour boundaries (24 -> 0) and ensure they're sorted
  const boundaries = [...new Set(hourBoundaries.map((h) => (h === 24 ? 0 : h)))].sort((a, b) => a - b)

  // Create a list of all boundary timestamps that fall within our event
  const splitPoints: dayjs.Dayjs[] = []

  // Start from the day of the event start, look at each boundary
  let currentDay = start.startOf('day')
  const lastDay = end.startOf('day').add(1, 'day') // Go one day past end to catch last segments

  while (currentDay.isSameOrBefore(lastDay)) {
    for (const hour of boundaries) {
      const point = currentDay.hour(hour).minute(0).second(0).millisecond(0)

      // Only add points that are after start and before or at end
      if ((point.isAfter(start) || point.isSame(start)) && (point.isBefore(end) || point.isSame(end))) {
        splitPoints.push(point)
      }
    }
    currentDay = currentDay.add(1, 'day')
  }

  // Sort split points chronologically
  splitPoints.sort((a, b) => a.valueOf() - b.valueOf())

  // If no split points, just return the original event
  if (splitPoints.length === 0) {
    return [event]
  }

  // Create segments between split points
  let segmentStart = start

  for (const point of splitPoints) {
    // Create a segment from current segmentStart to this point
    results.push({
      ...event,
      startedAt: segmentStart.utc().format(),
      endedAt: point.utc().format()
    })

    // Update segmentStart for the next iteration
    segmentStart = point
  }

  // Add the final segment from the last split point to the end
  if (segmentStart.isBefore(end)) {
    results.push({
      ...event,
      startedAt: segmentStart.utc().format(),
      endedAt: end.utc().format()
    })
  }

  return results.filter((r) => dayjs(r.startedAt).isBefore(dayjs(r.endedAt)))
}

export function getIntervalIndexForHour(hour: number, intervals: number[] = [0, 6, 12, 18]) {
  // walk up to last index and until we find the correct interval

  for (let i = 0; i < intervals.length; i++) {
    const interval = intervals[i]
    const nextInterval = intervals[i + 1] ?? 24

    if (hour >= interval && hour < nextInterval) {
      return i
    }
  }

  return -1
}

if (import.meta.vitest) {
  const { test, expect } = import.meta.vitest

  test('getIntervalIndexForHour', () => {
    expect(getIntervalIndexForHour(0)).toBe(0)
    expect(getIntervalIndexForHour(1)).toBe(0)
    expect(getIntervalIndexForHour(2)).toBe(0)
    expect(getIntervalIndexForHour(3)).toBe(0)
    expect(getIntervalIndexForHour(4)).toBe(0)
    expect(getIntervalIndexForHour(5)).toBe(0)
    expect(getIntervalIndexForHour(6)).toBe(1)
    expect(getIntervalIndexForHour(7)).toBe(1)
    expect(getIntervalIndexForHour(8)).toBe(1)
    expect(getIntervalIndexForHour(9)).toBe(1)
    expect(getIntervalIndexForHour(10)).toBe(1)
    expect(getIntervalIndexForHour(11)).toBe(1)
    expect(getIntervalIndexForHour(12)).toBe(2)
    expect(getIntervalIndexForHour(13)).toBe(2)
    expect(getIntervalIndexForHour(14)).toBe(2)
    expect(getIntervalIndexForHour(15)).toBe(2)
    expect(getIntervalIndexForHour(16)).toBe(2)
    expect(getIntervalIndexForHour(17)).toBe(2)
    expect(getIntervalIndexForHour(18)).toBe(3)
    expect(getIntervalIndexForHour(19)).toBe(3)
    expect(getIntervalIndexForHour(20)).toBe(3)
    expect(getIntervalIndexForHour(21)).toBe(3)
    expect(getIntervalIndexForHour(22)).toBe(3)
    expect(getIntervalIndexForHour(23)).toBe(3)
  })
}
