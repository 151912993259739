import { DebtorType } from '@contracts/types/Debtor'
import { BillectaDebtorExternal, PermitDebtor, UpsertPermitDebtorRequest } from '@contracts/types/PermitDebtor'

import BillingInformationDetails from '@my-drifter/components/BillingInformationDetails'
import BillingInformationForm from '@my-drifter/components/BillingInformationForm'

interface DebtorBillingContainerProps {
  selectedPermitDebtor: PermitDebtor
  billectaDebtorData: BillectaDebtorExternal | null
  onSaveBillingInfo: (req: UpsertPermitDebtorRequest) => void
}

export default function DebtorBillingContainer({
  selectedPermitDebtor,
  billectaDebtorData,
  onSaveBillingInfo
}: DebtorBillingContainerProps) {
  if (selectedPermitDebtor.type === DebtorType.Group) {
    return null
  }
  if (!selectedPermitDebtor.billectaDebtorId) {
    return (
      <BillingInformationForm
        debtorType={selectedPermitDebtor.type}
        permitDebtor={selectedPermitDebtor}
        onSaveBillingInfo={onSaveBillingInfo}
      />
    )
  }

  if (billectaDebtorData) {
    return (
      <BillingInformationDetails
        invoiceDeliveryMethod={billectaDebtorData.invoiceDeliveryMethod}
        contactName={billectaDebtorData.contactName}
        email={billectaDebtorData.email}
        adressLine1={billectaDebtorData.address?.name}
        adressLine2={billectaDebtorData.address?.name2}
        zipCode={billectaDebtorData.address?.zip_code}
        city={billectaDebtorData.address?.city}
      />
    )
  }

  return null
}
