const FontSans = 'Poppins'

export const BodySmall = {
  fontFamily: FontSans,
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '16px',
  letterSpacing: '0.4px'
}

export const BodySmallStrong = { ...BodySmall, fontWeight: 600 }

export const BodyMedium = {
  fontFamily: FontSans,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0.25px'
}

export const BodyMediumStrong = {
  ...BodyMedium,
  fontWeight: 600
}

export const BodyLargeRegular = {
  fontFamily: FontSans,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0.5px'
}

export const BodyMediumEmphasis = {
  ...BodyMedium,
  fontStyle: 'italic'
}
export const HeadlineMediumStrong = {
  fontFamily: FontSans,
  fontSize: '28px',
  fontWeight: 600,
  lineHeight: '36px',
  letterSpacing: '0px'
}

export const LabelMedium = {
  fontFamily: FontSans,
  fontSize: '12px',
  lineHeight: '16px',
  letterSpacing: '0.5px'
}

export const LabelMediumRegular = {
  ...LabelMedium,
  fontWeight: '500'
}

export const LabelMediumRegularUppercase = {
  ...LabelMedium,
  textTransform: 'uppercase'
}

export const LabelMediumStrong = {
  ...LabelMedium,
  fontWeight: 700
}

export const LabelMediumStrongUppercase = {
  ...LabelMediumStrong,
  textTransform: 'uppercase'
}

export const LabelLargeRegular = {
  fontFamily: FontSans,
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.1px'
}

export const LabelLargeStrong = {
  ...LabelLargeRegular,
  fontSize: '14px',
  fontWeight: 500
}
export const TitleSmall = {
  fontFamily: FontSans,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0.1px'
}

export const TitleSmallStrong = {
  ...TitleSmall,
  fontWeight: 600
}

export const TitleMedium = {
  fontFamily: FontSans,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px'
}

export const TitleMediumRegular = {
  ...TitleMedium,
  fontWeight: 500
}

export const TitleMediumStrong = {
  ...TitleMedium,
  fontWeight: 600
}

export const TitleLarge = {
  fontFamily: FontSans,
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: '28px',
  letterSpacing: '0px'
}

export const TitleLargeStrong = {
  ...TitleLarge,
  fontWeight: 600
}
