import { Box } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { useMemo } from 'react'

import { Site } from '@contracts/types/Site'

import { groupOccupancyByDominantType, groupOccupancyByHourInterval } from '@my-drifter/libs/OccupancyHelper'
import { getFeeForStartAndEndTime } from '@my-drifter/libs/PricingHelper'
import { BodyLargeRegular, LabelLargeRegular, LabelMediumRegular } from '@my-drifter/libs/Typography'
import { SlotOccupancy } from '@my-drifter/screens/SessionOverview'

import { PortalColors } from './PortalColors'
import HourlyBarChart from './SessionHourlyBarChart'

interface DailyParkingMetricsProps {
  occupancy: SlotOccupancy[]
  slotCount: number
  date: Dayjs
  isLoading: boolean
  site: Site
}

const hourIntervals = Array.from({ length: 25 }, (_, i) => i)

export default function DailyParkingMetrics({ occupancy, site, slotCount, date, isLoading }: DailyParkingMetricsProps) {
  const occupancyByHour = useMemo(
    () => groupOccupancyByHourInterval(occupancy, date.startOf('day').format(), hourIntervals),
    [occupancy, date]
  )

  const occupancyByHourByDominantType = useMemo(
    () =>
      occupancyByHour.map((group, i) => ({
        hour: i,
        ...groupOccupancyByDominantType(group)
      })),
    [occupancyByHour]
  )

  const totalOccupancyByType = groupOccupancyByDominantType(occupancy)
  const occupancyCount = occupancy.length

  const sitePricing = site.segments[0].pricing.parking
  const siteOffense = site.segments[0].pricing.offense

  const { totalDuration, approximateEarnings } = occupancyByHour.flat().reduce(
    (acc, slot) => {
      const { totalDuration, parkingDuration, parkingFee, offenseFee } = slot.events.reduce(
        (acc, event) => {
          const duration = dayjs(event.endedAt).diff(dayjs(event.startedAt), 'seconds')

          switch (event.type) {
            case 'parking':
              acc.parkingDuration = acc.parkingDuration + duration
              acc.parkingFee += getFeeForStartAndEndTime(event.startedAt, event.endedAt, sitePricing, site)

              break
            case 'offense': {
              if (!event.offenseType) {
                break
              }

              const offensePricing = siteOffense?.find((offense) => offense.offenseType === event.offenseType)

              if (!offensePricing) {
                break
              }

              acc.offenseFee =
                acc.offenseFee + getFeeForStartAndEndTime(event.startedAt, event.endedAt, offensePricing, site)

              break
            }
            default:
              break
          }

          acc.totalDuration = acc.totalDuration + duration

          return acc
        },
        {
          totalDuration: 0,
          parkingDuration: 0,
          offenseFee: 0,
          parkingFee: 0
        }
      )

      acc.totalDuration = acc.totalDuration + totalDuration
      acc.approximateEarnings = acc.approximateEarnings + parkingFee + offenseFee

      return acc
    },
    {
      approximateEarnings: 0,
      totalDuration: 0
    }
  )

  const averageParkingTime = dayjs.duration(totalDuration / occupancyCount, 'seconds').format('H[h] m[m] s[s]')
  const roundedApproximateEarnings = Math.round(approximateEarnings * 100) / 100
  const roundedApproximateEarningsInclVat = Math.round(approximateEarnings * 1.25 * 100) / 100

  const keyMetrics = [
    {
      label: 'Highest occupancy',
      value: Math.round((occupancyCount / slotCount) * 100) + '%',
      subValue: `${occupancyCount} of ${slotCount} total slots parked`
    },
    {
      label: 'Approximate earnings',
      value: `${roundedApproximateEarnings}* kr`,
      subValue: `${roundedApproximateEarningsInclVat}* kr (incl. VAT)`
    },
    {
      label: 'Average time parked',
      value: averageParkingTime,
      // TODO: Can be either above or below the site average
      subValue: '2 min above the site average'
    }
  ]

  const typeMetrics = [
    {
      label: 'Free parking',
      subLabel: '2 hours allowed',
      value: Math.round((totalOccupancyByType.free / occupancyCount) * 100) + '%',
      subValue: `${totalOccupancyByType.free} parked of the total ${occupancyCount}`
    },
    {
      label: 'Revenue generating parking',
      value: Math.round((totalOccupancyByType.parking / occupancyCount) * 100) + '%',
      subValue: `${totalOccupancyByType.parking} parked of the total ${occupancyCount}`
    },
    {
      label: 'Parking offenses',
      value: Math.round((totalOccupancyByType.offense / occupancyCount) * 100) + '%',
      subValue: `${totalOccupancyByType.offense} parked of the total ${occupancyCount}`
    },
    {
      label: 'Parking with permit',
      subLabel: '12 of 15 were used',
      value: Math.round((totalOccupancyByType.permit / occupancyCount) * 100) + '%',
      subValue: `${totalOccupancyByType.permit} parked of the total ${occupancyCount}`
    }
  ]

  return (
    <Box sx={{ fontFamily: 'Poppins' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'stretch',
          alignItems: 'center',
          gap: 3,
          padding: 3,
          backgroundColor: PortalColors.surfaceHighlightSecondary
        }}
      >
        {keyMetrics.map((metric) => (
          <Box
            key={metric.label}
            sx={{
              flex: 1,
              backgroundColor: 'rgba(0 0 0 / 30%)',
              borderRadius: 1,
              p: 4,

              color: PortalColors.textOnHighLightSecondary
            }}
          >
            <Box
              sx={{
                ...BodyLargeRegular,
                fontSize: 14,
                fontWeight: 500,
                color: PortalColors.textOnNavigationSecondary,
                mb: 2
              }}
            >
              {metric.label}
            </Box>
            <Box sx={{ fontSize: 44, fontWeight: 600, lineHeight: '52px' }}>{metric.value}</Box>
            <Box sx={{ ...LabelMediumRegular, color: PortalColors.textOnNavigation }}>{metric.subValue}</Box>
          </Box>
        ))}
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'stretch',
          gap: 3,
          p: 2,
          backgroundColor: PortalColors.surfaceLevelSecond
        }}
      >
        {typeMetrics.map((metric) => (
          <Box
            key={metric.label}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              borderRadius: 2,
              p: 2,
              backgroundColor: PortalColors.surfaceLevelThird
            }}
          >
            <Box sx={{ ...LabelLargeRegular, fontSize: 14, color: PortalColors.textPrimary }}>{metric.label}</Box>
            {metric.subLabel && (
              <Box
                sx={{
                  ...LabelMediumRegular,
                  color: PortalColors.textSecondary,
                  mt: 1,
                  mb: 3,
                  py: 0.5,
                  px: 1,
                  borderRadius: 0.5,
                  backgroundColor: PortalColors.surfaceLevelSecond,
                  width: 'fit-content'
                }}
              >
                {metric.subLabel}
              </Box>
            )}
            <Box sx={{ marginTop: 'auto' }}>
              <Box sx={{ fontSize: 44, lineHeight: '52px' }}>{metric.value}</Box>
              <Box sx={{ fontSize: 12, color: PortalColors.textSecondary }}>{metric.subValue}</Box>
            </Box>
          </Box>
        ))}
      </Box>
      <Box sx={{ width: '100%' }}>
        <HourlyBarChart
          sessions={[]}
          slotCount={slotCount}
          data={occupancyByHourByDominantType}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  )
}
