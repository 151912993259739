import styled from '@emotion/styled'

import { PortalColors } from './PortalColors'

interface TitleProps {
  size?: 'small' | 'medium' | 'large'
  weight?: 'light' | 'regular' | 'strong'
}

const DEFAULT_SIZE = 'large'
const DEFAULT_WEIGHT = 'regular'

const fontSize = {
  small: '16px',
  medium: '16px',
  large: '24px'
}

const fontWeight = {
  light: '300',
  regular: '400',
  strong: '600'
}

const lineHeight = {
  small: '16px',
  medium: '20px',
  large: '28px'
}

const Title = styled.h2`
  font-family: Poppins;
  margin: 0;
  font-size: ${({ size = DEFAULT_SIZE }: TitleProps) => fontSize[size]};
  line-height: ${({ size = DEFAULT_SIZE }: TitleProps) => lineHeight[size]};
  font-weight: ${({ weight = DEFAULT_WEIGHT }: TitleProps) => fontWeight[weight]};
  color: ${PortalColors.textPrimary};
`

export default Title
