import { BarChart } from '@mui/x-charts/BarChart'
import dayjs from 'dayjs'

import { ParkingSessionView } from '@my-drifter/screens/SessionOverview'

dayjs.updateLocale('en', {
  weekStart: 1
})

interface HourlyBarChartData {
  hour: number
  free: number
  parking: number
  permit: number
  offense: number
  [key: string]: number
}

interface HourlyBarChartProps {
  sessions: ParkingSessionView[]
  data: HourlyBarChartData[]
  slotCount: number
  isLoading: boolean
}

/** NOTES:
 * - We can't use the total price for sessions, since the total price is calculated based on the session's start and end time.
 *    - Moreover: sessions might overlap midnight and span multiple days. So this chart is only useful for speculative purposes. Real earnings should be viewed in the Revenue/Earnings screen.
 *    - So we probably need to calculate the total price based on the hourly rate and session count at that time.
 * - Some sessions might only be a few minutes long, so we might need to consider that when calculating the hourly rate.
 */
export default function HourlyBarChart({ data, slotCount, isLoading }: HourlyBarChartProps) {
  const xLabels = Array.from({ length: 24 }, (_, i) => `${i}:00`)

  return (
    <BarChart
      loading={isLoading}
      dataset={data}
      series={[
        {
          dataKey: 'free',
          label: 'Gratisparkering',
          color: '#6D63F1',
          stack: 'count'
        },
        {
          dataKey: 'parking',
          label: 'Betalparkering',
          color: '#C4C0FF',
          stack: 'count'
        },
        {
          dataKey: 'permit',
          label: 'Parkering med tillstånd',
          color: '#130068',
          stack: 'count'
        },
        {
          dataKey: 'offense',
          label: 'Parkeringsöverträdelser',
          color: '#D34444',
          stack: 'count'
        }
      ]}
      xAxis={[
        {
          dataKey: 'hour',
          scaleType: 'band',
          tickLabelStyle: {
            angle: -45,
            textAnchor: 'end'
          },
          // @ts-ignore - This is a bug in the library, the property exists
          categoryGapRatio: 0.4,
          valueFormatter: (value) => xLabels[value]
        }
      ]}
      yAxis={[
        {
          max: slotCount,
          disableTicks: false,
          tickMaxStep: 10
        }
      ]}
      height={547}
      // margin={{ top: 20, right: 30, bottom: 70, left: 60 }}
      slotProps={{
        legend: {
          hidden: true
        }
      }}
      borderRadius={4}
      grid={{
        horizontal: true
      }}
    />
  )
}
