import styled from '@emotion/styled'
import WestIcon from '@mui/icons-material/West'
import { Alert, Box, Button, Snackbar, SnackbarCloseReason } from '@mui/material'
import { captureException } from '@sentry/react'
import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { UpsertPermitRequest } from '@contracts/types/Permit'

import TextsExtended from '@my-drifter/assets/texts_extended.json'
import { LAYOUT_HEADER_HEIGHT } from '@my-drifter/components/HardcodedSizes'
import Layout from '@my-drifter/components/Layout'
import LoadingModal from '@my-drifter/components/LoadingModal'
import PermitForm, { PermitFormState } from '@my-drifter/components/PermitForm'
import { PortalColors } from '@my-drifter/components/PortalColors'
import { PortalRadiuses } from '@my-drifter/components/PortalRadiuses'
import { PortalSpacings } from '@my-drifter/components/PortalSpacings'
import { useSite } from '@my-drifter/hooks/useSite'
import { useSitePermitTemplates } from '@my-drifter/hooks/useSitePermitTemplates'
import { upsertPermit } from '@my-drifter/libs/CloudFunctionsApiHandler'
import NavigationPaths from '@my-drifter/libs/NavigationPaths'
import { getFigmaText } from '@my-drifter/libs/TextRepository'
import { LabelLargeRegular, TitleLarge } from '@my-drifter/libs/Typography'
import { PermitsContext } from '@my-drifter/store/PermitsProvider'

import NotAuthorizedScreen from './NotAuthorizedScreen'

function CreatePermitScreen() {
  const { isRoot, isLoadingUser, selectedSiteId, setSelectedSiteId, sitePermitDebtors, isAnyDrawerOpen } =
    useContext(PermitsContext)

  const { data: site, isLoading: isLoadingSite } = useSite(selectedSiteId)

  const { data: sitePermitTemplates } = useSitePermitTemplates(selectedSiteId)
  const hasTemplates = sitePermitTemplates && sitePermitTemplates.length > 0
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const [searchParams] = useSearchParams()
  const debtorId = searchParams.get('debtorId')
  const selectedPermitDebtor = sitePermitDebtors?.find((d) => d.id === debtorId)

  if (!isRoot && !isLoadingUser) return <NotAuthorizedScreen />

  const navigate = useNavigate()
  const cancel = () => {
    navigate(NavigationPaths.NewPermits)
  }

  const onSubmit = async (data: UpsertPermitRequest) => {
    try {
      data.id = self.crypto.randomUUID()
      await upsertPermit(data)
      navigate(NavigationPaths.NewPermits)
    } catch (error) {
      setIsSnackbarOpen(true)
      captureException(error)
      console.error(error)
    }
  }

  function handleSnackbarClose(event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) {
    if (reason === 'clickaway') {
      return
    }
    setIsSnackbarOpen(false)
  }

  const defaultPermitTemplate = sitePermitTemplates?.at(0) ?? {}

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { isSubmitting }
  } = useForm<PermitFormState>({
    defaultValues: {
      ...defaultPermitTemplate,
      price: 0,
      maxNumberOfConcurrentParkedVehicles: 0,
      featureToggleMaxConcurrentPermit: true,
      includeSlotPlaceCodes: [],
      permitDebtorId: selectedPermitDebtor?.id
    }
  })

  return (
    <Layout
      selectedSiteId={selectedSiteId}
      setSelectedSiteId={setSelectedSiteId}
      isDrawerOpen={isAnyDrawerOpen}
      isLoading={isLoadingUser}
    >
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%', marginTop: LAYOUT_HEADER_HEIGHT }}
        >
          {getFigmaText(TextsExtended.permitsInputfieldsErrorsystem)}
        </Alert>
      </Snackbar>

      {hasTemplates && selectedPermitDebtor && site && (
        <Box sx={{ paddingBottom: '80px' }}>
          <StyledHeader>
            <Button
              onClick={() => cancel()}
              sx={{
                minWidth: '36px',
                minHeight: '36px',
                backgroundColor: PortalColors.surfaceActionSecondary,
                borderRadius: PortalRadiuses.xs,
                padding: PortalSpacings.x2,
                '& .MuiButton-startIcon': {
                  margin: 0
                },
                '&: hover': {
                  backgroundColor: PortalColors.surfaceActionSecondaryHover
                }
              }}
              startIcon={
                <WestIcon
                  style={{ color: PortalColors.iconWhite, height: PortalSpacings.x6, width: PortalSpacings.x6 }}
                />
              }
            />
            <div>
              <h3>{selectedPermitDebtor.name}</h3>
            </div>
          </StyledHeader>
          <PermitForm
            site={site}
            permitDebtor={selectedPermitDebtor}
            permitTemplates={sitePermitTemplates}
            watch={watch}
            setValue={setValue}
            control={control}
            reset={reset}
          />
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <StyledFooter>
              <Button
                onClick={(e) => cancel()}
                sx={{
                  color: PortalColors.textOnActionTertiary,
                  backgroundColor: PortalColors.surfaceLevelFirst,
                  textTransform: 'none',
                  borderRadius: PortalRadiuses.sm,
                  ...LabelLargeRegular,
                  '&:hover': {
                    backgroundColor: PortalColors.surfaceActionTertiaryHover
                  },
                  '&.Mui-selected': {
                    backgroundColor: PortalColors.surfaceActionTertiaryPressed
                  }
                }}
              >
                {getFigmaText(TextsExtended.permitsButtonscancel)}
              </Button>
              <Button
                type="submit"
                sx={{
                  backgroundColor: PortalColors.surfaceActionSecondary,
                  color: PortalColors.textOnActionSecondary,
                  textTransform: 'none',
                  borderRadius: PortalRadiuses.sm,
                  padding: `${PortalSpacings.x3} ${PortalSpacings.x4} `,
                  ...LabelLargeRegular,
                  '&:hover': {
                    backgroundColor: PortalColors.surfaceActionSecondaryHover
                  },
                  '&.Mui-selected': {
                    backgroundColor: PortalColors.surfaceActionTertiaryPressed,
                    color: PortalColors.textOnActionTertiaryPressed
                  }
                }}
              >
                {getFigmaText(TextsExtended.permitsButtonssavepermit)}
              </Button>
            </StyledFooter>
            <LoadingModal open={isSubmitting} title={getFigmaText(TextsExtended.permitsSetupNewPermitloader)} />
          </Box>
        </Box>
      )}
    </Layout>
  )
}

export default CreatePermitScreen

const StyledHeader = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: PortalSpacings.x4,
  margin: `${PortalSpacings.x6}`,
  '& > div > h3': {
    color: PortalColors.textPrimary,
    ...TitleLarge,
    margin: 0
  }
})

const StyledFooter = styled.div({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: PortalColors.surfaceLevelFirst,
  borderTop: `1px solid ${PortalColors.borderDecorativeLow}`,
  padding: PortalSpacings.x4,
  display: 'flex',
  justifyContent: 'flex-end',
  gap: PortalSpacings.x4
})
