import { CameraEvent } from '@contracts/types/CameraEvent'
import { OffenseType } from '@contracts/types/OffenseType'
import { SessionEventType } from '@contracts/types/ParkingSessionEventTypeInfo'
import { Permit } from '@contracts/types/Permit'
import { Payment, SessionEventName } from '@contracts/types/Session'
import { SessionPricingItem } from '@contracts/types/Site'
import { SiteSessionLogic } from '@contracts/types/SiteSessionLogic'

import { HikerUser } from './HikerUser'
export enum ParkingSessionType {
  GUEST = 'guest'
}
export type ParkingSession = {
  id: string // trivial
  startedAt: string // trivial
  plate: string // trivial
  events: ParkingSessionEvent[]
  slotEvents: { [moment: string]: CameraEvent } // The relevant slot camera events that is used to determine what happened on a session (subset of the real slot camera events)
  gateEvents: { [moment: string]: CameraEvent } // The relevant gate camera events that is used to determine what happened on a session (subset of the real slot gate events)
  siteId: string // trivial
  currentPayment?: Payment // Payment object that hold the current payment state of the active session. This object should be considered in active sessions (before real payment is done)
  lastCameraEvent: CameraEvent // trivial
  endedAt?: string | null // trivial
  lastSessionClosedAt?: string // trivial
  sessionLogic?: Required<SiteSessionLogic> // trivial optional because of old sessions, can probabaly be migrated to required in future
  userId?: string // trivial
  user?: HikerUser // trivial
  type?: ParkingSessionType | undefined // Used to mark session as a guest session
  freeTimeUsed?: ParkingSessionFreeTimeUsed // How much free time was used on old sessions. Used to penalize free time usage on this session.
}
export type ParkingSessionFreeTimeUsed = {
  [sessionId: string]: {
    [segmentId: string]: {
      freeMinutesUsed: number
    }
  }
}
export type ParkingSessionEventBase = {
  name: SessionEventName // Models what hapepened on the session.
  type?: SessionEventType // trivial 'offense' | 'permit' | 'free' | 'parking' etc
  description?: string // trivial
  startedAt?: string // trivial
  endedAt?: string // trivial
  pricingItems?: SessionPricingItem[] // Data that show how the session is priced. I,e one item per "parking rule", i.e 8-17 Mon-Fri, 11-15 on saturdays are two rules.
  createdAt?: string // trivial
  gateDeviceId?: number // Used to show what gate the event happened on
  permit?: Partial<Permit> // Show what permit that triggered the permitevent.
  segmentId?: string // trivial
}
export type ParkingSessionEventParking = ParkingSessionEventBase & {
  place_code?: string // Camera Event place code that triggered the parking event
}
export type ParkingSessionEventOffense = ParkingSessionEventBase & {
  offenseType: OffenseType // trivial
  place_code: string // Camera Event place code that triggered the parking event
}
export type ParkingSessionEvent = ParkingSessionEventParking | ParkingSessionEventOffense

export function isOffenseEvent(event: ParkingSessionEvent): event is ParkingSessionEventOffense {
  return Boolean((event as ParkingSessionEventOffense).offenseType)
}
