import { collection } from 'firebase/firestore'

import Collections from '@contracts/enums/Collections'
import { PermitTemplate } from '@contracts/types/PermitTemplate'

import { useFirestoreQuery } from '@web-js/hooks/useFirestoreQuery'

import { db } from '@my-drifter/libs/FirebaseOptions'

export const useSitePermitTemplates = (siteId?: string) =>
  useFirestoreQuery<PermitTemplate[]>(
    collection(db, `${Collections.SITES}/${siteId}/${Collections.PERMIT_TEMPLATES}`),
    {
      fnName: 'useSitePermitTemplates',
      enabled: !!siteId
    }
  )
