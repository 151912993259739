import { z } from 'zod'

// Define the QRCodeStatus enum as a Zod enum
export const QRCodeStatus = {
  Active: 'active',
  Inactive: 'inactive'
} as const

const QRCodeStatusEnum = z.nativeEnum(QRCodeStatus)

// Define the QRCode schema
export const QRCodeSchema = z.object({
  id: z.string(),
  name: z.string(),
  path: z.string(),
  status: QRCodeStatusEnum
})

export type QRCodeStatus = z.infer<typeof QRCodeStatusEnum>
export type QRCode = z.infer<typeof QRCodeSchema>

export interface WritableQRCode extends Omit<QRCode, 'id'> {
  id?: string
}
