import { Button, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { Box } from '@mui/material'
import { grey } from '@mui/material/colors'
import dayjs from 'dayjs'
import { collection, doc, setDoc } from 'firebase/firestore'
import { customAlphabet } from 'nanoid'
import { memo, useState } from 'react'

import { YYYY_MM_DD } from '@consts/DateConsts'

import Collections from '@contracts/enums/Collections'
import { HikerUserRole } from '@contracts/types/HikerUser'
import { QRCode, QRCodeSchema, QRCodeStatus, WritableQRCode } from '@contracts/types/QRCode'

import { useFirestoreQuery } from '@web-js/hooks/useFirestoreQuery'

import Layout from '@my-drifter/components/Layout'
import QRCodeTable from '@my-drifter/components/QRCodeTable'
import useAppState from '@my-drifter/hooks/useAppState'
import { useSelectedSiteContext } from '@my-drifter/hooks/useSelectedSiteContext'
import { useUser } from '@my-drifter/hooks/useUser'
import { getLinkToGuestParkingFeauture } from '@my-drifter/libs/CloudFunctionsApiHandler'
import { db } from '@my-drifter/libs/FirebaseOptions'

import NotAuthorizedScreen from './NotAuthorizedScreen'

// Legacy, needs to be removed in the backend so we can stop passing it in

const nanoid = customAlphabet('1234567890abcdefghijklmnoprsuvwxyz', 6)

const DEFAULT_EXPIRY = dayjs().add(100, 'years').startOf('year')

const StaticQRMakerScreen = () => {
  const { state } = useAppState()

  const [isLoading, setIsLoading] = useState(false)
  const [qrCodeName, setQrCodeName] = useState('')
  const [selectedQRCodeType, setSelectedQRCodeType] = useState<'guest' | 'pay'>('pay')

  const { data: user, isLoading: isLoadingUser } = useUser(localStorage.getItem('userId') || state?.userId)

  const isAllowed =
    !isLoadingUser &&
    user?.role &&
    [HikerUserRole.ADMIN, HikerUserRole.ROOT].includes(user.role) &&
    user?.siteIds &&
    user?.siteIds?.length > 0

  const { selectedSiteId, setSelectedSiteId } = useSelectedSiteContext()

  const { data: allQRCodes = [], isLoading: areQrCodesLoading } = useFirestoreQuery<QRCode[]>(
    collection(db, Collections.QR_CODES),
    {
      fnName: 'useQRCodes'
    }
  )

  if (!isAllowed && !isLoadingUser) return <NotAuthorizedScreen />

  const getGuestParkingLink = async (siteId = selectedSiteId) => {
    try {
      if (!siteId) {
        alert('Please select a site')

        return
      }

      const { url = '' } = await getLinkToGuestParkingFeauture({
        siteId,
        expiresAt: DEFAULT_EXPIRY.format(YYYY_MM_DD)
      })

      return url
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  const isFormValid = () => {
    if (!qrCodeName) {
      alert('Please enter a name for the QR code')

      return false
    }

    if (!selectedSiteId) {
      alert('Please select a site')

      return false
    }

    return true
  }

  const upsertQRCode = async (qrCode: WritableQRCode) => {
    const id = qrCode.id ?? nanoid()

    try {
      const parsedQRCode = QRCodeSchema.parse({
        ...qrCode,
        id: id
      })

      await setDoc(doc(db, Collections.QR_CODES, id), parsedQRCode, { merge: true })

      return parsedQRCode
    } catch (error) {
      console.error(error)

      return null
    }
  }

  const createQRCodeForPay = async () => {
    if (!isFormValid()) {
      return null
    }

    if (selectedQRCodeType !== 'pay') {
      return null
    }

    const path = `https://pay.drifterworld.se/${selectedSiteId}`

    return upsertQRCode({
      name: qrCodeName,
      path,
      status: QRCodeStatus.Active
    })
  }

  const createQRCodeForGuest = async () => {
    if (!qrCodeName) {
      alert('Please enter a name for the QR code')

      return null
    }

    if (!selectedSiteId) {
      alert('Please select a site')

      return null
    }

    if (!selectedQRCodeType) {
      alert('Please select a type for the QR code')

      return null
    }

    const path = await getGuestParkingLink(selectedSiteId)

    if (!path) {
      console.error('Failed to get link')
      return null
    }

    return upsertQRCode({
      name: qrCodeName,
      path,
      status: QRCodeStatus.Active
    })
  }

  const handleSubmit = async () => {
    setIsLoading(true)

    try {
      const promise: Promise<QRCode | null> =
        selectedQRCodeType === 'pay' ? createQRCodeForPay() : createQRCodeForGuest()
      await promise
    } catch (e) {
      console.error(e)
    }

    setIsLoading(false)
  }

  return (
    <Layout
      selectedSiteId={selectedSiteId}
      setSelectedSiteId={(id) => {
        setSelectedSiteId(id)
        getGuestParkingLink(id)
      }}
      isLoading={isLoadingUser}
    >
      <Box
        sx={(theme) => ({
          border: `1px solid ${grey[300]}`,
          borderRadius: 2,
          backgroundColor: 'white',
          m: 2,
          p: 4,
          display: 'flex',
          maxWidth: theme.breakpoints.values.lg,
          gap: 5
        })}
      >
        {selectedSiteId ? (
          <>
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                width: theme.breakpoints.values.sm
              })}
            >
              {/* QR Code name */}
              <TextField
                label="QR Code Name"
                value={qrCodeName}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setQrCodeName(e.target.value)}
              />
              {/* QR Code Type (pay or guest) */}
              <ToggleButtonGroup
                color="primary"
                value={selectedQRCodeType}
                exclusive
                fullWidth
                onChange={(_event, newQrCodeType: 'pay' | 'guest') => {
                  setSelectedQRCodeType(newQrCodeType)
                }}
                aria-label="QR Code Type"
              >
                <ToggleButton value="pay">Pay</ToggleButton>
                <ToggleButton value="guest">Guest</ToggleButton>
              </ToggleButtonGroup>
              <Button
                variant="contained"
                size="large"
                onClick={handleSubmit}
                sx={{ alignSelf: 'flex-start' }}
                disabled={isLoading}
              >
                Create
              </Button>
            </Box>

            <QRCodeTable qrCodes={allQRCodes} siteId={selectedSiteId} />
          </>
        ) : (
          'Select site to continue'
        )}
      </Box>
    </Layout>
  )
}

export default memo(StaticQRMakerScreen)
