export enum PortalSpacings {
  x0_5 = '2px',
  x1 = '4px',
  x2 = '8px',
  x3 = '12px',
  x4 = '16px',
  x6 = '24px',
  x8 = '32px',
  x10 = '40px',
  x12 = '48px',
  x16 = '64px',
  x20 = '80px',
  x24 = '96px',
  x32 = '128px'
}
