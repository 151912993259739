import styled from '@emotion/styled'
import { Box, Button, FormControlLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'

import { DebtorType } from '@contracts/types/Debtor'
import { InvoiceDeliveryMethod, PermitDebtor, UpsertPermitDebtorRequest } from '@contracts/types/PermitDebtor'

import { captureException } from '@web-js/libs/SentryHelper'

import TextsExtended from '@my-drifter/assets/texts_extended.json'
import { getFigmaText } from '@my-drifter/libs/TextRepository'
import {
  BodyMediumEmphasis,
  BodySmallStrong,
  LabelLargeRegular,
  TitleMediumRegular,
  TitleMediumStrong
} from '@my-drifter/libs/Typography'

import LoadingModal from './LoadingModal'
import { PortalColors } from './PortalColors'
import { PortalRadiuses } from './PortalRadiuses'
import { PortalSpacings } from './PortalSpacings'

const DEFAULT_BILLECTA_DEBTOR = {
  siteId: '',
  orgnr: '',
  name: '',
  streetName: '',
  streetName2: '',
  zipCode: '',
  co: '',
  city: '',
  phoneNo: '',
  email: '',
  contactName: '',
  invoiceDeliveryMethod: InvoiceDeliveryMethod.Email
}

type BillingFormState = Omit<UpsertPermitDebtorRequest, 'zipCode'> & {
  zipCode: string
}

interface BillingInformationFormProps {
  debtorType: DebtorType
  permitDebtor: PermitDebtor
  onSaveBillingInfo: (request: UpsertPermitDebtorRequest) => void
}

function BillingInformationForm({ debtorType, permitDebtor, onSaveBillingInfo }: BillingInformationFormProps) {
  const { register, control, watch, formState, handleSubmit, reset } = useForm<BillingFormState>({
    defaultValues: DEFAULT_BILLECTA_DEBTOR
  })
  const { errors, isSubmitting } = formState

  const formValues = watch()

  const hasValue = Object.entries(formValues)
    .filter(([key]) => key !== 'invoiceDeliveryMethod')
    .some(([, value]) => value !== '')

  const onSubmit = async (formData: BillingFormState) => {
    try {
      if (debtorType === DebtorType.Company) {
        await onSaveBillingInfo({
          ...formData,
          zipCode: Number(formData.zipCode),
          id: permitDebtor.id,
          siteId: permitDebtor.siteId,
          name: permitDebtor.name ?? '',
          phoneNo: permitDebtor.phone,
          orgnr: permitDebtor.orgnr,
          type: permitDebtor.type,
          contactName: permitDebtor.contactName
        })
      } else {
        await onSaveBillingInfo({
          ...formData,
          zipCode: Number(formData.zipCode),
          id: permitDebtor.id,
          siteId: permitDebtor.siteId,
          name: permitDebtor.name ?? '',
          phoneNo: permitDebtor.phone,
          ssn: permitDebtor.ssn,
          type: permitDebtor.type,
          email: permitDebtor.email
        })
      }
    } catch (error) {
      captureException(error)
      console.error(error)
    }
  }

  const invoiceType = watch('invoiceDeliveryMethod')

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        backgroundColor: PortalColors.surfaceLevelFirst,
        display: 'flex',
        flexDirection: 'column',
        gap: PortalSpacings.x6,
        padding: PortalSpacings.x6,
        border: !hasValue ? `2px solid ${PortalColors.borderError}` : '2px solid white',
        borderRadius: !hasValue ? PortalRadiuses.xs : 'none'
      }}
    >
      <Box>
        <StyledDeliveryMethodHeadline>{getFigmaText(TextsExtended.permitsInvoiceheader)}</StyledDeliveryMethodHeadline>
        <Controller
          name="invoiceDeliveryMethod"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel
                value={InvoiceDeliveryMethod.Email}
                sx={{ color: PortalColors.textPrimary }}
                control={<Radio size="small" />}
                label={getFigmaText(TextsExtended.permitsInvoicedeliveryEmail)}
              />
              <FormControlLabel
                value={InvoiceDeliveryMethod.Paper}
                control={<Radio size="small" />}
                label={
                  <span>
                    {getFigmaText(TextsExtended.permitsInvoicedeliveryPaper)}
                    <span style={{ color: PortalColors.textSecondary, ...BodyMediumEmphasis }}>
                      {' '}
                      {getFigmaText(TextsExtended.permitsInvoicedeliveryPaperCost)}
                    </span>
                  </span>
                }
              />
            </RadioGroup>
          )}
        />
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          backgroundColor: PortalColors.surfaceLevelSecond,
          padding: PortalSpacings.x6,
          gap: PortalSpacings.x6,
          borderRadius: PortalRadiuses.sm
        }}
      >
        <StyledHeadline>
          {invoiceType === InvoiceDeliveryMethod.Email
            ? getFigmaText(TextsExtended.permitsInvoicebillingDetails)
            : getFigmaText(TextsExtended.permitsInvoiceheadlineAddress)}
        </StyledHeadline>
        {debtorType === DebtorType.Company && invoiceType === InvoiceDeliveryMethod.Email && (
          <Box sx={{ gridColumn: '1 / span 3' }}>
            <StyledLabel htmlFor="email">{getFigmaText(TextsExtended.permitsInputfieldsemail)}</StyledLabel>
            <TextField
              id="email"
              placeholder="name@company.se"
              {...register('email')}
              type="email"
              required
              sx={textFieldStyle}
            />
          </Box>
        )}
        <Box sx={{ gridColumn: '1 / span 3' }}>
          <StyledLabel htmlFor="address-line-1">{getFigmaText(TextsExtended.permitsInvoiceaddress1)}</StyledLabel>
          <TextField
            id="address-line-1"
            placeholder="Streetname streetnumber"
            {...register('streetName')}
            required
            sx={textFieldStyle}
          />
        </Box>

        <Box sx={{ gridColumn: '1 / span 2' }}>
          <StyledLabel htmlFor="address-line-2">{getFigmaText(TextsExtended.permitsInvoiceaddress2)}</StyledLabel>
          <TextField
            id="address-line-2"
            placeholder="Streetname streetnumber"
            {...register('streetName2')}
            sx={textFieldStyle}
          />
        </Box>

        <Box sx={{ gridColumn: 3 }}>
          <StyledLabel htmlFor="co">{getFigmaText(TextsExtended.permitsInvoiceCO)}</StyledLabel>
          <TextField id="co" placeholder="" {...register('co')} sx={textFieldStyle} />
        </Box>

        <Box sx={{ gridColumn: 1 }}>
          <StyledLabel htmlFor="postal-code">{getFigmaText(TextsExtended.permitsInvoicepostalcode)}</StyledLabel>
          <Controller
            name="zipCode"
            control={control}
            rules={{
              required: 'Zipcode is required',
              pattern: {
                value: /^(?!0$)\d+$/,
                message: 'Only numbers are allowed'
              }
            }}
            render={({ field, fieldState }) => (
              <TextField
                id="postal-code"
                {...field}
                placeholder="12345"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : ''}
                onChange={(e) => field.onChange(e.target.value.replace(/\D/g, ''))}
                sx={textFieldStyle}
              />
            )}
          />
        </Box>
        <Box sx={{ gridColumn: 2 }}>
          <StyledLabel htmlFor="city">{getFigmaText(TextsExtended.permitsInvoicecity)}</StyledLabel>
          <TextField id="city" placeholder="DrifterWorld city" {...register('city')} sx={textFieldStyle} />
        </Box>
        <Box sx={{ gridColumn: 3 }}>
          <StyledLabel htmlFor="country">{getFigmaText(TextsExtended.permitsInvoicecountry)}</StyledLabel>
          <Select value="Sweden" disabled sx={textFieldStyle}>
            <MenuItem value="Sweden">Sweden</MenuItem>
          </Select>
        </Box>
      </Box>
      <StyledFooter>
        <Button
          type="submit"
          sx={{
            backgroundColor: PortalColors.surfaceActionSecondary,
            color: PortalColors.textOnActionSecondary,
            borderRadius: PortalRadiuses.sm,
            textTransform: 'none',
            padding: `${PortalSpacings.x3} ${PortalSpacings.x4} `,
            ...LabelLargeRegular,
            '&:hover': {
              backgroundColor: PortalColors.surfaceActionSecondaryHover
            },
            '&.Mui-selected': {
              backgroundColor: PortalColors.surfaceActionTertiaryPressed,
              color: PortalColors.textOnActionTertiaryPressed
            }
          }}
        >
          {getFigmaText(TextsExtended.permitsButtonssavebillingdetails)}
        </Button>
        <Button
          onClick={() => reset(DEFAULT_BILLECTA_DEBTOR)}
          sx={{
            color: PortalColors.textOnActionTertiary,
            backgroundColor: PortalColors.surfaceLevelFirst,
            borderRadius: PortalRadiuses.sm,
            textTransform: 'none',
            ...LabelLargeRegular,
            '&:hover': {
              backgroundColor: PortalColors.surfaceActionTertiaryHover
            },
            '&.Mui-selected': {
              backgroundColor: PortalColors.surfaceActionTertiaryPressed
            }
          }}
        >
          {getFigmaText(TextsExtended.permitsButtonsclear)}
        </Button>
      </StyledFooter>
      <LoadingModal open={isSubmitting} title={getFigmaText(TextsExtended.permitsButtonsloaderaddingdetails)} />
    </Box>
  )
}

export default BillingInformationForm

const StyledHeadline = styled.h3({
  color: PortalColors.textPrimary,
  ...TitleMediumStrong,
  margin: 0
})
const StyledDeliveryMethodHeadline = styled.p({
  color: PortalColors.textPrimary,
  ...TitleMediumRegular,
  margin: 0,
  marginBottom: PortalSpacings.x4
})

const StyledLabel = styled.label({
  ...BodySmallStrong,
  display: 'block',
  marginBottom: PortalSpacings.x2,
  color: PortalColors.textPrimary
})

const textFieldStyle = () => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    backgroundColor: PortalColors.surfaceLevelFirst, // default background
    '& fieldset': {
      borderColor: 'lightgrey' // default border color when empty/not focused
    },
    // When empty and hovered: change background if needed, border remains lightgrey.
    '&:hover input:placeholder-shown': {
      backgroundColor: PortalColors.surfaceLevelSecond
    },
    // When focused (whether empty or not), override border color.
    '&.Mui-focused fieldset': {
      borderColor: PortalColors.borderStrong
    },
    // When typing (input not empty), override border color.
    '& input:not(:placeholder-shown) + fieldset': {
      borderColor: PortalColors.borderBrand
    }
  }
})

const StyledFooter = styled.div({
  backgroundColor: PortalColors.surfaceLevelFirst,
  display: 'flex',
  justifyContent: 'flex-start',
  gap: PortalSpacings.x4
})
