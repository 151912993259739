import { z } from 'zod'

import { Debtor, DebtorType } from '@contracts/types/Debtor'
import { PaymentOptions } from '@contracts/types/PaymentOptions'
import { Permit } from '@contracts/types/Permit'

import { Vehicle } from './Vehicle'

export type PermitDebtor = Debtor & {
  siteId: string
  paymentMethod?: PaymentOptions
  invoiceDeliveryMethod?: InvoiceDeliveryMethod
  billectaInvoiceIds?: string[]
  permits?: Permit[]
  contactName?: string // For organizations, the name of the contact person
  contactEmail?: string // For organizations, the email field is for billing email  only
}

export type BillectaDebtorExternal = Omit<PermitDebtor, 'id' | 'siteId' | 'updatedAt'> & {
  id?: string // ExternalId is not always set in Billecta
}

export const InvoiceDeliveryMethod = {
  Email: 'Email',
  Paper: 'Paper',
  Kivra: 'Kivra',
  EInvoice: 'EInvoice'
} as const

export type InvoiceDeliveryMethod = (typeof InvoiceDeliveryMethod)[keyof typeof InvoiceDeliveryMethod]

export const UpsertPermitDebtorRequestSchema = z.object({
  id: z.string().optional(),
  siteId: z.string(),
  name: z.string(),
  type: z.nativeEnum(DebtorType),
  email: z.string().optional(),
  phoneNo: z.string().optional(),
  invoiceDeliveryMethod: z.nativeEnum(InvoiceDeliveryMethod).optional(),
  co: z.string().optional(), // addressCO ex: "Nilsson"
  streetName: z.string().optional(), // @example "Litorinavägen 10"
  streetName2: z.string().optional(), // @example "Litorinavägen 10"
  zipCode: z.number().optional(), // @example 30256
  city: z.string().optional(), // @example "HALMSTAD"
  orgnr: z.string().optional(),
  ssn: z.string().optional(),
  billectaDebtorId: z.string().optional(),
  contactName: z.string().optional(),
  contactEmail: z.string().optional()
})

export type UpsertPermitDebtorRequest = z.infer<typeof UpsertPermitDebtorRequestSchema>

export const getOrgNoOrSsn = (debtor: PermitDebtor) => (debtor.type == DebtorType.Company ? debtor.orgnr : debtor.ssn)

export const getOrgNoOrSSNForVehicle = (vehicle: Vehicle): string | undefined => {
  const orgNoOrSSN =
    vehicle?.metadata?.valitivePerson?.legalId ||
    vehicle?.metadata?.valitiveOrganization?.legalId ||
    vehicle?.metadata?.valitiveVehicle?.ownerInfo?.userReference?.legalId

  return orgNoOrSSN
}

/**
 * Checks if the given value is a valid organization number.
 *
 * An organization number must concist of 10 numbers. In some it system there is 12 numbers.
 *
 * An organization number is considered valid if the third character is a digit greater than 1.
 * Otherwise, it is considered a personal identity number.
 *
 * In the case of a 12 character long SSN or OrgNr, the fifth character is checked instead.
 */
export function isOrgNr(value: unknown) {
  if (typeof value !== 'string') return false
  if (value.length < 10 || value.length > 12) return false

  const index = value.length === 12 ? 4 : 2
  return typeof value[2] === 'string' && parseInt(value[index]) > 1
}
