// returns a parsed ssn if successful.
// returns null for non-valid ssn's
// eslint-disable-file

function parseSSN(ssn): string | null {
  const CENTURY = '(19|20)?'
  const YEAR = '[0-9]{2}'
  const SEP = '[- ]?'
  const MONTH = '((0[0-9])|(10|11|12))'
  const DAY = '(([0-2][0-9])|(3[0-1])|(([7-8][0-9])|(6[1-9])|(9[0-1])))'
  const LAST = '[0-9]{4}'
  const WORD_BOUNDARY = '\\b'

  const pattern = new RegExp(
    WORD_BOUNDARY +
      group(CENTURY) +
      group(YEAR) +
      SEP +
      group(MONTH) +
      SEP +
      group(DAY) +
      SEP +
      group(LAST) +
      WORD_BOUNDARY
  )

  const r = pattern.exec(ssn)
  if (r) {
    const possiblyCentury = r[1]
    const year = r[3]
    const month = r[4]
    const day = r[8]
    const lastFour = r[16]
    const cent = possiblyCentury || guessCentury(year)

    return cent + year + month + day + lastFour
  }
  return null
}

function guessCentury(year) {
  return year < 30 ? 20 : 19
}

function group(str) {
  return `(${str})`
}

export default parseSSN
