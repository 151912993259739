import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFunctions } from 'firebase/functions'

import { FIREBASE_CONFIG, REGION } from '@consts/HikerConsts'

import { getFirestore } from './getFirestore'

// TODO: enable this when we're ready to work with the test environment locally
// const config = import.meta.env.MODE === 'production' ? FIREBASE_CONFIG : FIREBASE_CONFIG_TEST
const config = FIREBASE_CONFIG

const app = initializeApp(config)

const functions = getFunctions(app, REGION)

const auth = getAuth(app)

const db = getFirestore(app)

export { app, auth, db, functions }
