import styled from '@emotion/styled'
import { Box, TextField } from '@mui/material'
import { FieldErrors } from 'react-hook-form'

import TextsExtended from '@my-drifter/assets/texts_extended.json'
import { Elevation1 } from '@my-drifter/libs/Elevation'
import { getFigmaText } from '@my-drifter/libs/TextRepository'
import { BodyMedium, BodyMediumEmphasis, BodySmall, BodySmallStrong } from '@my-drifter/libs/Typography'

import DebtorForm from './DebtorForm'
import { DebtorFormImplProps, GrpFormData } from './DebtorFormContainer'
import { PortalColors } from './PortalColors'
import { PortalRadiuses } from './PortalRadiuses'
import { PortalSpacings } from './PortalSpacings'

interface DebtorGrpFormProps extends Omit<DebtorFormImplProps, 'errors'> {
  errors: FieldErrors<GrpFormData>
}

const informationText =
  'Interna, alltid avgiftsfria, används för att vitlista fordon eller användare (t.ex. leveransbilar, städpersonal, kommunanställda). Flera användare kan tilldelas deras tillstånd.'

function GroupDebtorForm({ register, debtorType, onSetDebtorType, errors }: DebtorGrpFormProps) {
  return (
    <DebtorForm debtorType={debtorType} onSetDebtorType={onSetDebtorType}>
      <StyledInformationContainer>
        <p>{getFigmaText(TextsExtended.permitsSetupNewDebtordescriptiongrpdebtor)}</p>
      </StyledInformationContainer>
      <StyledEmphasisText>{getFigmaText(TextsExtended.permitsInputFieldsrequired)}</StyledEmphasisText>
      <Box sx={{ flex: 1 }}>
        <StyledFormLabel htmlFor="group-name">
          {getFigmaText(TextsExtended.permitsInputfieldsgroupname)}
        </StyledFormLabel>
        <TextField
          id="group-name"
          placeholder="Delivery vehicles"
          {...register('name')}
          fullWidth
          sx={textFieldStyle}
          error={!!errors.name}
          helperText={errors.name?.message}
        />
        <StyledPromptText>{getFigmaText(TextsExtended.permitsInputFieldsgroupsupporttext)}</StyledPromptText>
      </Box>
    </DebtorForm>
  )
}

export default GroupDebtorForm

const StyledFormLabel = styled.label({
  color: PortalColors.textPrimary,
  ...BodySmallStrong,
  display: 'block',
  marginBottom: PortalSpacings.x2
})

const StyledPromptText = styled.p({
  ...BodySmall,
  color: PortalColors.textSecondary,
  marginTop: PortalSpacings.x2
})

const StyledEmphasisText = styled.p({
  ...BodyMediumEmphasis,
  color: PortalColors.textPrimary
})

const StyledInformationContainer = styled.div({
  boxShadow: Elevation1.boxShadow,
  backgroundColor: PortalColors.surfaceInformation,
  border: `1px solid ${PortalColors.borderInformation}`,
  borderRadius: PortalRadiuses.xs,
  ...BodyMedium,
  padding: `${PortalSpacings.x3} ${PortalSpacings.x4}`,

  '& > p': {
    color: PortalColors.textPrimary,
    margin: 0
  }
})

const textFieldStyle = {
  '& .MuiOutlinedInput-root': {
    backgroundColor: PortalColors.surfaceLevelFirst, // default background
    '& fieldset': {
      borderColor: PortalColors.borderAccessibleHigh // empty border color
    },
    // When empty and hovered
    '&:hover input:placeholder-shown': {
      backgroundColor: PortalColors.surfaceLevelSecond
    },
    // When focused (whether empty or not)
    '&.Mui-focused fieldset': {
      borderColor: PortalColors.borderStrong
    },
    // When typing (input not empty)
    '& input:not(:placeholder-shown) + fieldset': {
      borderColor: PortalColors.borderBrand
    }
  }
}
