import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Autocomplete, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { Control, Controller, UseFormSetValue } from 'react-hook-form'

import { PermitFormState } from './PermitForm'

interface SlotCodeInputProps {
  control: Control<PermitFormState>
  setValue: UseFormSetValue<PermitFormState>
  startValue?: string[]
  slots: string[]
}

export default function SlotCodeInput({ slots, control, startValue, setValue }: SlotCodeInputProps) {
  const [selectedValues, setSelectedValues] = useState<string[]>([])

  useEffect(() => {
    if (startValue && startValue.length > 0) {
      setSelectedValues(startValue)
      setValue('includeSlotPlaceCodes', startValue, { shouldValidate: true })
    }
  }, [startValue, setValue])
  return (
    <Controller
      name="includeSlotPlaceCodes"
      control={control}
      defaultValue={[]}
      rules={{ required: 'Slot numbers are required' }}
      render={({ field }) => (
        <Autocomplete
          multiple
          freeSolo
          options={slots}
          value={selectedValues}
          onChange={(_, newValue) => {
            setSelectedValues(newValue)
            field.onChange(newValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              id="slotnumbers"
              placeholder="Select slotcodes from the list"
              variant="outlined"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: <ArrowDropDownIcon style={{ color: 'grey' }} />
              }}
            />
          )}
        />
      )}
    />
  )
}
