import {
  Box,
  Table,
  TableBody,
  TableCell as MUITableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { SitePricingConfigurationAbsoluteItem } from '@contracts/types/Site'

import { arrayEquals } from '@pure/libs/Common'

import { LabelLargeStrong } from '@my-drifter/libs/Typography'

import NumericInput from './NumericInput'
import { PortalColors } from './PortalColors'
import { PortalSpacings } from './PortalSpacings'

interface AbsolutePricingTableProps {
  items: SitePricingConfigurationAbsoluteItem[]
  isEditing?: boolean
  onChange: (item: SitePricingConfigurationAbsoluteItem, index: number) => void
}

interface CellProps {
  item: SitePricingConfigurationAbsoluteItem
  isEditing?: boolean
  onChange?: (item: SitePricingConfigurationAbsoluteItem) => void
}

function translateDayLabels(dayLabels) {
  const browserLanguage = navigator.language || navigator.languages[0]

  const formatter = new Intl.DateTimeFormat(browserLanguage, { weekday: 'long' })
  return dayLabels.map((_, index) => {
    // Use a fixed date and adjust the day to match the index
    const date = new Date(1970, 0, 5 + index) // January 5, 1970 is a Monday
    return formatter.format(date)
  })
}

const dayLabels = translateDayLabels(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'])

const TableCell = ({ isEditing, sx, ...props }: TableCellProps & { isEditing?: boolean }) => (
  <MUITableCell sx={[isEditing ? { py: 3 } : {}, ...(Array.isArray(sx) ? sx : [sx])]} {...props} />
)

function splitTime(time: string) {
  const [hour, minute] = time.split(':')

  return {
    hour,
    minute
  }
}

function minutesToParts(minutes: number) {
  const minutesLeft = minutes % 60
  const hours = Math.floor((minutes - minutesLeft) / 60)

  return {
    hours,
    minutes: minutesLeft
  }
}

const IntervalCell = ({ item }: CellProps) => {
  const from = splitTime(item.fromTime)
  const to = splitTime(item.toTime)
  const { t } = useTranslation()
  const isAllDay = item.fromTime === '00:00' && item.toTime === '24:00'

  if (isAllDay) {
    return <Box sx={{ fontWeight: 600 }}>All times</Box>
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <Box>{t('configurationPricing_from')}</Box>
      <Box sx={{ fontWeight: 600 }}>
        {from.hour}:{from.minute}
      </Box>
      <Box>{t('configurationPricing_until')}</Box>
      <Box sx={{ fontWeight: 600 }}>
        {to.hour}:{to.minute}
      </Box>
    </Box>
  )
}

const FreeTimeCell = ({ item }: CellProps) => {
  const { hours } = minutesToParts(item?.freeDurationMinutes ?? 0)
  const { t } = useTranslation()

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Box sx={{ fontWeight: 600 }}>{hours}</Box>
      <Box>{t('configurationPricing_hrs')}</Box>
    </Box>
  )
}

const PriceCell = ({ item, isEditing, onChange }: CellProps) => {
  const { t } = useTranslation()
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'flex-end' }}>
      {isEditing ? (
        <NumericInput
          value={item.perHourPrice}
          onChange={(e) => {
            onChange?.({ ...item, perHourPrice: parseFloat(e.target.value) })
          }}
          min={0}
          variant="outlined"
          size="small"
          sx={{
            width: PortalSpacings.x16
          }}
          inputProps={{
            sx: { textAlign: 'right', fontSize: 14, py: 1, px: 2, color: PortalColors.textPrimary }
          }}
        />
      ) : (
        <Box sx={{ color: PortalColors.textPrimary, fontWeight: 600 }}>{item.perHourPrice}</Box>
      )}
      <Box sx={{ color: PortalColors.textPrimary, fontWeight: 600 }}>{t('configurationPricing_krhr')}</Box>
    </Box>
  )
}

export default function AbsolutePricingTable({ items, isEditing, onChange }: AbsolutePricingTableProps) {
  const { t } = useTranslation()
  const groupedItems = getDayGroups(items)

  function getItemIndexByGroupIndex(groupIndex: number, index) {
    let offset = 0

    for (let i = 0; i < groupIndex; i++) {
      offset += groupedItems[Object.keys(groupedItems)[i]].length
    }

    return offset + index
  }

  function getDayLabelFromDayGroupKey(key: string) {
    if (key === 'all') {
      return t('configurationPricing_allweek')
    }

    if (key === 'weekdays') {
      return t('configurationPricing_allweekdays')
    }

    if (key === 'weekends') {
      return t('configurationPricing_allweekends')
    }

    const days = key
      .split(',')
      .map((day) => dayLabels[parseInt(day) - 1])
      .join(', ')

    return days
  }

  return (
    <TableContainer component={Box} sx={{ overflow: 'hidden' }}>
      {Object.entries(groupedItems).map(([dayGroup, items], groupIndex) => (
        <Table key={dayGroup} sx={{ overflow: 'hidden' }}>
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={3}
                sx={{
                  ...LabelLargeStrong,
                  fontWeight: 600,
                  color: PortalColors.textPrimary,
                  letterSpacing: 0.1,
                  backgroundColor: PortalColors.surfaceLevelThird,
                  border: 0
                }}
              >
                {t('configurationPricing_daysoftheweek')} {getDayLabelFromDayGroupKey(dayGroup)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  color: PortalColors.textPrimary,
                  fontWeight: 'bold',
                  width: '40%',
                  borderBottom: '1px solid',
                  borderColor: PortalColors.borderStrong
                }}
              >
                {t('configurationPricing_interval').toUpperCase()}
              </TableCell>
              <TableCell
                sx={{
                  color: PortalColors.textPrimary,
                  fontWeight: 'bold',
                  width: '30%',
                  borderBottom: '1px solid',
                  borderColor: PortalColors.borderStrong
                }}
              >
                {t('configurationPricing_freeparking').toUpperCase()}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  color: PortalColors.textPrimary,
                  fontWeight: 'bold',
                  width: '30%',
                  borderBottom: '1px solid',
                  borderColor: PortalColors.borderStrong
                }}
              >
                {t('configurationPricing_price').toUpperCase()}{' '}
                <Box component="span" sx={{ fontWeight: 400 }}>
                  {t('configurationPricing_VATincl')}
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, groupedItemIndex) => (
              <TableRow
                key={`${item.fromTime}-${item.toTime}-${groupedItemIndex}`}
                sx={{
                  '&:last-child td, &:last-child th': !isEditing ? { border: 0 } : {},
                  color: PortalColors.textPrimary
                }}
              >
                <TableCell isEditing={isEditing} sx={{ color: PortalColors.textPrimary }}>
                  <IntervalCell item={item} />
                </TableCell>
                <TableCell isEditing={isEditing} sx={{ color: PortalColors.textPrimary }}>
                  <FreeTimeCell item={item} />
                </TableCell>
                <TableCell isEditing={isEditing} align="right">
                  <PriceCell
                    item={item}
                    isEditing={isEditing}
                    onChange={(item) => onChange(item, getItemIndexByGroupIndex(groupIndex, groupedItemIndex))}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ))}
    </TableContainer>
  )
}

function getDayGroups(items: SitePricingConfigurationAbsoluteItem[]) {
  return items.reduce<Record<string, SitePricingConfigurationAbsoluteItem[]>>((acc, item) => {
    let days = (item.dayOfWeeks ?? []).toSorted()

    if (days.length === 0 || arrayEquals(days, [1, 2, 3, 4, 5, 6, 7])) {
      days = []
    }

    if (days.length === 0) {
      acc['all'] ? acc['all'].push(item) : (acc['all'] = [item])

      return acc
    }

    if (days.length === 5 && arrayEquals(days, [1, 2, 3, 4, 5])) {
      acc['weekdays'] ? acc['weekdays'].push(item) : (acc['weekdays'] = [item])

      return acc
    }

    if (days.length === 2 && days[0] === 6 && days[1] === 7) {
      acc['weekends'] ? acc['weekends'].push(item) : (acc['weekends'] = [item])

      return acc
    }

    const individualDaysKey = days.join(',')

    if (!acc[individualDaysKey]) {
      acc[individualDaysKey] = []
    }

    acc[individualDaysKey].push(item)

    return acc
  }, {})
}
