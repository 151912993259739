import { BiluppgifterTechnical } from '@contracts/types/BiluppgifterTechnical'
import { DrifterUser } from '@contracts/types/HikerUser'

import { components } from '@pure/swagger/DrifterTypes'

export const DebtorType = {
  Person: 'person',
  Company: 'company',
  Group: 'group' // a group is a non-billable entity used for permits
} as const

export type DebtorType = (typeof DebtorType)[keyof typeof DebtorType]

export type DebtorAddress = {
  co?: string // @example ""
  name?: string // @example "Litorinavägen 10"
  name2?: string // @example "Port A"
  zip_code?: number // @example 30256
  city?: string // @example "HALMSTAD"
}

export type DebtorSettings = {
  invoicingDisabled?: boolean
}

export type Debtor = {
  id: string
  stripeCustomerId?: string
  stripePaymentMethodId?: string
  billectaDebtorId?: string
  updatedAt: string
  type: DebtorType
  ssn?: string
  orgnr?: string
  name?: string
  phone?: string
  email?: string
  address?: DebtorAddress
  settings?: DebtorSettings
  metaData?: {
    user?: DrifterUser
    vehicle?: DebtorVehicle
  }
}

export type DebtorVehicle = Omit<DrifterVehicle, 'owner'> & {
  owner?: BiluppgifterPersonData | BiluppgifterOrgData
  technical?: {
    data?: BiluppgifterTechnical
  }
}

export type DrifterVehicle = components['schemas']['inline_response_200_11_data_vehicle']

export type BiluppgifterPersonData = {
  data: {
    type: string // @example "person"
    links: {
      rel: string // @example "self"
      uri: string // @example "person/pnr/*stripped*"
    }[]
    attributes: {
      pnr: number // @example *stripped*
      status: string // @example "Aktiv"
      sex: string // @example "man"
      birth_year: number // @example 1978
      name: {
        given_name: string // @example "Christian"
        first_name: string // @example "Christian"
        middle_name: string // @example ""
        last_name: string // @example "Nilsson"
        full_name: string // @example "Nilsson, Christian"
      }
      address: {
        co: string // @example ""
        name: string // @example "Litorinavägen 10"
        zip_code: number // @example 30256
        city: string // @example "HALMSTAD"
      }
    }
    vehicles: {
      data: {
        type: string // @example "vehicle"
        attributes: {
          regno: string // @example "EYS061"
          vin: string // @example "WDD2462001N016340"
        }
      }[]
      meta: {
        pagination: {
          total: number // @example 1
          count: number // @example 1
          per_page: number // @example 15
          current_page: number // @example 1
          total_pages: number // @example 1
          links: any[] // @example []
        }
      }
    }
  }
}

export type BiluppgifterOrgData = {
  data: {
    type: string // @example "company"
    links: {
      rel: string // @example "self"
      uri: string // @example "company/orgnr/2021006099"
    }[]
    attributes: {
      orgnr: number // @example 2021006099
      status: string // @example "Aktivt"
      status_detailed: string // @example "Aktivt"
      legal_group: string // @example "Statlig enhet"
      name: {
        legal_name: string // @example "Transportstyrelsen"
      }
      address: {
        co: string // @example " "
        name: string // @example " "
        zip_code: number // @example 60173
        city: string // @example "NORRKÖPING"
      }
    }
    vehicles: {
      data: {
        type: string // @example "vehicle"
        attributes: {
          regno: string // @example "EZK499"
          vin: string // @example "YV1BW84A1C1213710"
        }
        links: {
          rel: string // @example "self"
          uri: string // @example "vehicle/vin/YV1BW84A1C1213710"
        }[]
      }[]
      meta: {
        pagination: {
          total: number // @example 36
          count: number // @example 15
          per_page: number // @example 15
          current_page: number // @example 1
          total_pages: number // @example 3
          links: {
            next: string // @example "https://api.biluppgifter.se/api/v1/company/orgnr/2021006099?limit=15&page=2"
          }
        }
      }
    }
  }
}
