import styled from '@emotion/styled'

import { DebtorType } from '@contracts/types/Debtor'
import { BillectaDebtorExternal, PermitDebtor } from '@contracts/types/PermitDebtor'

import TextsExtended from '@my-drifter/assets/texts_extended.json'
import { getFigmaText } from '@my-drifter/libs/TextRepository'
import { BodySmall, LabelMediumRegular, TitleMediumStrong } from '@my-drifter/libs/Typography'

import { PortalColors } from './PortalColors'
import { PortalRadiuses } from './PortalRadiuses'
import { PortalSpacings } from './PortalSpacings'
import StatusChip from './StatusChip'

interface PermitDebtorInfoProps {
  permitDebtor: PermitDebtor
  billectaDebtor: BillectaDebtorExternal | null
}

function InfoRow({ label, info }) {
  return (
    <StyledRow>
      <StyledLabel>{label}</StyledLabel>
      <StyledDetails>{info}</StyledDetails>
    </StyledRow>
  )
}

function PermitDebtorInfo({ permitDebtor, billectaDebtor }: PermitDebtorInfoProps) {
  const { billectaDebtorId, type } = permitDebtor
  const { name, orgnr, contactName, email, contactEmail, phone, ssn } = billectaDebtor ?? permitDebtor

  const hasBillectaDebtorId = !!billectaDebtorId
  if (type === DebtorType.Group) {
    return (
      <StyledFormContainer>
        <StyledRow>
          <StyledLabel>{getFigmaText(TextsExtended.permitsTabledebtor)}</StyledLabel>
          <StyledDetails>{getFigmaText(TextsExtended.permitsDashboardDebtorsAndPermitsgroup)}</StyledDetails>
        </StyledRow>
      </StyledFormContainer>
    )
  }

  if (type === DebtorType.Person) {
    return (
      <StyledFormContainer>
        <StyledRow>
          <StyledName>{name}</StyledName>
        </StyledRow>

        <InfoContainer>
          <StyledRow>
            <StyledLabel>{getFigmaText(TextsExtended.sitesheadingType)}</StyledLabel>
            <StatusChip status={hasBillectaDebtorId ? 'active' : 'inactive'} />
          </StyledRow>

          <InfoRow label={getFigmaText(TextsExtended.permitsDashboardDebtorsAndPermitsSsn)} info={ssn} />
          <InfoRow label={getFigmaText(TextsExtended.permitsInvoicedeliveryEmail)} info={email} />
          <InfoRow label={getFigmaText(TextsExtended.permitsDashboardDebtorsAndPermitsnumber)} info={phone} />
        </InfoContainer>
      </StyledFormContainer>
    )
  }

  return (
    <StyledFormContainer>
      <StyledRow>
        <StyledName>{name}</StyledName>
      </StyledRow>
      <InfoContainer>
        <StyledRow>
          <StyledLabel>Status</StyledLabel>
          <StatusChip status={hasBillectaDebtorId ? 'active' : 'inactive'} />
        </StyledRow>

        <InfoRow
          label={getFigmaText(TextsExtended.permitsDashboardDebtorsAndPermitsdebtorType)}
          info={getFigmaText(TextsExtended.permitsButtonsorganisation)}
        />
        <InfoRow label={getFigmaText(TextsExtended.permitsDashboardDebtorsAndPermitsorgId)} info={orgnr} />
        <InfoRow label={getFigmaText(TextsExtended.permitsDashboardDebtorsAndPermitscontact)} info={contactName} />
        <InfoRow
          label={getFigmaText(TextsExtended.permitsDashboardDebtorsAndPermitsemail)}
          info={contactEmail ?? email}
        />
        <InfoRow label={getFigmaText(TextsExtended.permitsDashboardDebtorsAndPermitsnumber)} info={phone} />
      </InfoContainer>
    </StyledFormContainer>
  )
}

export default PermitDebtorInfo

const StyledFormContainer = styled.div({
  backgroundColor: PortalColors.surfaceLevelFirst,
  borderTopRightRadius: PortalRadiuses.sm,
  display: 'flex',
  flexDirection: 'column',
  padding: `${PortalSpacings.x2} ${PortalSpacings.x6} ${PortalSpacings.x6} ${PortalSpacings.x6}`,
  borderBottom: `1px solid ${PortalColors.borderDecorativeLow}`
})

const InfoContainer = styled.div`
  display: grid;
  gap: 0.5rem;
`
const StyledLabel = styled.label({
  color: PortalColors.textSecondary,
  textAlign: 'left',
  ...BodySmall,
  margin: 0
})

const StyledName = styled.p({
  color: PortalColors.textPrimary,
  ...TitleMediumStrong
})

const StyledRow = styled.div({
  display: 'grid',
  gridTemplateColumns: ' 1fr 2fr',
  alignItems: 'center',
  gap: PortalSpacings.x6
})

const StyledDetails = styled.div({
  backgroundColor: PortalColors.surfaceLevelSecond,
  borderRadius: PortalRadiuses.xs,
  minHeight: '24px',
  alignContent: 'center',
  padding: `${PortalSpacings.x1} ${PortalSpacings.x2}`,
  ...LabelMediumRegular,
  color: PortalColors.textPrimary,
  margin: 0
})
