import styled from '@emotion/styled'
import CheckIcon from '@mui/icons-material/Check'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import { Alert, Box, Snackbar, SnackbarCloseReason, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { memo, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { UpsertPermitDebtorRequest } from '@contracts/types/PermitDebtor'

import { captureException } from '@web-js/libs/SentryHelper'

import TextsExtended from '@my-drifter/assets/texts_extended.json'
import DebtorBillingContainer from '@my-drifter/components/DebtorBillingContainer'
import DebtorPanel from '@my-drifter/components/DebtorPanel'
import EmptyStateScreen from '@my-drifter/components/EmptyStateScreen'
import FigmaImage from '@my-drifter/components/FigmaImage'
import { LAYOUT_HEADER_HEIGHT } from '@my-drifter/components/HardcodedSizes'
import Layout from '@my-drifter/components/Layout'
import Loader from '@my-drifter/components/Loader'
import PermitDebtorInfo from '@my-drifter/components/PermitDebtorInfo'
import PermitInfoContainer from '@my-drifter/components/PermitInfoContainer'
import PermitUsersTable from '@my-drifter/components/PermitUsersTable'
import { PortalColors } from '@my-drifter/components/PortalColors'
import { PortalRadiuses } from '@my-drifter/components/PortalRadiuses'
import { PortalSpacings } from '@my-drifter/components/PortalSpacings'
import { upsertPermitDebtor } from '@my-drifter/libs/CloudFunctionsApiHandler'
import Images from '@my-drifter/libs/Images'
import NavigationPaths from '@my-drifter/libs/NavigationPaths'
import { getFigmaText } from '@my-drifter/libs/TextRepository'
import { HeadlineMediumStrong, LabelLargeRegular, LabelLargeStrong } from '@my-drifter/libs/Typography'
import { NewPermitsContext } from '@my-drifter/store/NewPermitsProvider'

import NotAuthorizedScreen from './NotAuthorizedScreen'

const PermitDebtorTabs = {
  Permits: 'permits',
  Users: 'users',
  Info: 'info'
} as const

type PermitDebtorTabs = (typeof PermitDebtorTabs)[keyof typeof PermitDebtorTabs]

const DEFAULT_TAB = PermitDebtorTabs.Permits

const NewPermitsScreen = () => {
  const {
    isRoot,
    isLoadingUser,
    selectedSiteId,
    setSelectedSiteId,
    selectedSitePermitDebtorId,
    setSelectedSitePermitDebtorId,
    selectedSitePermitDebtorPermitId,
    setSelectedSitePermitDebtorPermitId,
    selectedPermit,
    selectedPermitDebtor,
    sitePermitDebtors,
    isAnyDrawerOpen,
    sitePermitDebtorsPermits,
    isLoadingBillectaDebtorData,
    isLoadingSitePermitDebtorsPermits,
    billectaDebtorData
  } = useContext(NewPermitsContext)
  const { t } = useTranslation()
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const [hasBillectaError, setHasBillectaError] = useState(false)
  const [debtorTab, setDebtorTab] = useState<PermitDebtorTabs>(DEFAULT_TAB)
  const [isDisplayedPermitUsers, setIsDisplayedPermitUsers] = useState(false)
  const TAB_MAX_HEIGHT = 41
  const TAB_MARGIN = 2
  if (!isRoot && !isLoadingUser) return <NotAuthorizedScreen />

  const navigate = useNavigate()
  const onAddPermit = () => navigate(`${NavigationPaths.CreatePermit}?debtorId=${selectedSitePermitDebtorId}`)
  const onAddDebtor = () => navigate(NavigationPaths.CreateDebtor)
  const onEditPermit = (id: string) => {
    setSelectedSitePermitDebtorPermitId(id)
    navigate(`${NavigationPaths.EditPermit}?permitId=${id}&debtorId=${selectedPermitDebtor?.id}`)
  }

  const hasPermitDebtorsConfigured = sitePermitDebtors && sitePermitDebtors.length > 0

  useEffect(() => {
    setHasBillectaError(false)
    setDebtorTab(DEFAULT_TAB)
    setSelectedSitePermitDebtorId(undefined)
    setIsDisplayedPermitUsers(false)
  }, [selectedSiteId])

  useEffect(() => {
    if (debtorTab === PermitDebtorTabs.Users) {
      setDebtorTab(DEFAULT_TAB)
    }
    setIsDisplayedPermitUsers(false)
  }, [selectedPermitDebtor])

  async function onSaveBillingInfo(request: UpsertPermitDebtorRequest) {
    console.log(request)
    setHasBillectaError(false)

    try {
      await upsertPermitDebtor(request)
      //TODO Behöver en loading eller liknande för att veta när den är klar, då ska success snackbar visas
    } catch (error) {
      setHasBillectaError(true)
      setIsSnackbarOpen(true)
      captureException(error)
      console.error(error)
    }
  }

  function onDebtorTabClick(val) {
    if (val) {
      setDebtorTab(val)
    }
    return
  }

  function handleSnackbarClose(event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) {
    if (reason === 'clickaway') {
      return
    }
    setIsSnackbarOpen(false)
  }

  return (
    <Layout
      selectedSiteId={selectedSiteId}
      setSelectedSiteId={setSelectedSiteId}
      isDrawerOpen={isAnyDrawerOpen}
      isLoading={isLoadingUser}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: `${PortalSpacings.x2} ${PortalSpacings.x6}`,
          flex: 1,
          minHeight: 0,
          gap: PortalSpacings.x6
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: PortalSpacings.x3 }}>
          <StyledImage>
            <FigmaImage imageKey={Images.permitsDark} />
          </StyledImage>
          <StyledHeaderHeadline>{t('headlinePermit')}</StyledHeaderHeadline>
        </Box>

        {!selectedSiteId && (
          <EmptyStateScreen
            title={getFigmaText(TextsExtended.permitsSetupEmptyStateheadlinenosite)}
            description={getFigmaText(TextsExtended.permitsSetupEmptyStatedescriptionnosite)}
          />
        )}
        {selectedSiteId && !hasPermitDebtorsConfigured && (
          <EmptyStateScreen
            title={getFigmaText(TextsExtended.permitsSetupEmptyStateheadline)}
            description={getFigmaText(TextsExtended.permitsSetupEmptyStatedescription)}
            actionTitle={getFigmaText(TextsExtended.permitsButtonsenablepermits)}
            action={onAddDebtor}
          />
        )}

        {selectedSiteId && hasPermitDebtorsConfigured && (
          <Box
            sx={{
              background: PortalColors.surfaceWhite,
              display: 'flex',
              flex: 1,
              minHeight: 0,
              borderRadius: PortalRadiuses.sm,
              border: `1px solid ${PortalColors.borderDecorativeLow}`
            }}
          >
            {hasPermitDebtorsConfigured && (
              <DebtorPanel
                onAddDebtor={() => onAddDebtor()}
                permitDebtors={sitePermitDebtors}
                selectedDebtorId={selectedSitePermitDebtorId}
                onSelectDebtor={(id: string) => setSelectedSitePermitDebtorId(id)}
              />
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                overflow: 'auto'
              }}
            >
              {!isDisplayedPermitUsers && (
                <ToggleButtonGroup
                  value={debtorTab}
                  exclusive
                  onChange={(_, val) => onDebtorTabClick(val)}
                  aria-labelledby="debtor-type-label"
                  sx={{
                    maxHeight: `${TAB_MAX_HEIGHT}px`,
                    margin: `${TAB_MARGIN}px`,
                    position: 'sticky',
                    top: 0
                  }}
                >
                  <ToggleButton value={PermitDebtorTabs.Permits} aria-label="Debtors" sx={toggleButtonStyle}>
                    {debtorTab === PermitDebtorTabs.Permits && <CheckIcon sx={{ fontSize: '16px', mr: 1 }} />}
                    {getFigmaText(TextsExtended.permitsButtonspermits)}
                  </ToggleButton>
                  <ToggleButton
                    disabled={true}
                    value={PermitDebtorTabs.Users}
                    aria-label="Users"
                    sx={toggleButtonStyle}
                  >
                    {debtorTab === PermitDebtorTabs.Users && <CheckIcon sx={{ fontSize: '16px', mr: 1 }} />}
                    {getFigmaText(TextsExtended.permitsButtonsusers)}
                  </ToggleButton>

                  <ToggleButton value={PermitDebtorTabs.Info} aria-label="Info" sx={toggleButtonStyle}>
                    {debtorTab === PermitDebtorTabs.Info && <CheckIcon sx={{ fontSize: '16px', mr: 1 }} />}
                    {getFigmaText(TextsExtended.permitsButtonsinfo)}
                  </ToggleButton>
                </ToggleButtonGroup>
              )}

              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  overflow: 'auto',
                  position: 'relative',

                  minWidth: 0 // This prevents child elements from growing out of the container horizontally
                }}
              >
                {!selectedPermitDebtor && (
                  <EmptyStateScreen
                    title={getFigmaText(TextsExtended.permitsDashboardDebtorsAndPermitsheaderEmptyState)}
                    description={getFigmaText(TextsExtended.permitsDashboardDebtorsAndPermitsdescriptionEmptyState)}
                  />
                )}
                {selectedSiteId &&
                  selectedSitePermitDebtorId &&
                  debtorTab === PermitDebtorTabs.Permits &&
                  !isDisplayedPermitUsers && (
                    <PermitInfoContainer
                      permits={sitePermitDebtorsPermits}
                      setSelectedPermit={setSelectedSitePermitDebtorPermitId}
                      onShowPermitUsers={() => setIsDisplayedPermitUsers(true)}
                      onAddPermit={onAddPermit}
                      onEditPermit={onEditPermit}
                      isLoadingPermits={isLoadingSitePermitDebtorsPermits}
                    />
                  )}
                {selectedSitePermitDebtorId &&
                  selectedSitePermitDebtorPermitId &&
                  selectedPermitDebtor &&
                  isDisplayedPermitUsers && (
                    <PermitUsersTable
                      selectedSiteId={selectedSiteId}
                      selectedSitePermitDebtorId={selectedSitePermitDebtorId}
                      permit={selectedPermit}
                      onHidePermitUsers={() => setIsDisplayedPermitUsers(false)}
                      permitDebtor={selectedPermitDebtor}
                    />
                  )}
                {selectedPermitDebtor &&
                  debtorTab === PermitDebtorTabs.Info &&
                  (isLoadingBillectaDebtorData ? (
                    <Box sx={{ margin: 'auto' }}>
                      <Loader />
                    </Box>
                  ) : (
                    <>
                      <PermitDebtorInfo permitDebtor={selectedPermitDebtor} billectaDebtor={billectaDebtorData} />
                      <DebtorBillingContainer
                        selectedPermitDebtor={selectedPermitDebtor}
                        billectaDebtorData={billectaDebtorData}
                        onSaveBillingInfo={onSaveBillingInfo}
                      />
                    </>
                  ))}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={hasBillectaError ? 'error' : 'success'}
          variant="filled"
          icon={hasBillectaError ? <ErrorIcon /> : <CheckCircleIcon />}
          sx={hasBillectaError ? alertStylesError : alertStylesSuccess}
        >
          {hasBillectaError
            ? getFigmaText(TextsExtended.permitsInputfieldsErrorsystem)
            : getFigmaText(TextsExtended.permitsInvoicenotificationbillingsuccess)}
        </Alert>
      </Snackbar>
    </Layout>
  )
}

const StyledHeaderHeadline = styled.h2({
  color: PortalColors.textPrimary,
  ...HeadlineMediumStrong,
  margin: 0
})

const StyledImage = styled.div`
  & > * {
    height: 100%;
    width: 100%;
  }
`

const alertStylesSuccess = {
  width: '100%',
  marginTop: LAYOUT_HEADER_HEIGHT,
  backgroundColor: PortalColors.surfaceSuccess,
  color: PortalColors.textPrimary,
  border: `1px solid ${PortalColors.borderSuccess}`,
  '& .MuiAlert-icon': {
    color: PortalColors.iconSuccess
  }
}

const alertStylesError = {
  width: '100%',
  marginTop: LAYOUT_HEADER_HEIGHT,
  backgroundColor: PortalColors.surfaceError,
  color: PortalColors.textPrimary,
  border: `1px solid ${PortalColors.borderError}`,
  '& .MuiAlert-icon': {
    color: PortalColors.iconError
  }
}

const toggleButtonStyle = {
  backgroundColor: 'white',
  color: PortalColors.textPrimary,
  ...LabelLargeRegular,
  textTransform: 'none',
  flex: 1,
  flexBasis: 0,
  '&:hover': {
    backgroundColor: PortalColors.surfaceActionTertiaryHover
  },
  '&.Mui-selected': {
    backgroundColor: PortalColors.surfaceActionSecondary,
    color: PortalColors.textOnActionPrimary,
    ...LabelLargeStrong,
    '&:hover': {
      backgroundColor: PortalColors.surfaceActionSecondaryHover
    }
  }
}

export default memo(NewPermitsScreen)
