import { TextField, TextFieldProps } from '@mui/material'
import { NumericFormat } from 'react-number-format'

import { useLanguage } from '@my-drifter/hooks/useLanguage'

interface NumericInputProps {
  max?: number
  min?: number
}

export default function NumericInput({ max, min, ...props }: TextFieldProps & NumericInputProps) {
  const lang = useLanguage()
  const decimalSeparatorByLocale = {
    sv: ',',
    en: '.'
  }

  return (
    // TS complains about passing TextField props through to NumericFormat, but it works fine
    // @ts-ignore
    <NumericFormat
      decimalSeparator={decimalSeparatorByLocale[lang]}
      allowedDecimalSeparators={Object.values(decimalSeparatorByLocale)}
      decimalScale={2}
      isAllowed={(value) => {
        if (typeof value.floatValue !== 'number') return true

        if (typeof max === 'number' && value.floatValue > max) return false
        if (typeof min === 'number' && value.floatValue < min) return false

        return true
      }}
      customInput={TextField}
      {...props}
    />
  )
}
