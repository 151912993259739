import { Block, CheckCircle, Delete, EditOutlined, FileDownload, MoreVert, QrCodeRounded } from '@mui/icons-material'
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Menu,
  MenuItem,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material'
import { IconButton } from '@mui/material'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import QRCodeGenerator from 'qrcode'
import { useEffect, useState } from 'react'

import { QRCode, QRCodeStatus } from '@contracts/types/QRCode'

import { LAYOUT_LEFT_PADDING, LAYOUT_LEFT_XL_PADDING } from './HardcodedSizes'
import StatusChip from './StatusChip'

interface QRCodeTableProps {
  qrCodes: QRCode[]
  siteId: string
  onToggleActive?: (code: QRCode, active: boolean) => void
  onDelete?: (code: QRCode) => void
  onChange?: (code: QRCode) => void
}

const dialoguePaddingSX = (theme) => ({
  '.MuiDialog-container': {
    paddingLeft: `${LAYOUT_LEFT_PADDING}`,
    [theme.breakpoints.up('xl')]: { paddingLeft: `${LAYOUT_LEFT_XL_PADDING}` }
  }
})

export default function QRCodeTable({ qrCodes, siteId }: QRCodeTableProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedCode, setSelectedCode] = useState<QRCode | null>(null)
  const [qrCodeAction, setQrCodeAction] = useState<'view' | 'download' | 'edit' | 'delete' | 'toggleActive' | 'none'>(
    'none'
  )
  const [qrCodeSVG, setQrCodeSVG] = useState<string | null>(null)

  function getQRCodeType(code: QRCode) {
    const url = new URL(code.path)

    const subDomain = url.hostname.split('.')[0]

    switch (subDomain) {
      case 'guest':
        return 'guest'
      case 'pay':
        return 'pay'
      default:
        return 'unknown'
    }
  }

  useEffect(() => {
    if (!selectedCode) {
      return
    }

    generateQRCodeSVG().then((code) => {
      setQrCodeSVG(code)
    })
  })

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, code: QRCode) => {
    setAnchorEl(event.currentTarget)
    setSelectedCode(code)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleDialogClose = () => {
    setQrCodeAction('none')
    setSelectedCode(null)
  }

  const handleView = () => {
    if (selectedCode) {
      setQrCodeAction('view')
    }

    handleMenuClose()
  }

  const handleToggleActive = () => {
    handleMenuClose()
  }

  const handleEdit = () => {
    handleMenuClose()
  }

  const handleDelete = () => {
    handleMenuClose()
  }

  return (
    <>
      <TableContainer sx={{ borderRadius: 2, border: '1px solid', borderColor: 'grey.300' }}>
        <Table aria-label="QR Codes">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {qrCodes.map((code, index) => (
              <TableRow key={index}>
                <TableCell>{code.name}</TableCell>
                <TableCell>{getQRCodeType(code)}</TableCell>
                <TableCell>
                  <Chip
                    size="small"
                    component="a"
                    target="_blank"
                    href={`https://qr.drifterworld.se/${code.id}`}
                    label={code.id}
                    clickable
                  />
                </TableCell>
                <TableCell>
                  <StatusChip status={code.status} />
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="more actions"
                    aria-controls="action-menu"
                    aria-haspopup="true"
                    onClick={(e) => handleMenuOpen(e, code)}
                    size="small"
                  >
                    <MoreVert />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Menu id="action-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={handleView}>
            <QrCodeRounded fontSize="small" sx={{ mr: 1 }} />
            View
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (!selectedCode || !qrCodeSVG) {
                return
              }

              const prefix = `site-${siteId}-${getQRCodeType(selectedCode)}`
              const name = selectedCode?.name ? selectedCode?.name.replace(/ /g, '-').toLowerCase() : selectedCode.id

              downloadSVG(qrCodeSVG, `${prefix}-${name}`)
            }}
          >
            <FileDownload fontSize="small" sx={{ mr: 1 }} />
            Download
          </MenuItem>
          <MenuItem onClick={handleEdit}>
            <EditOutlined fontSize="small" sx={{ mr: 1 }} />
            Edit
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleToggleActive}>
            {selectedCode && selectedCode.status === QRCodeStatus.Active ? (
              <>
                <Block fontSize="small" sx={{ mr: 1 }} />
                Deactivate
              </>
            ) : (
              <>
                <CheckCircle fontSize="small" sx={{ mr: 1 }} />
                Activate
              </>
            )}
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
            <Delete fontSize="small" sx={{ mr: 1 }} />
            Delete
          </MenuItem>
        </Menu>
      </TableContainer>

      {/* View Dialog*/}
      <Dialog
        open={Boolean(selectedCode && qrCodeSVG && qrCodeAction === 'view')}
        onClose={handleDialogClose}
        maxWidth="sm"
        sx={dialoguePaddingSX}
      >
        <DialogTitle id="alert-dialog-title">{selectedCode?.name}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {qrCodeSVG && <div dangerouslySetInnerHTML={{ __html: qrCodeSVG }} />}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* Edit Dialog*/}
      <Dialog open={Boolean(selectedCode && qrCodeAction === 'edit')} onClose={handleDialogClose}>
        <DialogTitle id="alert-dialog-title">{selectedCode?.name}</DialogTitle>
        <DialogContent>{/* TODO: Implement */}</DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleDialogClose} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

async function generateQRCodeSVG() {
  const ns = 'http://www.w3.org/2000/svg'
  const darkColor = '#0c0043'
  const code = await QRCodeGenerator.toString('https://qr.drifterworld.se/abc', {
    type: 'svg',
    errorCorrectionLevel: 'Q',
    color: {
      dark: darkColor
    }
  })

  const parser = new DOMParser()

  const svg = parser.parseFromString(code, 'image/svg+xml').querySelector('svg')

  if (!svg) {
    return code
  }

  svg.setAttribute('width', '100%')

  const g = document.createElementNS(ns, 'g')

  g.setAttribute('transform', 'translate(15 15)')

  const logoBackground = document.createElementNS(ns, 'rect')

  logoBackground.setAttribute('width', '23%')
  logoBackground.setAttribute('height', '23%')
  logoBackground.setAttribute('fill', 'white')
  logoBackground.setAttribute('rx', '2')
  logoBackground.setAttribute('stroke', darkColor)
  logoBackground.setAttribute('stroke-width', '0.6')

  const logo =
    '<svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M26.9383 0C45.1764 0 60 14.322 60 32C60 49.4829 45.4979 63.6879 27.5007 64H26.9383H21.3141L20.3903 63.0244V51.0049L21.5552 49.7561H26.8981C37.0617 49.7561 45.2969 41.7951 45.2969 32C45.2969 22.322 37.2224 14.4 27.1793 14.2439H26.858H23.122L18.6628 18.6536V40.7415L17.4175 41.8732H5.28551L4 40.7805V17.4049L5.28551 16.2341H16.1721L20.4304 12.0976V1.48293C20.4304 1.48293 21.6757 0 21.7159 0H26.9383Z" fill="#130068"/></svg>'

  const logoSVG = parser.parseFromString(logo, 'image/svg+xml').children[0].children[0]

  g.appendChild(logoBackground)

  svg.appendChild(g)

  if (!logoSVG) {
    return svg.outerHTML
  }

  logoSVG.setAttribute('transform', `scale(0.1) translate(12 11)`)

  g.appendChild(logoSVG)

  return svg.outerHTML
}

function downloadSVG(svgString: string, fileName = 'image') {
  const blob = new Blob([svgString], { type: 'image/svg+xml' })
  const url = URL.createObjectURL(blob)

  const downloadLink = document.createElement('a')

  downloadLink.href = url
  downloadLink.download = `${fileName}.svg`
  document.body.appendChild(downloadLink)

  downloadLink.click()

  document.body.removeChild(downloadLink)
  URL.revokeObjectURL(url)
}
