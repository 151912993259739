import styled from '@emotion/styled'
import { Box, TextField } from '@mui/material'
import { FieldErrors } from 'react-hook-form'

import { getInternationalPhoneNumber } from '@pure/libs/PhoneNumberHelper'

import TextsExtended from '@my-drifter/assets/texts_extended.json'
import { Elevation1 } from '@my-drifter/libs/Elevation'
import { getFigmaText } from '@my-drifter/libs/TextRepository'
import { BodyMedium, BodyMediumEmphasis, BodySmallStrong, TitleMediumStrong } from '@my-drifter/libs/Typography'

import DebtorForm from './DebtorForm'
import { DebtorFormImplProps, OrgFormData } from './DebtorFormContainer'
import { PortalColors } from './PortalColors'
import { PortalRadiuses } from './PortalRadiuses'
import { PortalSpacings } from './PortalSpacings'

interface DebtorOrgFormProps extends Omit<DebtorFormImplProps, 'errors'> {
  errors: FieldErrors<OrgFormData>
}

function OrgDebtorForm({ debtorType, onSetDebtorType, register, errors }: DebtorOrgFormProps) {
  return (
    <DebtorForm debtorType={debtorType} onSetDebtorType={onSetDebtorType}>
      <StyledInformationContainer>
        <p>{getFigmaText(TextsExtended.permitsSetupNewDebtordescriptionorgdebtor)}</p>
      </StyledInformationContainer>
      <StyledEmphasisText>{getFigmaText(TextsExtended.permitsInputFieldsrequired)}</StyledEmphasisText>
      <Box sx={{ display: 'flex', gap: PortalSpacings.x6 }}>
        <Box sx={{ flex: 1 }}>
          <StyledFormLabel htmlFor="org-name">{getFigmaText(TextsExtended.permitsInputFieldsorgname)}</StyledFormLabel>
          <TextField
            id="org-name"
            placeholder="Company Name AB"
            {...register('name')}
            fullWidth
            sx={textFieldStyle}
            error={!!errors.name}
            helperText={errors.name?.message}
          />
        </Box>

        <Box sx={{ flex: 1 }}>
          <StyledFormLabel htmlFor="org-id">{getFigmaText(TextsExtended.permitsInputFieldsorgID)}</StyledFormLabel>
          <TextField
            id="org-id"
            placeholder="123456789"
            {...register('orgnr')}
            fullWidth
            sx={textFieldStyle}
            error={!!errors.orgnr}
            helperText={errors.orgnr?.message}
            onBlur={(event) => {
              // Remove spaces and hyphens from the input value
              const formattedValue = event.target.value.replace(/[\s-]/g, '')
              event.target.value = formattedValue
            }}
          />
        </Box>
      </Box>

      <Box sx={{ display: 'flex', gap: PortalSpacings.x6, flexDirection: 'column' }}>
        <div>
          <StyledTitle>{getFigmaText(TextsExtended.permitsSetupNewDebtorheadlineContact)}</StyledTitle>
          <StyledPromptText>{getFigmaText(TextsExtended.permitsSetupNewDebtordescription)}</StyledPromptText>
        </div>

        <Box>
          <StyledFormLabel htmlFor="contact-person">
            {getFigmaText(TextsExtended.permitsInputFieldsfullnames)}
          </StyledFormLabel>
          <TextField
            id="contact-person"
            placeholder="John Doe"
            {...register('contactName')}
            fullWidth
            sx={textFieldStyle}
            error={!!errors.contactName}
            helperText={errors.contactName?.message}
          />
        </Box>

        <Box sx={{ display: 'flex', gap: PortalSpacings.x6 }}>
          <Box sx={{ flex: 1 }}>
            <StyledFormLabel htmlFor="email">{getFigmaText(TextsExtended.permitsInputfieldsemail)}</StyledFormLabel>
            <TextField
              id="email"
              placeholder="name@company.se"
              {...register('email')}
              type="email"
              fullWidth
              sx={textFieldStyle}
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <StyledFormLabel htmlFor="phone">{getFigmaText(TextsExtended.permitsInputFieldsnumber)}</StyledFormLabel>
            <TextField
              id="phone"
              placeholder="+46 00 000 0000"
              {...register('phone')}
              type="tel"
              fullWidth
              sx={textFieldStyle}
              error={!!errors.phone}
              helperText={errors.phone?.message}
              onBlur={(event) => {
                // Format number to SE format
                const formattedNumber = getInternationalPhoneNumber(event.target.value)
                event.target.value = formattedNumber
              }}
            />
          </Box>
        </Box>
      </Box>
    </DebtorForm>
  )
}

export default OrgDebtorForm

const StyledOrgDebtorText = styled.p({
  color: PortalColors.textPrimary,
  margin: 0
})

const StyledInformationContainer = styled.div({
  boxShadow: Elevation1.boxShadow,
  backgroundColor: PortalColors.surfaceInformation,
  border: `1px solid ${PortalColors.borderInformation}`,
  borderRadius: PortalRadiuses.xs,
  ...BodyMedium,
  padding: `${PortalSpacings.x3} ${PortalSpacings.x4}`,

  '& > p': {
    color: PortalColors.textPrimary,
    margin: 0
  }
})

const StyledFormLabel = styled(StyledOrgDebtorText.withComponent('label'))({
  ...BodySmallStrong,
  display: 'block',
  marginBottom: PortalSpacings.x2
})

const StyledTitle = styled(StyledOrgDebtorText)({
  ...TitleMediumStrong,
  marginBottom: PortalSpacings.x2
})

const StyledPromptText = styled(StyledOrgDebtorText)({
  ...BodyMedium
})

const StyledEmphasisText = styled.p({
  ...BodyMediumEmphasis,
  color: PortalColors.textPrimary
})

const textFieldStyle = {
  '& .MuiOutlinedInput-root': {
    backgroundColor: PortalColors.surfaceLevelFirst, // default background
    '& fieldset': {
      borderColor: PortalColors.borderAccessibleHigh // empty border color
    },
    // When empty and hovered
    '&:hover input:placeholder-shown': {
      backgroundColor: PortalColors.surfaceLevelSecond
    },
    // When focused (whether empty or not)
    '&.Mui-focused fieldset': {
      borderColor: PortalColors.borderStrong
    },
    // When typing (input not empty)
    '& input:not(:placeholder-shown) + fieldset': {
      borderColor: PortalColors.borderBrand
    }
  }
}
