import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import { Dispatch, memo, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import * as yup from 'yup'

import { BorderRadiusesPx } from '@contracts/types/BorderRadixes'
import { Permit } from '@contracts/types/Permit'

import { Colors } from '@pure/assets/Colors'

import { upsertPermit } from '@my-drifter/libs/DBApiHandler'
import Texts from '@my-drifter/libs/Texts'

import { Spacings } from '@web-components/enums/Spacings'

import Box from './Box'
import Button from './Button'
import FigmaText from './FigmaText'
import RightSideDrawer from './RightSideDrawer'
import TextFieldForm from './TextFieldForm'

interface RightSideVehiclesDrawerProps {
  permit?: Permit
  selectedSiteId?: string
  selectedSitePermitDebtorId?: string
  isDrawerOpen: boolean
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>
}

const AddVehicleDrawer = ({
  permit,
  selectedSiteId,
  selectedSitePermitDebtorId,
  isDrawerOpen,
  setIsDrawerOpen
}: RightSideVehiclesDrawerProps) => {
  const schema = yup.object().shape({
    plate: yup.string().required('Plate Number is required!'),
    description: yup.string().optional()
  })

  const [isPlateNumberAlreadyAdded, setIsPlateNumberAlreadyAdded] = useState(false)

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = (data: { plate: string; description?: string }) => {
    const { plate, description } = data
    if (!permit || !selectedSiteId || !selectedSitePermitDebtorId) return

    const fleetVehicles = permit.fleetVehicles ?? {}
    if (fleetVehicles[plate]) {
      setIsPlateNumberAlreadyAdded(true)
      return
    }
    fleetVehicles[plate] = { plate, description }

    const updatedPermit = { ...permit, fleetVehicles }
    upsertPermit(selectedSiteId, selectedSitePermitDebtorId, permit.id, updatedPermit)
    setIsDrawerOpen(false)
  }

  useEffect(() => {
    if (!isDrawerOpen) {
      reset({ plate: '' })
      setIsPlateNumberAlreadyAdded(false)
    }
  }, [isDrawerOpen])

  return (
    <RightSideDrawer isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen}>
      <FigmaText textKey={Texts.textMyPortalAddVehicleHeader} />
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Box fullWidth style={{ gap: Spacings.xs }}>
          <FigmaText textKey={Texts.textMyPortalPermitTitlePlateNumber} />
          <Controller
            control={control}
            name="plate"
            render={({ field, fieldState }) => (
              <TextFieldForm
                {...field}
                value={field.value}
                fullWidth
                fieldState={fieldState}
                autoFocus
                placeholder="AVC854"
              />
            )}
          />
        </Box>
        <Button fullWidth type="submit" textKey={Texts.textButtonsAddButton} />
      </StyledForm>
      {isPlateNumberAlreadyAdded && (
        <ErrorBox fullWidth fullPadding>
          <FigmaText textKey={Texts.textMyPortalErrorVehicleAlreadyAdded} />
        </ErrorBox>
      )}
    </RightSideDrawer>
  )
}

const StyledForm = styled.form`
  display: flex;
  gap: ${Spacings.s};
  flex-direction: column;
  margin-top: ${Spacings.m};
`

const ErrorBox = styled(Box)`
  margin-top: ${Spacings.s};
  border: 1px solid ${Colors.yellow1};
  background-color: ${Colors.yellow4};
  border-radius: ${BorderRadiusesPx.minimum};
`

export default memo(AddVehicleDrawer)
