import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import ErrorIcon from '@mui/icons-material/Error'
import { Alert, Box as MuiBox, Button, Snackbar, SnackbarCloseReason, TextField } from '@mui/material'
import { captureException } from '@sentry/react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import * as yup from 'yup'

import TextsExtended from '@my-drifter/assets/texts_extended.json'
import { upsertPermit } from '@my-drifter/libs/DBApiHandler'
import { getFigmaText } from '@my-drifter/libs/TextRepository'
import Texts from '@my-drifter/libs/Texts'
import { BodySmall, BodySmallStrong, LabelLargeRegular, TitleLarge } from '@my-drifter/libs/Typography'

import { Spacings } from '@web-components/enums/Spacings'

import { AddPermitConnectionDrawerProps } from './AddPermitConnectionDrawer'
import { LAYOUT_HEADER_HEIGHT } from './HardcodedSizes'
import Loader from './Loader'
import { PortalColors } from './PortalColors'
import { PortalRadiuses } from './PortalRadiuses'
import { PortalSpacings } from './PortalSpacings'

const DEFAULT_FORM_DATA = {
  plate: '',
  description: ''
}

export default function AddVehicleForm({
  permit,
  selectedSiteId,
  selectedSitePermitDebtorId,
  isDrawerOpen,
  onCloseDrawer
}: AddPermitConnectionDrawerProps) {
  const schema = yup.object().shape({
    plate: yup.string().required('Plate number is required'),
    description: yup.string().optional()
  })

  const [isPlateNumberAlreadyAdded, setIsPlateNumberAlreadyAdded] = useState(false)
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_FORM_DATA
  })

  const onSubmit = async (data: { plate: string; description?: string }) => {
    const { plate, description } = data
    if (!permit || !selectedSiteId || !selectedSitePermitDebtorId) return

    const fleetVehicles = permit.fleetVehicles ?? {}
    if (fleetVehicles[plate]) {
      setIsSnackbarOpen(true)
      setIsPlateNumberAlreadyAdded(true)
      return
    }
    fleetVehicles[plate] = { plate, description }

    const updatedPermit = { ...permit, fleetVehicles }
    try {
      await upsertPermit(selectedSiteId, selectedSitePermitDebtorId, permit.id, updatedPermit)
      onCloseDrawer()
    } catch (e) {
      captureException(e)
      setIsSnackbarOpen(true)
      console.log(e)
    }
  }

  function handleSnackbarClose(event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) {
    if (reason === 'clickaway') {
      return
    }
    setIsSnackbarOpen(false)
  }

  useEffect(() => {
    if (!isDrawerOpen) {
      reset({ plate: '' })
      setIsPlateNumberAlreadyAdded(false)
    }
  }, [isDrawerOpen])

  return (
    <MuiBox sx={{ display: 'flex', flex: 1 }}>
      {isSubmitting ? (
        <MuiBox
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1 }}
        >
          <Loader $loaderColor={PortalColors.surfaceActionSecondary} />
          <StyledLoaderText>{getFigmaText(TextsExtended.permitsSetupNewuserloader)}</StyledLoaderText>
        </MuiBox>
      ) : (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <MuiBox>
            <StyledFormLabel htmlFor="plate">
              {getFigmaText(TextsExtended.permitsInputfieldsvehicleregnumber)}
            </StyledFormLabel>
            <Controller
              control={control}
              name="plate"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  value={field.value.toUpperCase()}
                  fullWidth
                  placeholder="AVC854"
                  size="small"
                  sx={textFieldStyle}
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </MuiBox>
          <MuiBox>
            <StyledFormLabel htmlFor="description">
              {getFigmaText(TextsExtended.permitsInputfieldsdriverorvehicledetails)}
            </StyledFormLabel>
            <Controller
              control={control}
              name="description"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  value={field.value}
                  fullWidth
                  size="small"
                  sx={textFieldStyle}
                  placeholder="Suzuki delivery"
                />
              )}
            />
            <StyledSupportText>
              {getFigmaText(TextsExtended.permitsInputfieldsdescriptiondriverorvehicle)}
            </StyledSupportText>
          </MuiBox>

          <MuiBox
            sx={{
              marginTop: 'auto',
              marginBottom: PortalSpacings.x8,
              display: 'flex',
              flexDirection: 'row',
              padding: PortalSpacings.x4,
              gap: PortalSpacings.x4
            }}
          >
            <Button
              onClick={onCloseDrawer}
              sx={{
                color: PortalColors.textOnActionTertiary,
                textTransform: 'none',
                ...LabelLargeRegular,
                padding: PortalSpacings.x2,
                borderRadius: PortalRadiuses.sm,
                flex: 1,
                '&:hover': {
                  backgroundColor: PortalColors.surfaceActionTertiaryHover
                }
              }}
            >
              {getFigmaText(TextsExtended.permitsButtonscancel)}
            </Button>
            <Button
              type="submit"
              sx={{
                backgroundColor: PortalColors.surfaceActionSecondary,
                color: PortalColors.textOnActionSecondary,
                textTransform: 'none',
                ...LabelLargeRegular,
                padding: PortalSpacings.x2,
                borderRadius: PortalRadiuses.sm,
                flex: 1,
                '&:hover': {
                  backgroundColor: PortalColors.surfaceActionSecondaryHover
                }
              }}
            >
              {getFigmaText(TextsExtended.permitsButtonsassigntopermit)}
            </Button>
          </MuiBox>
        </StyledForm>
      )}

      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          variant="filled"
          icon={<ErrorIcon />}
          sx={{
            width: '100%',
            marginTop: LAYOUT_HEADER_HEIGHT,
            backgroundColor: PortalColors.surfaceError,
            color: PortalColors.textPrimary,
            border: `1px solid ${PortalColors.borderError}`,
            '& .MuiAlert-icon': {
              color: PortalColors.iconError
            }
          }}
        >
          {isPlateNumberAlreadyAdded
            ? getFigmaText(Texts.textMyPortalErrorVehicleAlreadyAdded)
            : getFigmaText(TextsExtended.permitsInputfieldsErrorsystem)}
        </Alert>
      </Snackbar>
    </MuiBox>
  )
}

const StyledForm = styled.form`
  display: flex;
  gap: ${Spacings.s};
  flex-direction: column;
  flex: 1;
`

const StyledFormLabel = styled.label({
  color: PortalColors.textPrimary,
  margin: 0,
  ...BodySmallStrong,
  display: 'block',
  marginBottom: PortalSpacings.x2
})

const StyledLoaderText = styled.p({
  color: PortalColors.textPrimary,
  ...TitleLarge
})

const StyledSupportText = styled.p({
  color: PortalColors.textSecondary,
  ...BodySmall,
  marginTop: PortalSpacings.x2
})

const textFieldStyle = {
  '& .MuiOutlinedInput-root': {
    backgroundColor: PortalColors.surfaceLevelFirst, // default background
    '& fieldset': {
      borderColor: PortalColors.borderAccessibleHigh // empty border color
    },
    // When empty and hovered
    '&:hover input:placeholder-shown': {
      backgroundColor: PortalColors.surfaceLevelSecond
    },
    // When focused (whether empty or not)
    '&.Mui-focused fieldset': {
      borderColor: PortalColors.borderStrong
    },
    // When typing (input not empty)
    '& input:not(:placeholder-shown) + fieldset': {
      borderColor: PortalColors.borderBrand
    }
  }
}
